import Select from "react-select";
import { inLineSelectStyles} from "../../../components/utils/reactSelect";

const DebtDetailsForm = ({setDebtEstimationAproach,debtEstimationAproach, setRating, settype_of_company, setpre_tax_cost_debt, setaverage_maturity_of_debt, t}) => {
    return (
        <div className="card text-left o-hidden">
            <div className="card-header d-flex align-items-center border-0 my-header">
                <h3 className="w-100 float-left card-title m-0 text-white">{t("OTHERCRRINFO.DEBTDETAILS")}</h3>
            </div>
            <div className="table-responsive table-strip">
                <table className="table table-hover table-bordered">
                    <thead style={{backgroundColor:"var(--tp-common-gray)"}}>
                        <tr>
                            <th scope="col">{t("FIN_STATEMENT.LABEL")}</th>
                            <th scope="col">{t("OTHERCRRINFO.VALUE")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{t("OTHERCRRINFO.DEBTMATURITY")}</td>
                            <td style={{padding:0, marginTop:0}}><input type="number" style={{borderStyle:"none", marginBottom:0, width:"100%", textAlign:"right"}} placeholder="1 000 000 000" className=""  onChange={(e)=>{setaverage_maturity_of_debt(e.target.value)}}/></td>
                        </tr>
                        <tr>
                            <td>{t("OTHERCRRINFO.DEBTESTIMATION")}</td>
                            <td style={{padding:0, marginTop:0}}>
                                <Select className="basic-single"  
                                    styles={inLineSelectStyles} 
                                    menuPortalTarget={document.body}
                                    onChange={(e)=>{setDebtEstimationAproach(e.value)}}
                                    options={
                                        [{label:"ActualRating", value:"Actual Rating"},
                                        {label:"DirectInput", value:"Direct Input"},
                                        {label:"SyntheticRating", value:"Synthetic Rating"}]
                                    }/>
                            </td>
                        </tr>
                        {debtEstimationAproach==="Actual Rating" &&
                        <tr>
                            <td>Rating</td>
                            <td style={{padding:0, marginTop:0}}>
                                <Select className="basic-single"  
                                styles={inLineSelectStyles}
                                menuPortalTarget={document.body}
                                onChange={(e)=>{setRating(e.value)}}
                                options={[
                                    {label: "Aaaa/AAA", value:"Aaaa/AAA"},
                                    {label: "Aa2/AA", value:"Aa2/AA"},
                                    {label: "A1/A+", value:"A1/A+"},
                                    {label: "Aa2/AA", value:"Aa2/AA"},
                                    {label: "A1/A+", value:"A1/A+"},
                                    {label: "A3/A-", value:"A3/A-"},
                                    {label: "Baa/BBB", value:"Baa/BBB"},
                                    {label: "Ba1/BB+", value:"Ba1/BB+"},
                                    {label: "Baa2/BB+", value:"Baa2/BB+"},
                                    {label: "B2/B", value:"B2/B"},
                                    {label: "B3/B-", value:"B3/B-"},
                                    {label: "C2/C", value:"C2/C"},
                                    {label: "Ca2/CC", value:"Ca2/CC"},
                                    {label: "Caa/CCC", value:"Caa/CCC"},
                                    {label: "D2/D", value:"D2/D"},
                                    {label: "No rating", value:"No rating"}
                                ]}
                                />
                            </td>
                        </tr>}
                        {debtEstimationAproach==="Direct Input" &&
                        <tr>
                            <td>{t("OTHERCRRINFO.DEBTBT")}</td>
                            <td><input type="number" style={{borderStyle:"none", marginBottom:0, width:"100%", textAlign:"right"}} placeholder="1 000 000 000" className="" onChange={(e)=>{setpre_tax_cost_debt(e.target.value)}}/></td>
                        </tr>}
                        {debtEstimationAproach==="Synthetic Rating" &&
                        <tr>
                            <td>{t("OTHERCRRINFO.CMPTYPE")}</td>
                            <td style={{padding:0, marginTop:0}}>
                                <Select className="basic-single"
                                styles={inLineSelectStyles} 
                                menuPortalTarget={document.body}
                                onChange={(e)=>{settype_of_company(e.value)}}
                                options={[
                                    {label:"Larger Manufacturing Firm", value:1},
                                    {label:"Small or Risker Firm", value:2},
                                    {label:"Fiancial Service Firm", value:3}
                                ]}
                                />
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default DebtDetailsForm