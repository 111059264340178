import { Link, useNavigate } from "react-router-dom"
import useSWR from "swr";
import { useEffect, useState } from "react";
import { URLS } from "../../services/urls";
import { DotLoaderOverlay } from 'react-spinner-overlay'
import { API, CONFIGAPI, CRRAPI, PARAMAPI, PAYMENTAPI } from "../../services/axios";
import axios from "axios";
import { split } from "lodash";
import { getActivities } from "../../services/activities";
import { curencyFormater, getUserSubscribtions } from "../../components/utils/functions";
import { toast } from "react-toastify";
import Select from "react-select";
import { inLineSelectStyles, inLineSelectStyles2, styles } from "../../components/utils/reactSelect";
import LoaderPage from "../../components/loader";
import BreadCrumb from "../../components/breadcrumb";
import NoSubscribtionComponent from "../../components/nosubscibtion/no-subscribtionpage";
import DisplayAsset from "../../components/financial_statement_display/assets_display";
import DisplayLiability from "../../components/financial_statement_display/display_liabilities";
import DisplayIncomeStatement from "../../components/financial_statement_display/display_income";
import DisplayCashFlow from "../../components/financial_statement_display/display_cashflow";
import BusinessRevenueTable from "./components/businessRevenuesTable";
import DebtDetailsForm from "./components/debtDetailsForm";
import LeasingForm from "./components/leasingForm";
import RDExpnesesForm from "./components/rdExpensesForm";
import CountryRevenueForm from "./components/countriesRevenuForm";
import QualitativeDataForm from "./components/qualitativeDataForm";
import FootNoteForm from "./components/footNoteForm";
import CompanyDetailsForm from "./components/companyDetailsForm";
import CRRFormInit from "./components/formInit";
import { useTranslation } from "react-i18next";

const SMECRRForm = () => {
    const navigage = useNavigate();
    const {t} = useTranslation()
    const [subscribed, setSubscribed] = useState(false);
    const [servicePackage, setServicePackage] = useState([]);

    const [file1, setFile1]=useState(null);
    const [file2, setFile2]=useState(null);
    const annee=(new Date().getFullYear())-1;
    const [assets, setAssets] = useState([]);
    const [liabilities, setLiabilities] = useState([]);
    const [incomeStatement, setIncomeStatement] = useState([])
    const [cashflow, setCashflow] = useState([]);
    const [footNote, setFootNote] = useState([]);
    const [qualitativeData, loadQualitativeData] = useState([]);
    const [preloading, setPreloading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [searchCountry, setSearchCountry] = useState("");
    const [activities, setActivities] = useState([])
    const  [countries, setCountries] = useState();
    const  [legalStatus, setlegalStatus] = useState();


    const [year, setYear] = useState(annee)
    const [period, setPeriod] = useState(12)
    const [currency, setCurrency] = useState('Fcfa')
    const [num_business_revenues, setNum_business_revenues] = useState(0);
    const [mainBusiness, setMainBusiness] = useState(null);
    const [countryRevenu, setCountryRevenu] = useState([])
    const [businessRevenues, setbusinessRevenues] = useState([]);
    const [landScape, setLandScape] = useState(false);
    const [rdInputMethod,setRdInputMethod] = useState(null);
    const [rdAmortPeriod, setRdAmortPeriod] = useState(0);
    const [rdExpenses, setRdExpenses] = useState([])
    const [olExpenses, setOlExpenses] = useState([]);
    const [olAmortPeriod, setOlAmortPeriod] = useState(0);
    const [legalForm, setLegalForm] = useState(null);
    const [headOffice, setHeadOffice] = useState(null);
    const [taxRate, setTaxRate] = useState(33.0);
    const [vatRate, setVatRate] = useState(19.25);
    const [visa, setVisa] = useState("yes");
    const [controle, setControle] = useState("yes");
    const [firstYear, setFirstYear] = useState(null);
    const [staff, setStaff] = useState(0);
    const [extracted, setExtracted] = useState(false);

    const [average_maturity_of_debt, setaverage_maturity_of_debt] = useState(10);
    const [debtEstimationAproach, setDebtEstimationAproach] = useState("Actual Rating");
    const [rating, setRating] = useState("Aaaa/AAA");
    const [pre_tax_cost_debt, setpre_tax_cost_debt] = useState(2);
    const [type_of_company, settype_of_company] = useState(2);
    const setFields = () => {
        let currentBusiness = businessRevenues[0];
        let currentCountry = countryRevenu[0];
        console.log("looking for the main activity")
        console.log(businessRevenues)
        for(let i=1; i<businessRevenues.length;i++){
            if(businessRevenues[i].revenue>currentBusiness.revenue){
                currentBusiness=businessRevenues[i];
                setMainBusiness(businessRevenues[i].business);
                console.log(mainBusiness)
            }
        }
        console.log(`The main activity is ${currentBusiness.business}`)
        setMainBusiness(currentBusiness.business);
    }
    const checkErrors=()=>{
        setFields();
        for(let i=0;i<assets.length;i++){
            if(assets[i].reference==="BZ"){
                for(let j=0;j<liabilities.length;j++){
                    if(liabilities[j].reference==="DZ"){
                        if(assets[i].net!==liabilities[j].net || assets[i].net1!==liabilities[j].net1 || assets[i].net2!==liabilities[j].net2){
                            toast.error("Your assets and Liabilities are not consistent", {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
                            return false;
                        }
                    }
                }
            }
        }
        for(let i=0;i<assets.length;i++){
            if(assets[i].reference==="BZ" && (!assets[i].net ||!assets[i].net1 ||!assets[i].net2)){
                toast.error("Upload your data for extraction", {position:toast.POSITION.BOTTOM_RIGHT, theme:"colored"})
                return false;
            }
        }
        for(let i=0;i<liabilities.length;i++){
            if(liabilities[i].reference==="DZ" && (!liabilities[i].net ||!liabilities[i].net1 ||!liabilities[i].net2)){
                toast.error("Upload your data for extraction", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
                return false;
            }
        }
        for(let i=0;i<incomeStatement.length;i++){
            if(incomeStatement[i].reference==="XI" && (!incomeStatement[i].net ||!incomeStatement[i].net1 ||!incomeStatement[i].net2)){
                toast.error("Upload your data for extraction", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
                return false;
            }
        }
        for(let i=0;i<cashflow.length;i++){
            if(cashflow[i].reference==="ZH" && (!cashflow[i].net ||!cashflow[i].net1 ||!cashflow[i].net2)){
                toast.error("Upload your data for extraction", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
                return false;
            }
        }
        if(!mainBusiness){
            toast.error("Provide Your main activity", {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
            return false;
        }
        if(!headOffice){
            toast.error("Where is the Enterprise head Quater?", {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
            return false;
        }
        if(!staff){
            toast.error("Povide the number of employees working in the enterprise", {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
            return false;
        }
        if(!taxRate){
            toast.error("Provide Your tax Rate", {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
            return false;
        }
        if(!legalForm){
            toast.error("Provide Your enterprise Legal Status", {position:toast.POSITION.TOP_CENTER})
            return false;
        }
        if(!businessRevenues){
            toast.error("Provide Your business revenues", {position:toast.POSITION.TOP_CENTER})
            return false
        }else{
            for(let i=0; i<businessRevenues.length; i++){
                if(!businessRevenues[i].business || !businessRevenues[i].revenue){
                    toast.error("Provide Your Business revenues", {position:toast.POSITION.TOP_CENTER})
                    return false;
                }
            }
        }
        for(let i=0;i<qualitativeData.length;i++){
            if(!qualitativeData[i].value){
                toast.error("Provide Qualitative Data", {position:toast.POSITION.TOP_CENTER});
                return false;
            }
        }

        if(countryRevenu.length===0){
            toast.error("Provide Your country revenues", {position:toast.POSITION.TOP_CENTER})
            return false
        }else{
            for(let i=0; i<countryRevenu.length; i++){
                if(!countryRevenu[i].country || !countryRevenu[i].revenue){
                    toast.error("Provide Your country revenues", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
                    return false;
                }
            }
        }
        return true;
    }
    const setFootNoteValues=(reference, value, position)=>{
        let _footNote = footNote;
        for(let i=0;i<_footNote[0].rubriques.length;i++){
            for(let j=0; j<_footNote[0].rubriques[i].posts.length;j++){
                if(_footNote[0].rubriques[i].posts[j].reference===reference){
                    _footNote[0].rubriques[i].posts[j][position]=Number(value);
                }else{
                    if(!_footNote[0].rubriques[i].posts[j][position]){
                        _footNote[0].rubriques[i].posts[j][position]=0   
                    }
                }

                if(_footNote[0].rubriques[i].posts[j].reference==="effectif" && position==="net"){
                    setStaff(Number(value));
                }
            }
        }
        setFootNote(_footNote);
        console.log(footNote)
    }
    const getQualitativeDate=(variable, value)=>{
        let _qualitativeData = qualitativeData
        for(let i=0;i<_qualitativeData.length;i++){
            if(_qualitativeData[i].variable===variable){
                _qualitativeData[i].value=value
            }
        }
        loadQualitativeData(_qualitativeData)
        // console.log(qualitativeData)
    }

    const parseData = ()=>{
        // Prepare data to send to backend
        let _footNote=[]
        for(let i=0;i<footNote[0].rubriques.length;i++){
            for(let j=0;j<footNote[0].rubriques[i].posts.length;j++){
                _footNote.push(footNote[0].rubriques[i].posts[j])
            }
        }
        // const assets_ = assets
        // const liabilities_ = liabilities
        // const income_statement_ = incomeStatement
        // const cashflow_ = cashflow
        // for(let i=0; i<income_statement_.length;i++){
        //     for(let j=0;j<income_statement_[i].rubriques.length;j++){
        //         income_statement_[i].rubriques[j]["posts"]=income_statement_[i].rubriques[j].posts
        //     //    masse["rubriques"][j]['posts'] = masse.rubriques[j].posts;
        //     //    delete masse.rubriques[j].posts;
        //     }
        //     // income_statement_.push(masse);
        // }
        // setIncomeStatement(income_statement_)
        // for(let i=0; i<cashflow_.length;i++){
        //     // let masse = cashflow[i]
        //     for(let j=0;j<cashflow_[i].rubriques.length;j++){
        //        cashflow_[i].rubriques[j]["posts"] = cashflow_[i].rubriques[j].posts;
        //     //    delete masse.rubriques[j].posts;
        //     }
        //     // cashflow_.push(masse);
        // }
        // setCashflow(cashflow_)
        // for(let i=0; i<assets_.length;i++){
        //     // let masse = assets[i]
        //     for(let j=0;j<assets_[i].rubriques.length;j++){
        //        assets_[i].rubriques[j]["posts"] = assets_[i].rubriques[j].posts
        //     //    delete masse.rubriques[j].posts;
        //     }
        //     // assets_.push(masse);
        // }
        // setAssets(assets_)
        // for(let i=0; i<liabilities_.length;i++){
        //     // let masse = liabilities[i]
        //     for(let j=0;j<liabilities_[i].rubriques.length;j++){
        //        liabilities_[i].rubriques[j]["posts"] = liabilities_[i].rubriques[j].posts;
        //     //    delete masse.rubriques[j].posts;
        //     }
        //     // liabilities_.push(masse);
        // }
        setLiabilities(liabilities)
        console.log(assets)
        console.log(liabilities)
        console.log(cashflow)
        console.log(incomeStatement)
        const data = {    
            year:year,
            period:period,
            currency:currency,
            head_office: headOffice,
            activity:mainBusiness,
            legal_form: legalForm,
            staff: staff,
            
            tax_rate:taxRate>1?taxRate:taxRate/100,
            vat_rate:vatRate>1?vatRate:vatRate/100,
            rd_amortizing_period:rdAmortPeriod,
            ol_amortizing_period: olAmortPeriod,
            rd_expenses: olExpenses,
            ol_expenses: rdExpenses,
            number_of_shares:1,
            market_price: 1,

            balance_sheet: {
                assets: assets,
                liabilities: liabilities
            },
            cash_flow: cashflow,
            income_statement: incomeStatement,
            foot_note: _footNote,
            qualitative_data: qualitativeData,
            debt_details:{
                "average_maturity_of_debt": Number(average_maturity_of_debt)<1?Number(average_maturity_of_debt):Number(average_maturity_of_debt)/100,
                "pre_tax_cost_debt_estimation_approach": debtEstimationAproach,
                "pre_tax_cost_debt": pre_tax_cost_debt,
                "rating": rating,
                "type_of_company": type_of_company
            },
            operating_countries: countryRevenu,
            business_revenues: businessRevenues,

        }
        console.log(data)
        return data;
    }

    const onSubmitSaveData=()=>{
        if(checkErrors()){
            const data=parseData();
            console.log(data)
            API.post(`${URLS.RATING_URL}/pending/`,data).then((resp)=>{
                toast.success("Your financial data was successfully saved, You can go back to contnue the process at any moment", {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
                const data = {
                    "id":resp.data?.id??resp.data?._id
                }
                setLoading(false);
                navigage(`/sme-crr/${data.id}/${data.id}`)
            }).catch((error)=>{
                toast.error(error?.response?.data?? "Error saving Data", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
                setLoading(false)
            })
            console.log(data)
        }
    }

    const onSubmitRating=()=>{
        if(checkErrors()){
            const data=parseData();
            setLoading(true)
            // PAYMENTAPI.post(URLS.PAYMENT.INVOICE.add(),{rererence:servicePackage})
            // .then((res)=>{
            //     toast.success("New payment recorded", {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
            CRRAPI.post(URLS.CRR.create(),data).then((resp)=>{
                    // toast.success("Rating succeeded check the details to see more", {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
                    setLoading(false);
                    navigage(`/sme-crr/details/${resp.data.id}/${resp.data.reference}`)
                }).catch((error)=>{
                    toast.error(error?.response?.data??"Error Rating Your enterprise", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
                    setLoading(false)
                })
            // }).catch((err)=>{
            //     toast.error("Error recording new payment", {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
            //     setLoading(false);
            // })
        }
    }

    const getMasse=(masses)=>{
                    const assets_=[];
                    const liabilities_ = [];
                    const incomeStatement_ = [];
                    const cashflow_ = [];
                    const footNote_ = []
            masses.forEach(element => {
                if(element['category']==='asset'){
                    assets_.push(element)
                }
                if(element['category']==="liability"){
                    liabilities_.push(element)
                }
                if(element['category']==="income statement"){
                    incomeStatement_.push(element)
                }
                if(element['category']==="cashflow"){
                    cashflow_.push(element)
                }
                if(element['category']==="footnote table"){
                    footNote_.push(element)
                }
            });
            setAssets(assets_);
            setLiabilities(liabilities_);
            setIncomeStatement(incomeStatement_);
            setCashflow(cashflow_);
            setFootNote(footNote_);
            setPreloading(false);
    }

    const fillFormFields=(data)=>{
        let _assets=assets;
        let _liabilities=liabilities;
        let _incomstatement=incomeStatement;
        let _cashflow=cashflow;
        console.log(data.liabilities)
        for(let i=0;i<data.assets.length;i++){
	      for(let j=0;j<_assets.length;j++){
	        if(_assets[j].reference.trim()===data.assets[i].reference.trim()){
	          _assets[j].amort_deprec=Number(String((data.assets[i].amort_deprec)).split("\n")[0].split(" ").join(""));
	          _assets[j].brut=Number(String((data.assets[i].brut)).split("\n")[0].split(" ").join(""));
	          _assets[j].net=Number(String((data.assets[i].net)).split("\n")[0].split(" ").join(""));
	          _assets[j].net1=Number(String((data.assets[i].net1)).split("\n")[0].split(" ").join(""));
	          _assets[j].net2=Number(String((data.assets[i].net2)).split("\n")[0].split(" ").join(""));
	        }else{
	          for(let k=0;k<_assets[j].rubriques.length;k++){
	            if(_assets[j].rubriques[k].reference.trim()===data.assets[i].reference.trim()){
	              _assets[j].rubriques[k].amort_deprec=Number(String((data.assets[i].amort_deprec)).split("\n")[0].split(" ").join(""));
	              _assets[j].rubriques[k].brut=Number(String((data.assets[i].brut)).split("\n")[0].split(" ").join(""));
	              _assets[j].rubriques[k].net=Number(String((data.assets[i].net)).split("\n")[0].split(" ").join(""));
	              _assets[j].rubriques[k].net1=Number(String((data.assets[i].net1)).split("\n")[0].split(" ").join(""));
	              _assets[j].rubriques[k].net2=Number(String((data.assets[i].net2)).split("\n")[0].split(" ").join(""));
	            }else{
	              for(let l=0;l<_assets[j].rubriques[k].posts?.length;l++){
	                if(_assets[j].rubriques[k]?.posts[l]?.reference.trim()===data.assets[i]?.reference.trim()){
	                  _assets[j].rubriques[k].posts[l].amort_deprec=Number(String((data.assets[i].amort_deprec)).split("\n")[0].split(" ").join(""));
	                  _assets[j].rubriques[k].posts[l].brut=Number(String((data.assets[i].brut)).split("\n")[0].split(" ").join(""));
	                  _assets[j].rubriques[k].posts[l].net=Number(String((data.assets[i].net)).split("\n")[0].split(" ").join(""));
	                  _assets[j].rubriques[k].posts[l].net1=Number(String((data.assets[i].net1)).split("\n")[0].split(" ").join(""));
	                  _assets[j].rubriques[k].posts[l].net2=Number(String((data.assets[i].net2)).split("\n")[0].split(" ").join(""));
	                }
	              }
	            }
	          }
	        }
	      }
	    }

        for(let i=0;i<data.liabilities.length;i++){
            for(let j=0;j<_liabilities.length;j++){
              if((_liabilities[j].reference.trim()===data.liabilities[i].reference.trim())||(_liabilities[j].reference.trim()==="DV" && data.liabilities[i].reference.trim()==="DY")){
                _liabilities[j].net=Number(String((data.liabilities[i].net)).split("\n")[0].split(" ").join(""));
                _liabilities[j].net1=Number(String((data.liabilities[i].net1)).split("\n")[0].split(" ").join(""));
                _liabilities[j].net2=Number(String((data.liabilities[i].net2)).split("\n")[0].split(" ").join(""));
              }else{
                for(let k=0;k<_liabilities[j].rubriques.length;k++){
                  if(_liabilities[j].rubriques[k].reference.trim()===data.liabilities[i].reference.trim()){
                    _liabilities[j].rubriques[k].net=Number(String((data.liabilities[i].net)).split("\n")[0].split(" ").join(""));
                    _liabilities[j].rubriques[k].net1=Number(String((data.liabilities[i].net1)).split("\n")[0].split(" ").join(""));
                    _liabilities[j].rubriques[k].net2=Number(String((data.liabilities[i].net2)).split("\n")[0].split(" ").join(""));
                  }else{
                    for(let l=0;l<_liabilities[j].rubriques[k].posts.length;l++){
                      if(_liabilities[j].rubriques[k].posts[l].reference.trim()===data.liabilities[i].reference.trim()){
                        _liabilities[j].rubriques[k].posts[l].net=Number(String((data.liabilities[i].net)).split("\n")[0].split(" ").join(""));
                        _liabilities[j].rubriques[k].posts[l].net1=Number(String((data.liabilities[i].net1)).split("\n")[0].split(" ").join(""));
                        _liabilities[j].rubriques[k].posts[l].net2=Number(String((data.liabilities[i].net2)).split("\n")[0].split(" ").join(""));
                      }
                    }
                  }
                }
              }
            }
        }
  
        for(let i=0;i<data.income_statement.length;i++){
        for(let j=0;j<_incomstatement.length;j++){
            if(_incomstatement[j].reference.trim()===data.income_statement[i].reference.trim()){
            _incomstatement[j].net=Number(String((data.income_statement[i].net)).split("\n")[0].split(" ").join(""));
            _incomstatement[j].net1=Number(String((data.income_statement[i].net1)).split("\n")[0].split(" ").join(""));
            _incomstatement[j].net2=Number(String((data.income_statement[i].net2)).split("\n")[0].split(" ").join(""));
            }else{
            for(let k=0;k<_incomstatement[j].rubriques.length;k++){
                if(_incomstatement[j].rubriques[k].reference.trim()===data.income_statement[i].reference.trim()){
                _incomstatement[j].rubriques[k].net=Number(String((data.income_statement[i].net)).split("\n")[0].split(" ").join(""));
                _incomstatement[j].rubriques[k].net1=Number(String((data.income_statement[i].net1)).split("\n")[0].split(" ").join(""));
                _incomstatement[j].rubriques[k].net2=Number(String((data.income_statement[i].net2)).split("\n")[0].split(" ").join(""));
                }else{
                for(let l=0;l<_incomstatement[j].rubriques[k].posts.length;l++){
                    if(_incomstatement[j].rubriques[k].posts[l].reference.trim()===data.income_statement[i].reference.trim()){
                    _incomstatement[j].rubriques[k].posts[l].net=Number(String((data.income_statement[i].net)).split("\n")[0].split(" ").join(""));
                    _incomstatement[j].rubriques[k].posts[l].net1=Number(String((data.income_statement[i].net1)).split("\n")[0].split(" ").join(""));
                    _incomstatement[j].rubriques[k].posts[l].net2=Number(String((data.income_statement[i].net2)).split("\n")[0].split(" ").join(""));
                    }
                }
                }
            }
            }
        }
        }

        for(let i=0;i<data.cash_flow.length;i++){
        for(let j=0;j<_cashflow.length;j++){
            if(_cashflow[j].reference.trim()===data.cash_flow[i].reference.trim()){
            _cashflow[j].net=Number(String((data.cash_flow[i].net)).split("\n")[0].split(" ").join(""));
            _cashflow[j].net1=Number(String((data.cash_flow[i].net1)).split("\n")[0].split(" ").join(""));
            _cashflow[j].net2=Number(String((data.cash_flow[i].net2)).split("\n")[0].split(" ").join(""));
            }else{
            for(let k=0;k<_cashflow[j].rubriques.length;k++){
                if(_cashflow[j].rubriques[k].reference.trim()===data.cash_flow[i].reference.trim()){
                _cashflow[j].rubriques[k].net=Number(String((data.cash_flow[i].net)).split("\n")[0].split(" ").join(""));
                _cashflow[j].rubriques[k].net1=Number(String((data.cash_flow[i].net1)).split("\n")[0].split(" ").join(""));
                _cashflow[j].rubriques[k].net2=Number(String((data.cash_flow[i].net2)).split("\n")[0].split(" ").join(""));
                }else{
                for(let l=0;l<_cashflow[j].rubriques[k].posts.length;l++){
                    if(_cashflow[j].rubriques[k].posts[l].reference.trim()===data.cash_flow[i].reference.trim()){
                    _cashflow[j].rubriques[k].posts[l].net=Number(String((data.cash_flow[i].net)).split("\n")[0].split(" ").join(""));
                    _cashflow[j].rubriques[k].posts[l].net1=Number(String((data.cash_flow[i].net1)).split("\n")[0].split(" ").join(""));
                    _cashflow[j].rubriques[k].posts[l].net2=Number(String((data.cash_flow[i].net2)).split("\n")[0].split(" ").join(""));
                    }
                }
                }
            }
            }
        }
        }
        if(data.hasOwnProperty('data_birth')){
        for(let i=0;i<data.data_birth.length;i++){
            if(data.data_birth[i].reference==="ZK"){
                for(let j=0;j<legalStatus.length;j++){
                    if(legalStatus[j].code===data.data_birth[i].code_birth){
                        setLegalForm(legalStatus[j].sigle)
                    }
                }
            }
            if(data.data_birth[i].reference==="ZM"){
            for(let j=0;j<countries.length;j++){
                if(countries[j].code===data.data_birth[i].code_birth){
                // setHeadOffice(countries[j].country)
                let country_rev = countryRevenu;
                let op_country={"country":countries[j].country}
                op_country.country=countries[j].country;
                country_rev[0]=op_country;
                setCountryRevenu(country_rev);
                }
            }
            }
            if(data.data_birth[i].reference==="ZP"){
            setFirstYear(data.data_birth[i].code_birth)
            }
        }

        }
        if(data.hasOwnProperty('business_rev_break')){
        let business_revenues=[]
        let act_codes = []
        console.log(data.business_rev_break)
        for(let i=0;i<data.business_rev_break.length;i++){
            for(let j=0;j<activities.length;j++){
            if(String(data.business_rev_break[i].reference)===String(activities[j].code)){
                console.log(`Good ${data.business_rev_break[i].reference} ${activities[j].code}`)
                act_codes.push(data.business_rev_break[i]);
                let newbusiness = {"business":activities[j].title, "revenue":data.business_rev_break[i].chiffre_affaire}
                business_revenues.push(newbusiness)
            }
            }
        }
        let mainAct = business_revenues[0].business;
        let mainActRev = business_revenues[0].revenue;
        for(let i=1;i<business_revenues.length;i++){
            if(business_revenues[i].revenue>mainActRev){
                mainAct = business_revenues[i].business;
                mainActRev = act_codes[i].revenue;
            }
        }
        for(let k=0; k<activities.length;k++){
            if(String(mainAct)===String(activities[k].title)){
                setMainBusiness(activities[k].title);
                console.log(activities[k].industry)
                if(activities[k].industry?.amortizing_period>0){
                    setRdAmortPeriod(activities[k].industry?.amortizing_period);
                }
                else{
                    setRdAmortPeriod(1);
                }
            }
        }
        setbusinessRevenues(business_revenues)
        console.log(mainBusiness,rdAmortPeriod)
        }
        setAssets(_assets);
        setLiabilities(_liabilities);
        setIncomeStatement(_incomstatement);
        setCashflow(_cashflow);
        console.log(liabilities);
    }

    useEffect(()=>{
        const subs = getUserSubscribtions()
        subs?.forEach((element)=>{
            if(element.includes("CRR")){
                setSubscribed(true)
                setServicePackage(element)
            }
        })
        console.log(subs)
            axios.get(`${URLS.CONF_URL}/rating/masses/?limit=1000`)
            .then((res)=>{
                let masses = res.data.results;
                // window.localStorage.setItem("masses", JSON.stringify(masses));
                getMasse(res.data.results);
            }
            ).catch((error)=>{
                console.log(error);
            });
            axios.get(`${URLS.CONF_URL}/rating/qualitativedata/?limit=1000`)
            .then((res)=>{
                loadQualitativeData(res.data.results);
            }).catch((error)=>{
                console.log(error)
            });
            axios.get(`${URLS.PARAM_URL}/countries_params/countries/?limit=1000`)
            .then((res)=>{
                setCountries(res.data.results);
            }).catch((error)=>{
                console.log(error)
            })
            axios.get(`${URLS.PARAM_URL}/params/activities/?limit=1000`).then((resp)=>{
                console.log(resp.data.results)
                setActivities(resp.data.results);
            }).catch((error)=>{
                console.log(error);
            });
            axios.get(`${URLS.PARAM_URL}/industries_params/legal_status/?limit=1000`).then((resp)=>{
                setlegalStatus(resp.data.results);
                console.log(resp.data.results)
            }).catch((error)=>{
                console.log(error);
            });
    }
        ,[],
    )

    const getBunusnessRev=(value)=>{
        setNum_business_revenues(value)
        let i=0;
        let bR = [];
        while(i<value){
            bR.push({business:activities[i].title,revenue:0});
            i++
        }
        setbusinessRevenues(bR);
    }

    const getCountryRevenu=(value)=>{
        let revenues=Array();
        for(let i=0; i<value; i++){
            revenues.push({"country":countries[i].id,"revenue":0})
        }
        setCountryRevenu(revenues);
    }
    const saveCountryRevenue=(value,pos,index)=>{
        let revenues = countryRevenu;
        if(pos==="country"){
            revenues[index].country=value;
        }
        if(pos==="revenue"){
            revenues[index].revenue=Number(value);
        }
        setCountryRevenu(revenues)
        console.log(countryRevenu)
    }

    const saveBusinessRevenu=(value, val, index)=>{
        let revenues = businessRevenues
        if(val==="business"){
            revenues[index].business=value;
        }
        if(val==="revenue"){
            revenues[index].revenue=value;
        }
        setbusinessRevenues(revenues)
    }

    const getOlExpenses=(value)=>{
        setOlAmortPeriod(value)
        let expenses = [];
        for(let i=0;i<value;i++){
            expenses.push({"year":annee+i, "amount":0.0})
        }
        setOlExpenses(expenses);
    }

    const saveOLExpenses=(event, index)=>{
        let expenses = olExpenses
        expenses[index].amount=Number(event.target.value);
        setOlExpenses(expenses);
        console.log(olExpenses);
    }

    const setRDamort=(period)=>{
        let amort=Array()
        for(let i=0; i<period; i++){
            amort.push({"year":annee-(i+1), "amount":0.0})
        }
        setRdExpenses(amort);
    }
    const saveRDExpenses=(event, index)=>{
        let expenses = rdExpenses;
        expenses[index].amount = Number(event.target.value);
        setRdExpenses(expenses);
        console.log(rdExpenses)
    }

    const extract_data=(event)=>{
        setLoading(true);
        const formData = new FormData();
        formData.append("file1",file1, file1.name)
        formData.append("file2",file2, file2.name);
        CRRAPI.post(URLS.CRR.extractData(), formData).then((resp)=>{
            console.log(resp.data)
            fillFormFields(resp.data)
            setLoading(false);
            toast.success("Data successfully extracted",{position: toast.POSITION.TOP_RIGHT, theme:"colored"})
            toast.info("Make sure you verify your data and add any additinnal information that is needed then proceed to obtain your rating",{position: toast.POSITION.BOTTOM_RIGHT, theme:"colored", autoClose: false})
            setExtracted(true);
        })
        .catch((error)=>{
            console.log(error)
            toast.error("Error Extracting Data", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
            setLoading(false);
        })
        event.preventDefault()
    }

    const onCancel=()=>{
        setFile1(null);
        setFile2(null);
        setExtracted(false);
    }

    return (
        <>
            <BreadCrumb title1="CRR-SME" title2="New"  link="/sme-crr"/>
            {preloading?(
                <LoaderPage/>
            ):
            (<>
            {/* {!subscribed?
                (<NoSubscribtionComponent service={"CRR"}/>):
            (<div className="row"> */}
                <div className={loading?"col-12 disabled":"col-12"} data-bg-color="#F6F6F9">
                    <div className="alert alert-info" role="alert"><strong className="text-capitalize">Info!</strong> Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus quos omnis illo nulla nobis beatae labore. Magnam facilis ducimus impedit minus, perspiciatis voluptatum itaque autem harum, velit, numquam facere porro!.
                        <button className="close" type="button" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    {!extracted?
                    (<CRRFormInit year={year} setYear={setYear} setFile1={setFile1} setFile2={setFile2} file1={file1} file2={file2}
                    annee={annee} period={period} setPeriod={setPeriod} extract_data={extract_data} t={t}/>):(
                    <>
                    <div className="row mb-2">
                        <div className="col-2">
                            <button className="btn btn-danger" onClick={(e)=>{onCancel()}}>
                                {t("textCancel")}
                            </button>
                        </div>
                        <div className=" col"><div style={{float:"right"}}>
                            {/* <button className="btn ripple m-1" type="button" style={{backgroundColor:"#1D4361", color:"white"}} onClick={(e)=>{onSubmitSaveData()}}>Save</button> */}
                            <button className="btn m-1" type="button" style={{backgroundColor:"#1D4361", color:"white"}} onClick={(e)=>{onSubmitRating()}}>{t("textProcedd")}</button></div>
                        </div>
                    </div>
                    <div>
                        {/* <div className="card-body"> */}
                        <ul className="nav nav-tabs profile-nav mb-4" id="profileTab" role="tablist"style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", width:"inherit"}}>
                        {/* <ul className="nav nav-tabs profile-nav mb-4" id="profileTab" role="tablist"> */}
                            <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link active" id="balancesheet-tab" data-toggle="tab" href="#balancesheet" role="tab" aria-controls="balancesheet" aria-selected="false">{t("FIN_STATEMENT.BALANCESHEET")}</a></li>
                            <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link" id="incomestatement-tab" data-toggle="tab" href="#incomestatement" role="tab" aria-controls="incomestatement" aria-selected="false">{t("FIN_STATEMENT.INCOME_STATEMENT")}</a></li>
                            <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link" id="cashflow-tab" data-toggle="tab" href="#cashflow" role="tab" aria-controls="cashflow" aria-selected="true">{t("FIN_STATEMENT.CASHFLOW")}</a></li>
                            <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link" id="footnote-tab" data-toggle="tab" href="#footnote" role="tab" aria-controls="footnote" aria-selected="false">{t("FIN_STATEMENT.FOOT_NOTE")}</a></li>
                            <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link" id="qdata-tab" data-toggle="tab" href="#qdata" role="tab" aria-controls="qdata" aria-selected="true">{t("FIN_STATEMENT.QDATA")}</a></li>
                            <li className="nav-item" style={{fontWeight:"bold", color:"#1D4361"}}><a className="nav-link" id="others-tab" data-toggle="tab" href="#others" role="tab" aria-controls="others" aria-selected="false">{t("textOthers")}</a></li>
                        </ul>
                        <div className="tab-content" id="tabContent" style={{marginRight:0, marginLeft:0}}>
                            <div className="tab-pane fade active show" id="balancesheet" role="tabpanel" aria-labelledby="balancesheet-tab" style={{background:"#fff"}}>
                                <div className="row">
                                    <div className="col-12">
                                        <label className="switch pr-5 switch-primary mt-2 mb-4 float-left">
                                            <span style={{color:"black"}}>{t("FIN_STATEMENT.VIEW")}</span>
                                            <input type="checkbox" checked={landScape} onChange={()=>{setLandScape(!landScape)}}/><span className="slider"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={!landScape?"col-xl-12 mb-5":"col-xl-7 mb-5"}>
                                        <DisplayAsset assets={assets} t={t} landscape={landScape}/>
                                    </div>
                                    <div className={!landScape?"col-xl-12 mb-5":"col-xl-5 mb-5"}>
                                        <DisplayLiability liabilities={liabilities} t={t} landscape={landScape}/>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="incomestatement" role="tabpanel" aria-labelledby="incomestatement-tab" style={{background:"#fff"}}>
                                <div className="row">
                                    <div className="col-xl-12 mb-5">
                                        <DisplayIncomeStatement incomeStatement={incomeStatement} t={t}/>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="cashflow" role="tabpanel" aria-labelledby="cashflow-tab" style={{background:"#fff"}}>
                                <div className="row">
                                    <div className="col-xl-12 mb-5">
                                        <DisplayCashFlow cashflow={cashflow} t={t}/>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="footnote" role="tabpanel" aria-labelledby="footnote-tab" style={{background:"#fff"}}>
                                <FootNoteForm footNote={footNote} setFootNoteValues={setFootNoteValues} t={t}/>
                            </div>

                            <div className="tab-pane fade" id="qdata" role="tabpanel" aria-labelledby="qdata-tab" style={{background:"#fff"}}>
                                <div className="row">
                                    <div className="col-xl-6 mb-5">
                                        <QualitativeDataForm qualitativeData={qualitativeData} getQualitativeDate={getQualitativeDate} t={t}/>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="others" role="tabpanel" aria-labelledby="others-tab" style={{background:"#fff"}}>
                                
                                <div className="row">
                                        <div className="col-xl-4 mb-0">
                                            <BusinessRevenueTable businessRevenues={businessRevenues} t={t}/>
                                        </div>
                                        <div className="col-xl-4 mb-0">
                                            <RDExpnesesForm  setRdInputMethod={setRdInputMethod} rdInputMethod={rdInputMethod} 
                                            rdExpenses={rdExpenses} setRDamort={setRDamort} saveRDExpenses={saveRDExpenses} t={t}
                                            amortPreriod={rdAmortPeriod}/>
                                        </div>
                                        <div className="col-xl-4 mb-5">
                                            <CompanyDetailsForm setHeadOffice={setHeadOffice} countries={countries} 
                                            setLegalForm={setLegalForm} legalForm={legalForm}
                                            legalStatus={legalStatus} loading={loading} taxRate={taxRate} setTaxRate={setTaxRate} 
                                            vatRate={vatRate} setVatRate={setVatRate} firstYear={firstYear} setFirstYear={setFirstYear}
                                            setControle={setControle} setVisa={setVisa} t={t}/>
                                        </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-4 mb-5">
                                        <CountryRevenueForm countryRevenu={countryRevenu} countries={countries} saveCountryRevenue={saveCountryRevenue} 
                                        getCountryRevenu={getCountryRevenu} t={t}/>
                                    </div>
                                    <div className="col-xl-4 mb-5">
                                        <LeasingForm getOlExpenses={getOlExpenses} olExpenses={olExpenses} annee={annee} saveOLExpenses={saveOLExpenses} t={t}/>
                                    </div>
                                    <div className="col-xl-4 mb-5">
                                        <DebtDetailsForm setDebtEstimationAproach={setDebtEstimationAproach} 
                                        debtEstimationAproach={debtEstimationAproach}
                                        setRating={setRating} settype_of_company={settype_of_company} 
                                        setpre_tax_cost_debt={setpre_tax_cost_debt} 
                                        setaverage_maturity_of_debt={setaverage_maturity_of_debt}
                                        t={t}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>)}
                </div>
            {/* </div>)} */}
            </>)}
        </> 
    );
}
export default SMECRRForm;