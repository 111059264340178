export const DEFAULT_PAGE_COUNT = 25;

export const DEFAULT_IMAGE = "/favicon.ico";
export const DEFAULT_PROFILE = "/assets/images/avatar.png";
export const DEFAULT_LOGO = "/assets/img/logo/logo_small.png";
export const VISA_LOGO = "/assets/images/visa.png";
export const PAYPAL_LOGO = "/assets/images/paypal.png";
export const MASTERCARD_LOGO = "/assets/images/master-card.png";
export const VERVE_LOGO = "/assets/images/verve.svg";
export const BANK_SVG = "/assets/svgs/bank.svg"
export const PACCOUNT_SVG = "/assets/svgs/paccount.svg"
export const ENTERPRISE_SVG = "/assets/svgs/enterprise.svg"
export const CHECK_MARK_SVG = "/assets/svgs/checkmark.svg"

export const APPLICATION_STEPS = [
    "sent",
    "in_progress",
    "interview",
    "test",
    "hr",
    "waiting_user",
    "accepted",
    "rejected",
]