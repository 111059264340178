import axios from 'axios';
import { URLS } from './urls';
import { refreshToken } from './refresh_token';
import { getUserDetails } from '../components/utils/functions';
// const dotenv = require("dotenv")
console.log("\n\n",process.env.REACT_APP_AUHT_URL,"\n\n")

const API = axios.create({
    baseURL: process.env.REACT_APP_AUHT_URL,
    timeout: 100000,
});
const FFORCASTAPI = axios.create({
    baseURL: process.env.REACT_APP_FFORCAST_URL,
    // timeout:1000
});
const AUTHAPI = axios.create({
    baseURL: process.env.REACT_APP_AUHT_URL,
});

const USERAPI = axios.create({
    baseURL: process.env.REACT_APP_AUHT_URL,
});

const PAYMENTAPI = axios.create({
    baseURL: process.env.REACT_APP_PAYMENT_URL,
})

const CRRAPI =axios.create({
    baseURL: process.env.REACT_APP_RATING_URL,
    timeout: 100000,
});

const CONFIGAPI = axios.create({
    baseURL: process.env.REACT_APP_CONF_URL,
    timeout: 100000,
})

const PARAMAPI = axios.create({
    baseURL: process.env.REACT_APP_PARAM_URL,
    timeout: 10000000,
})

// API.interceptors.request.use(
//     async (config) => {
//         const user = getUserDetails()
//         // console.log(user?.access_token)
//         // const session = token;
//         const token = user?.access_token;

//         if (token) {
//             config.headers = {
//                 ...config.headers,
//                 Authorization: user ? `Bearer ${user?.access_token ?? ""}` : undefined
//             };
//         }
//         return config;
//     },
//     (error) => Promise.reject(error)
// );
// // Response interceptor for API calls
// API.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     async function (error) {
//         const originalRequest = error.config;
//         if (error.response?.status === 403 || (error.response?.status === 401 && !originalRequest._retry)) {
//             originalRequest._retry = true;
//             const access_token = await refreshToken();
//             // CONTACTAPI.defaults.headers.common['Authorization'] = access_token;
//             originalRequest.headers = {
//                 ...originalRequest.headers,
//                 Authorization: `Bearer ${access_token}`
//             };
//             return axios(originalRequest);
//         }
//         return Promise.reject(error);
//     }
// );
USERAPI.interceptors.request.use(
    async (config) => {
        const user = getUserDetails()
        // console.log(user?.access_token)
        // const session = token;
        const token = user?.access_token;

        if (token) {
            config.headers = {
                ...config.headers,
                Authorization: user ? `Bearer ${user?.access_token ?? ""}` : undefined
            };
        }
        return config;
    },
    (error) => Promise.reject(error)
);
USERAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response?.status === 403 || (error.response?.status === 401 && !originalRequest._retry)) {
            originalRequest._retry = true;
            const access_token = await refreshToken();
            // CONTACTAPI.defaults.headers.common['Authorization'] = access_token;
            originalRequest.headers = {
                ...originalRequest.headers,
                Authorization: `Bearer ${access_token}`
            };
            return axios(originalRequest);
        }
        return Promise.reject(error);
    }
);
CRRAPI.interceptors.request.use(
    async (config) => {
        const user = getUserDetails()
        const token = user?.access_token;

        if (token) {
            config.headers = {
                ...config.headers,
                Authorization: user ? `Bearer ${user?.access_token ?? ""}` : undefined
            };
        }
        return config;
    },
    (error) => Promise.reject(error)
);
FFORCASTAPI.interceptors.request.use(
    async (config) => {
        const user = getUserDetails()
        const token = user?.access_token;

        if (token) {
            config.headers = {
                ...config.headers,
                Authorization: user ? `Bearer ${user?.access_token ?? ""}` : undefined,
            };
        }
        return config;
    },
    (error) => Promise.reject(error)
);
// Response interceptor for API calls
CRRAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response?.status === 403 || (error.response?.status === 401 && !originalRequest._retry)) {
            originalRequest._retry = true;
            const access_token = await refreshToken();
            // CONTACTAPI.defaults.headers.common['Authorization'] = access_token;
            originalRequest.headers = {
                ...originalRequest.headers,
                Authorization: `Bearer ${access_token}`
            };
            return axios(originalRequest);
        }
        return Promise.reject(error);
    }
);
// Response interceptor for API calls
PAYMENTAPI.interceptors.request.use(
    async (config) => {
        const user = getUserDetails()
        // console.log(user?.access_token)
        // const session = token;
        const token = user?.access_token;

        if (token) {
            config.headers = {
                ...config.headers,
                Authorization: user ? `Bearer ${user?.access_token ?? ""}` : undefined
            };
        }
        return config;
    },
    (error) => Promise.reject(error)
);
PAYMENTAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response?.status === 403 || (error.response?.status === 401 && !originalRequest._retry)) {
            originalRequest._retry = true;
            const access_token = await refreshToken();
            originalRequest.headers = {
                ...originalRequest.headers,
                Authorization: `Bearer ${access_token}`
            };
            return axios(originalRequest);
        }
        return Promise.reject(error);
    }
);
CONFIGAPI.interceptors.request.use(
    async (config) => {
        const user = getUserDetails()
        // console.log(user?.access_token)
        // const session = token;
        const token = user?.access_token;

        if (token) {
            config.headers = {
                ...config.headers,
                Authorization: user ? `Bearer ${user?.access_token ?? ""}` : undefined
            };
        }
        return config;
    },
    (error) => Promise.reject(error)
);
// Response interceptor for API calls
CONFIGAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response?.status === 403 || (error.response?.status === 401 && !originalRequest._retry)) {
            originalRequest._retry = true;
            const access_token = await refreshToken();
            // CONTACTAPI.defaults.headers.common['Authorization'] = access_token;
            originalRequest.headers = {
                ...originalRequest.headers,
                Authorization: `Bearer ${access_token}`
            };
            return axios(originalRequest);
        }
        return Promise.reject(error);
    }
);
PARAMAPI.interceptors.request.use(
    async (config) => {
        const user = getUserDetails()
        // console.log(user?.access_token)
        // const session = token;
        const token = user?.access_token;

        if (token) {
            config.headers = {
                ...config.headers,
                Authorization: user ? `Bearer ${user?.access_token ?? ""}` : undefined
            };
        }
        return config;
    },
    (error) => Promise.reject(error)
);
// Response interceptor for API calls
PARAMAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response?.status === 403 || (error.response?.status === 401 && !originalRequest._retry)) {
            originalRequest._retry = true;
            const access_token = await refreshToken();
            // CONTACTAPI.defaults.headers.common['Authorization'] = access_token;
            originalRequest.headers = {
                ...originalRequest.headers,
                Authorization: `Bearer ${access_token}`
            };
            return axios(originalRequest);
        }
        return Promise.reject(error);
    }
);

FFORCASTAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response?.status === 403 || (error.response?.status === 401 && !originalRequest._retry)) {
            originalRequest._retry = true;
            const access_token = await refreshToken();
            // CONTACTAPI.defaults.headers.common['Authorization'] = access_token;
            originalRequest.headers = {
                ...originalRequest.headers,
                Authorization: `Bearer ${access_token}`,
                Sender: "Ronald Vincent"
            };
            return axios(originalRequest);
        }
        return Promise.reject(error);
    }
);

export { API, AUTHAPI, CRRAPI, CONFIGAPI, PARAMAPI, USERAPI, PAYMENTAPI, FFORCASTAPI };
