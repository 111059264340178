import { toast } from "react-toastify"
import { API } from "./axios";
import { URLS } from "./urls";


export const logout = () => {

    const id = toast.loading("LoggedOut");
    API.get(URLS.AUTHS.LOGOUT).then(() => {
        window.localStorage.removeItem("user-details");
        window.localStorage.removeItem("countries");
        window.localStorage.removeItem("legal_status");
        window.localStorage.removeItem("masses");
        window.localStorage.removeItem("activities");
        window.localStorage.removeItem("qualitative_data");
        window.localStorage.removeItem("subscribtions");
        window.localStorage.removeItem("redirect");
        window.localStorage.removeItem("lastExternalReferrerTime");
        window.localStorage.removeItem("lastExternalReferrer");
        window.location.pathname = "/account/login";
        toast.dismiss(id)
    }).catch((error) => {
        console.log(error)
        toast.update(id, {render: error?.response?.data?.detail, type: "error", isLoading: false, autoClose:true});
    })
}