import {Outlet} from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { changeLanguage } from "../../store/slices/language";
import i18n from "../../translations/translations";


const HomeLayout = () => {
    const dispatch = useDispatch();
    const {auth} = useSelector((state) => state.auth)
    useEffect(()=>{
        const lng = window.localStorage.getItem("lang");
        dispatch(changeLanguage(lng === "fr-FR" ? lng : "en-US"));
        i18n.changeLanguage(lng === "fr-FR" ? lng : "en-US");
    },[dispatch])

    return(
        <>
            <div id="loading">
                <div id="loading-center">
                    <div className="preloader"></div>
                </div>  
            </div>
            <div className="body-overlay"></div>
            <Outlet />
        </>
    )
}

export default HomeLayout;