import { curencyFormater } from "../../../components/utils/functions"

const BusinessRevenueTable = ({businessRevenues, t}) => {
    return (<div className="card text-left o-hidden">
        <div className="card-header d-flex align-items-center border-0 my-header">
            <h3 className="w-100 float-left card-title m-0 text-white">{t("OTHERCRRINFO.ACTIVTYBREAKDOWN")}</h3>
        </div>
        <div className="table-responsive table-strip">
                <table className="table table-hover table-bordered">
                    <thead>
                        {/* <tr style={{backgroundColor:"var(--tp-common-gray)"}}>
                            <th scope="col">Nombre d'entreprises</th>
                            <th scope="col"style={{padding:0}}>
                                <Select 
                                    className="basic-single"  
                                    styles={inLineSelectStyles} 
                                    menuPortalTarget={document.body}
                                    onChange={(e)=>{getBunusnessRev(e.value)}}
                                    options={[
                                        {label:1, value:1},
                                        {label:2, value:2},
                                        {label:3, value:3},
                                        {label:4, value:4},
                                        {label:5, value:5},
                                    ]}
                                    />
                            </th>
                        </tr> */}
                        {businessRevenues.length!==0 &&
                        <tr style={{backgroundColor:"var(--tp-common-gray)"}}>
                            <th>Activity</th>
                            <th>Revenue</th>
                        </tr>
                        }
                    </thead>
                    <tbody>
                        {businessRevenues.map((val, index)=>(
                            <tr key={index}>
                                <td >
                                    {val.business?.substring(0,40)}
                                    {/* <Select className="basic-single"
                                    styles={inLineSelectStyles} 
                                    menuPortalTarget={document.body}
                                    onChange={(e)=>{saveBusinessRevenu(e.value,"business",index)}}
                                    options = {activities.map((act)=>{
                                        return {label: act.title, value: act.title}
                                    })}
                                    /> */}
                                        {/* {activities.map((act)=>(
                                            <option key={act.key} value={act.title}>{act.title}</option>
                                        ))}
                                    </select> */}
                                </td>
                                <td style={{textAlign:"right"}}>
                                    {curencyFormater(val.revenue)}
                                    {/* <input type="text" 
                                    style={{borderStyle:"none", marginBottom:0, width:"100%", textAlign:"right"}} 
                                    placeholder="1 000 000 000" className="" 
                                    onChange={(e)=>{saveBusinessRevenu(e,"revenue",index)}}/> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
        </div>
    </div>)
}
export default BusinessRevenueTable