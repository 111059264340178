import { useState } from "react";
import { FFORCASTAPI } from "../../../services/axios";
import { URLS } from "../../../services/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const UploadFile = ({name, addFormData, year}) => {
    const [dsfFiles, setDsfFiles] = useState([]);
    const formData = new FormData()
    const addFile=(e)=>{
        let files=dsfFiles;
        let file=e.target
        files.push(e.target.value.file)
        formData.append(file.name, file.files[0], file.name)
    }
    return (
        <div className="card text-left">
            <div className="card-body">
                <h4 className="card-title">Select the DSF file for {year}</h4>
                <div className="dropzone dropzone-area" id="button-select-upload" action="#">
                    <div className="fallback">
                        <input name={name} type="file" accept=".xlsx" onChange={(e)=>{addFormData(e)}}/>
                    </div>
                    <div className="dz-message">Drop Files Here To Upload</div>
                </div>
            </div>
        </div>
    )
}

const SMEFFFormInit = ({annee, setStep, setPeriod, setYear, period, year, setTransactionId, setLoading, setExtractedData, forcastingPlug, setForcastingPlug, currency, setCurrency}) =>{
    const [filesInputs, setFilesInputs ]= useState([]);
    const [formData, setFormData] = useState([])
    const [id, setId] = useState(null)
    const addFormData = (e) => {
        // console.log("Adding data to form...........")
        let f_data = formData===null?[]:formData;
        const file=e.target;
        // console.log(file.name)
        // console.log(file.files[0])
        f_data.push(file)
        setFormData(f_data)
        // console.log(formData)
        // if(!formData){
        //     f_data = new FormData();
        //     let f_data=formData;
        // }
    }
    const changePeriod=(e)=>{
        // console.log(e?.target.value);
        setPeriod(e?.target.value)
        if(e.target.value==2){
            // console.log("Here")
            setFilesInputs(["1","2"])
        }else if(e.target.value==3){
            setFilesInputs(["1","2","3"])
        }else if(e.target.value==4){
            setFilesInputs(["1","2","3","4"])
        }else{
            setFilesInputs(["1","2","3","4","5"])
        }
    }
    const uploadFile=(e)=>{
        if(formData.length!==filesInputs.length){
            toast.error(`Upload ${filesInputs.length} consecutive files and try again!`, {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
            return
        }
        setLoading(true)
        FFORCASTAPI.post(URLS.FF.create(),{"years":period, "first_year": year, "plug": forcastingPlug, "currency": currency}).then((resp)=>{
            let data = new FormData()
            formData?.forEach((element)=>{
                data.append(element.name,element.files[0]);
            })
            console.log(formData)
            FFORCASTAPI.post(URLS.FF.extractData(resp.data.id), data).then((resp2)=>{
                toast.success("Success Extracting Data", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
                console.log(resp2.data)
                setExtractedData(resp2.data)
                setId(resp.data.id)
                setTransactionId(resp.data.id);
                setLoading(false)
            })
            .catch((error)=>{
                setLoading(false)
                console.log(error.toJSON())
                toast.error("Error Extracting Data", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
            })
        })
        .catch((error)=>{
            // console.log(error)
            toast.error("Error Creating Data", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
            setLoading(false);
        })
        e.preventDefault()
    }
   return (
        <div className="col-xl-12 mt-3 mb-3">
            <div className="card text-left o-hidden">
                <div className="card-header d-flex align-items-center border-0 my-header">
                    Basic Forcasting Informations <span className="float-right"><button><i className="nav-icon i-coffee font-weight-bold"></i></button></span>
                </div>
                <div className="card-body" style={{backgroundColor:"#ffff"}}>
                        <div className="row">
                            <div className="form-group mt-4 col-xl-3">
                                <label>Provide the first year of forcasting <span className="ml-2 text-danger">*</span></label>
                                <FontAwesomeIcon icon={faQuestionCircle} data-toggle="tooltip" data-placement="bottom"  title="Describtion in tooltip" className="btn btn-default float-right"/>
                                <select className="form-control" onChange={(e)=>{setYear(e.target.value)}} value={year}>
                                    <option value="">Select The Year</option>
                                    <option value={annee}>{annee}</option>
                                    <option value={annee-1}>{annee-1}</option>
                                    <option value={annee-2}>{annee-2}</option>
                                    <option value={annee-3}>{annee-3}</option>
                                    <option value={annee-4}>{annee-4}</option>
                                </select>
                            </div>
                            <div className="form-group mt-4 col-xl-3">
                                <label>Currency to be used <span className="ml-2 text-danger">*</span></label>
                                <FontAwesomeIcon icon={faQuestionCircle} data-toggle="tooltip" data-placement="bottom"  title="Describtion in tooltip" className="btn btn-default float-right"/>
                                <select className="form-control" onChange={(e)=>{setCurrency(e.value)}} value={currency}>
                                    <option value="">Select Currency</option>
                                    <option value={"XAF"}>XAF</option>
                                    <option value={"XOF"}>XOF</option>
                                    <option value={"Others"}>Others</option>
                                    {/* <option value={5}>5</option> */}
                                </select>
                            </div>
                            <div className="form-group mt-4 col-xl-3">
                                <label>Select the Parameter for your forcating <span className="ml-2 text-danger">*</span></label>
                                <FontAwesomeIcon icon={faQuestionCircle} className="btn btn-default float-right" data-toggle="tooltip" data-placement="bottom"  title="Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                Quo porro totam suscipit, dolor optio illo ab?"/>
                                <select className="form-control" onChange={(e)=>{setForcastingPlug(e.target.value)}} value={forcastingPlug}>
                                    <option value="">Select </option>
                                    <option value={'STD'}>Short Term Debt</option>
                                    <option value={'STD-LTD'}>Short term Debt and Long Term Debt</option>
                                    <option value={'STD-LTD-EQ'}>Short term Debt and long Term </option>
                                    {/* <option value={5}>5</option> */}
                                </select>
                            </div>
                            <div className="form-group mt-4 col-xl-3">
                                <label>Select the number of years you want forecast <span className="ml-2 text-danger">*</span></label>
                                <FontAwesomeIcon icon={faQuestionCircle} data-toggle="tooltip" data-placement="bottom"  title="Describtion in tooltip" className="btn btn-default float-right"/>
                                <select className="form-control" onChange={(e)=>{changePeriod(e)}} value={period}>
                                    <option value="">Select Period</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            {filesInputs?.map((element,index) => (
                                <div key={index} className="card-body col-4" style={{backgroundColor:"#ffff"}}>
                                    <UploadFile name={`file${element}`} addFormData={addFormData} year={year-period+index+1}/>
                                </div>
                            ))}
                        </div>
                </div>
                <div className="card-footer">
                    <button className="btn btn-danger float-left" onClick={(e)=>setStep(0)}>Cancle</button>
                    <button className="btn float-right btn-primary" onClick={(e)=>uploadFile(e)}>Validate</button>
                </div>
            </div>
        </div>
    )
}

export default SMEFFFormInit;