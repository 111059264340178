import { Link } from "react-router-dom"
import { useEffect, useState } from "react";
import axios from "axios";
import { DotLoaderOverlay } from "react-spinner-overlay";

const IndustriesPage = () => {
    const [loading, setLoading] = useState(true);
    const [dataList, setDataList] = useState([]);
    useEffect(()=>{
        const url = "https://params.api.bkresearchandanalytics.com/params/industries/";
        axios.get(url).then((resp)=>{
            setDataList(resp.data);
            setLoading(false);
            console.log(dataList);
        }).catch((error)=>{
            console.log(error);
        });
    })
    return (
        <div className="row">
            <DotLoaderOverlay
            　　loading={loading} size={20}
                    color="#1D4361"
                    overlayColor="transparant"
            />
            {!loading && dataList?.count!==0 && (
                <div className="col-md-12 mb-4">
            <div className="card text-left">
                    <div className="card-body">
                        <h4 className="card-title mb-3">Zero configuration</h4>
                        <p>DataTables has most features enabled by default, so all you need to do to use it with your own ables is to call the construction function: $().DataTable();.</p>
                        <div className="table-responsive">
                            <table className="display table table-striped table-bordered" id="zero_configuration_table" style={{width:"100%"}}>
                                <thead  style={{background:"#1D4361", color:"white"}}>
                                    <tr>
                                        <th>#</th>
                                        <th>name</th>
                                        <th>Number of firms</th>
                                        <th>Amortizing period</th>
                                        <th>Updated</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataList?.results?.map((data, index)=>(
                                        <tr key={data.id}>
                                            <td>{index+1}</td>
                                            <td>{data.name}</td>
                                            <td>{data.number_of_firms}</td>
                                            <td>{data.amortizing_period}</td>
                                            <td>{data.updated}</td>
                                            <td style={{alignContent:"right"}}>
                                                <button className="btn btn-primary" type="button" style={{marginRight:10}}><i className="nav-icon i-Eye"></i></button>
                                                <button className="btn btn-danger" type="button"><i className="nav-icon i-Close-Window"></i></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                </div>)}
        </div>
    );
}
export default IndustriesPage;