import { useForm } from "react-hook-form";
import BreadCrumb from "../../components/breadcrumb"
import { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { inLineSelectStyles, styles } from "../../components/utils/reactSelect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { USERAPI } from "../../services/axios";
import { URLS } from "../../services/urls";

const AddUser = ({toEdit, onSuccess, countries, cities}) => {
    const { auth } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [searchCountries, setSearchCountries] = useState("");
    const [city, setCity] = useState();
    const [searchCities, setSearchCities] = useState("");
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm({});
    const validateEmail=(email)=>{
    const domain = email.split("@")[1];
    return email && domain !== "yahoo.com" && domain !== "gmail.com" && domain !== "hotmail.com" && domain !== "yahoo.fr" && domain !== "msn.com"
    }
    const closeModal = () =>{
        window.$("#manageUser").modal("hide");
    }
    const onSubmit = (data) => {
        setLoading(true)
        if(validateEmail(data.email)){
            toast.error("Provide a valid Professionnal Email Address ",{position:toast.POSITION.TOP_CENTER})
            setLoading(false);
            // window.$("#manageUser").modal("hide");
            // return;
        }else{
            let formData = new FormData()
            console.log(selectedCountry.value)
            formData.append("first_name", data.first_name);
            formData.append("last_name", data.last_name);
            formData.append("email", data.email);
            formData.append("profession", data.profession?? "");
            formData.append("country", selectedCountry?.value);
            formData.append("city", selectedCity?.value);
            formData.append("parent_account", auth?.user?.id);
            formData.append("phone_number", data.phone_number);

            USERAPI.post(URLS.USERS.create, formData)
            .then((resp)=>{
            toast.success("User Successfully Added, He will receive an email address for his account validation as well as a password for account access");
            setLoading(false);
            onSuccess();
            closeModal();
            reset();
            }).catch((error)=>{
                toast.error(
                    error?.response?.data?.detail ??
                    error?.response?.data?.message ??
                    "Unable to create the account due to a server's Error, try again latter"
                );
                setLoading(false);
            })
        }
    }
    return (
        <div
        className={loading?"modal fade bd-example-modal-lg disabled":"modal fade bd-example-modal-lg"}
        id="manageUser"
        tabIndex={-1}
        aria-hidden="true"
        >
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header my-header">
                        <h5 className="modal-title text-white">
                            {toEdit?("User details"):("Add User")}
                        </h5>
                        <button className="close text-white" type="button" data-dismiss="modal" aria-label="Close" onClick={()=>closeModal()}><span aria-hidden="true">×</span></button>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6 form-group mb-3">
                                    <label className="label-text">First name<span className="text-danger ml-1 text-14">*</span></label>
                                    <input className="form-control form-element" 
                                        defaultValue={toEdit?.first_name}
                                        placeholder="Nkolo"
                                        id="user-name" {...register("first_name", {required: true})}/>
                                </div>
                                <div className="col-md-6 form-group mb-3">
                                    <label className="label-text">Last name<span className="text-white ml-1 text-14">*</span></label>
                                    <input 
                                        className="form-control form-element"
                                        placeholder="Jean Vincent"
                                        defaultValue={toEdit?.last_name}
                                        id="user-name" {...register("last_name")}/>
                                </div>
                            </div>
                            <div className="separator-breadcrumb border-top"></div>
                            <div className="row">
                                <div className="col-md-6 form-group mb-3">
                                    <label className="label-text">Email<span className="text-danger ml-1 text-14">*</span></label>
                                    <input className="form-control form-element"
                                        placeholder="user@bkresearchandanalytics.com"
                                        defaultValue={toEdit?.email}
                                        id="user-name" {...register("email", {required: true})}/>
                                </div>
                                <div className="col-md-6 form-group mb-3">
                                    <label className="label-text">Profession<span className="text-danger ml-1 text-14">*</span></label>
                                    <input 
                                        className="form-control form-element" 
                                        placeholder="e.g Accountant"
                                        defaultValue={toEdit?.profession}
                                        id="user-name" {...register("profession", {required: true})}/>
                                </div>
                            </div>
                            <div className="separator-breadcrumb border-top"></div>
                            <div className="row">
                                <div className="col-md-6 form-group mb-3">
                                    <label className="label-text">Phone Number<span className="text-danger ml-1 text-14">*</span></label>
                                    <input className="form-control form-element" 
                                        placeholder="690123456"
                                        defaultValue={toEdit?.phone_number}
                                        id="user-name" {...register("phone_number", {required: true})}/>
                                </div>
                                <div className="col-md-6 form-group mb-3">
                                    <label className="label-text">Mobile<span className="text-white ml-1 text-14">*</span></label>
                                    <input 
                                        className="form-control form-element"
                                        placeholder="224678902"
                                        defaultValue={toEdit?.mobile}
                                        id="user-name" {...register("mobile")}/>
                                </div>
                            </div>
                            <div className="separator-breadcrumb border-top"></div>
                            <div className="row">
                                <div className="col-md-6 form-group mb-3">
                                    <label className="label-text">Gender <span className="text-danger ml-1 text-14">*</span></label>
                                    <select className="form-control form-element"
                                        defaultValue={toEdit?.gender}
                                        {...register("gender")}>
                                            <option value="MALE">Male</option>
                                            <option value="FEMALE">Female</option>
                                        </select>{" "}
                                    
                                </div>
                                <div className="col-md-6 form-group mb-3">
                                    <label className="label-text">Language<span className="text-danger ml-1 text-14">*</span></label>
                                    <select className="form-control form-element"
                                        defaultValue={toEdit?.language}
                                        {...register("language")}>
                                            <option value="EN">English</option>
                                            <option value="FR">French</option>
                                        </select>{" "}
                                </div>
                            </div>
                            <div className="separator-breadcrumb border-top"></div>
                            <div className="row">
                                <div className="col-md-6 form-group mb-3">
                                    <label className="label-text">Country <span className="text-danger ml-1 text-14">*</span></label>
                                    <Select className="basic-single"
                                        id="country"
                                        styles={inLineSelectStyles}
                                        isSearchable
                                        onInputChange={(value) => setSearchCountries(value)}
                                        inputId="country"
                                        value={selectedCountry}
                                        {...register("country")}
                                        isLoading={loading}
                                        onChange={(val)=>{setSelectedCountry(val)}}
                                        options={countries?.map((countr)=>{
                                            return {label: countr.name, value: countr?.id}
                                        }) ?? []}
                                        />
                                </div>
                                <div className="col-md-6 form-group mb-3">
                                    <label className="label-text">City<span className="text-white ml-1 text-14">*</span></label>
                                    <Select className="basic-single"
                                        id="city"
                                        styles={inLineSelectStyles}
                                        isSearchable
                                        inputId="city"
                                        value={selectedCity}
                                        {...register("city")}
                                        onInputChange={(value) => setSearchCities(value)}
                                        isLoading={loading}
                                        onChange={(val)=>{setSelectedCity(val)}}
                                        options={cities?.map((scity)=>{
                                            return {label: scity.name, value: scity?.id}
                                        }) ?? []}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-danger" type="button" data-dismiss="modal" onClick={()=>closeModal()}>Close</button>
                            <button className="btn btn-primary ml-2" type="submit">{toEdit?("Update User details"):("Save changes")}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddUser;