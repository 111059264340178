import Select from "react-select";
import { inLineSelectStyles} from "../../../components/utils/reactSelect";
const RDExpnesesForm = ({setRdInputMethod, rdInputMethod, rdExpenses, setRDamort, saveRDExpenses, amortPreriod, t}) => {
    return (<div className="card text-left o-hidden">
        <div className="card-header d-flex align-items-center border-0 my-header">
            <h3 className="w-100 float-left card-title m-0 text-white">{t("OTHERCRRINFO.RDEXPENSE")}</h3>
        </div>
        <div className="table-responsive table-strip">
            <table className="table table-hover table-bordered">
                <thead>
                    <tr style={{backgroundColor:"var(--tp-common-gray)", color:"black"}}>
                        <th scope="col">methode d'entree</th>
                        <th scope="col"style={{padding:0, marginTop:0}}>
                            <Select className="basic-single"  
                            styles={inLineSelectStyles}
                            menuPortalTarget={document.body} 
                            onChange={(e)=>{setRdInputMethod(e.value);if(e.value===1){setRDamort(amortPreriod)}}}
                            options={[{label:"Direct", value:1}, {label:"Indirect", value:2}]}
                            />
                        </th>
                    </tr>
                    {rdInputMethod===2 && <tr style={{backgroundColor:"#fff"}}>
                        <th scope="col">{t("textNumYears")}</th>
                        <th scope="col"style={{padding:0, marginTop:0}}>
                            <Select className="basic-single"  
                            styles={inLineSelectStyles} 
                            menuPortalTarget={document.body}
                            onChange={(e)=>{setRDamort(e.value)}}
                            options={[
                                {label:1, value:1},
                                {label:2, value:2},
                                {label:3, value:3},
                                {label:4, value:4},
                                {label:5, value:5},]}
                            />
                        </th>
                    </tr>}
                    <tr style={{backgroundColor:"var(--tp-common-gray)"}}>
                        <th scope="col">Year</th>
                        <th scope="col" style={{textAlign:"right"}}>{t("textAmount")}</th>
                    </tr>
                </thead>
                <tbody>
                    {rdExpenses.map((val, index)=>(
                        <tr>
                            <td >{val.year}</td>
                            <td style={{padding:0, marginTop:0}}>
                                <input 
                                    type="number"
                                    style={{borderStyle:"none", marginBottom:0, width:"100%", textAlign:"right"}} 
                                    placeholder="1 000 000 000" className="" 
                                    onChange={(e)=>{saveRDExpenses(e,index)}}/>
                            </td>
                        </tr>)
                    )
                    }
                </tbody>
            </table>
        </div>
    </div>)
}
export default RDExpnesesForm;