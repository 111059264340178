import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import DashboardLayout from "../components/layouts/dashboardLayout";
import DashBoardPage from "../pages/dashboard/pages";
import LoginPage from "../pages/user/login";
import HomeLayout from "../components/layouts/homeLayout";
import SMECRRLayout from "../components/layouts/sme-crrlayout";
import HomeSMECRR from "../pages/sme-crr";
import SMECRRForm from "../pages/sme-crr/form";
import ParamsLayout from "../components/layouts/paramsLayout";
import InflationRatePage from "../pages/system-parameters/inflation_rate";
import CDSSpreadPage from "../pages/system-parameters/cds_spread";
import PRSEquivalentPage from "../pages/system-parameters/prs_equivalent";
import PRSScorePage from "../pages/system-parameters/prs_score";
import ActivitiesPage from "../pages/system-parameters/activies";
import IndustriesPage from "../pages/system-parameters/industries";
import CountriesPage from "../pages/system-parameters/countries";
import BillingPage from "../pages/billing";
import SignupPage from "../pages/user/signup";
import PasswordRecoverPage from "../pages/user/password-reset";
import NotFoundPage from "../pages/not-found";
import SMECRRDetails from "../pages/sme-crr/details";
import UserProfileSettings from "../pages/user/profile-settings";
import MembersPage from "../pages/groups-members";
import MemberDetails from "../pages/groups-members/member-details";
import MembersLayout from "../components/layouts/members-layout";
import SMECRRList from "../pages/sme-crr/list";
import MembersList from "../pages/groups-members/list";
import AddUser from "../pages/groups-members/form";
import HomeSMEFForcasting from "../pages/financial-forcasting";
import SMEFForcastingForm from "../pages/financial-forcasting/form";
import SMEFForcastingList from "../pages/financial-forcasting/list";
import FForcastingDetails from "../pages/financial-forcasting/details";
import { isActivatedAcc, isCompany } from "../components/utils/functions";
import ComplianceHome from "../components/compliance";


const AppRoutes = () => {

    return (
        <Router>
            <Routes>
                <Route element={<HomeLayout/>}>
                <Route element={<DashboardLayout/>} path="">
                    <Route index path="/" element={isActivatedAcc()?<DashBoardPage />:<ComplianceHome/>} />
                    <Route path="billing" element={isActivatedAcc()?<BillingPage />:<ComplianceHome/>}/>
                    <Route path="sme-crr/" element={isActivatedAcc()?<SMECRRLayout/>:<ComplianceHome/>}>
                        <Route index path="" element={<HomeSMECRR/>}/>
                        <Route path="details/:key" element={<SMECRRDetails/>}/>
                        <Route path="details/:key/:ref" element={<SMECRRDetails/>}/>
                        <Route path="list" element={<SMECRRList/>}/>
                        <Route path="new" element={<SMECRRForm/>}/>
                    </Route>
                    <Route path="parameters/" element={isActivatedAcc()?<ParamsLayout/>:<ComplianceHome/>}>
                        <Route index path="inflation-rate" element={<InflationRatePage/>}/>
                        <Route path="activities" element={<ActivitiesPage/>}/>
                        <Route path="countries" element={<CountriesPage/>}/>
                        <Route path="industries" element={<IndustriesPage/>}/>
                        <Route path="cds-spread" element={<CDSSpreadPage/>}/>
                        <Route path="prs-equivalent" element={<PRSEquivalentPage/>}/>
                        <Route path="prs-score" element={<PRSScorePage/>}/>
                    </Route>
                    <Route path="group-members" element={isActivatedAcc() && isCompany()?<MembersLayout/>:<ComplianceHome/>}>
                        <Route element={<MembersPage/>} index path=""/>
                        <Route element={<MembersList/>} path="list"/>
                        <Route element={<AddUser/>} path="new"/>
                        <Route element={<MemberDetails/>} path="details/:key"/>
                    </Route>
                    <Route path="f-forcasting" element={isActivatedAcc()?<SMECRRLayout/>:<ComplianceHome/>}>
                        <Route index path="" element={<HomeSMEFForcasting/>}/>
                        <Route path="list" element={<SMEFForcastingList/>}/>
                        <Route path="new" element={<SMEFForcastingForm/>}/>
                        <Route path="details/:key" element={<FForcastingDetails/>}/>
                        <Route path="details/:key/:ref" element={<FForcastingDetails/>}/>
                    </Route>
                    <Route element={<UserProfileSettings/>} path="user-profile-settings"/>
                </Route>
                
                <Route path="account/login" element={<LoginPage />} />
                <Route path="account/sign-up" element={<SignupPage />}/>
                <Route path="account/validation/:emailKey" element={<LoginPage />}/>
                <Route path="account/reset-password" element={<PasswordRecoverPage />}/>
                <Route path="account/reset-confirm/:uuid/:token" element={<PasswordRecoverPage />}/>

                <Route path="*" element={<NotFoundPage/>}/>
                </Route>
            </Routes>
        </Router>
    )
}

export default AppRoutes;