import {Link, Outlet} from "react-router-dom"
import { useSelector } from "react-redux";


const MembersLayout = () => {

    const {auth} = useSelector((state) => state.auth)

    return(
        <div className="main-content">
        <Outlet />
        </div>
    )
}

export default MembersLayout;