import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import axios from "axios";
import Select from "react-select";
import BreadCrumb from "../../components/breadcrumb"
import { inLineSelectStyles, inLineSelectStyles2, styles } from "../../components/utils/reactSelect";
import SMEFFFormInit from "./components/form-init";
import { toast } from "react-toastify";
import FormFileUpload from "./components/file-uplaod";
import { URLS } from "../../services/urls";
import LoaderPage from "../../components/loader";
import { getMasse, listToMasses3 } from "../../components/utils/functions";
import DisplayFStatement from "./components/raw_statement_display";
import { CONFIGAPI, FFORCASTAPI } from "../../services/axios";
import ForcastingData from "./components/forcasting_data";
import { MockData } from "../../mock-data/data";
import AsumptionTable from "./components/asumptions-pane";

const SMEFForcastingForm = () =>{
    const annee=(new Date().getFullYear());
    const [forcastingPlug, setForcastingPlug] = useState("STD");
    const [formStep, setFormStep] = useState(0)
    const [year, setYear] = useState(annee)
    const [period, setPeriod] = useState(0)
    const [stockPrice, setStockPrice] = useState(0);
    const [shares, setShares] = useState(0); // Number of shares
    const [dsfFiles, setDsfFiles] = useState([]);
    const [transactionId,setTransactionId] = useState(null);
    const [masses, setMasses] = useState([])
    const [loading,setLoading] = useState(false);
    const [extracted, setExtracted] = useState(null)
    const [preLoading, setPreloading] = useState(true)
    const [assets, setAssets] = useState([]);
    const [liabilities, setLiabilities] = useState([]);
    const [incomeStatement, setIncomeStatement] = useState([])
    const [cashflow, setCashflow] = useState([]);
    const [assetsData, setAssetsData] = useState([]);
    const [liabilitiesData, setLiabilitiesData] = useState([]);
    const [incomeStatementData, setIncomeStatementData] = useState([])
    const [cashflowData, setCashflowData] = useState([]);
    const [asumptionsList, setAsumptionList] = useState([]);
    const [asumptions, setAsumption] = useState(null);
    const [restructuredData, setRestructuredData] = useState(null);
    const [jsonRestructuredData, setJsonRestructuredData] = useState(null);
    const [forcast, setForcast] = useState(null);
    const [dataLength, setDataLength] = useState(2);
    const [forcasted, setForcasted] = useState(false);
    const [initialValuationRatio, setInitialValuationRatio] = useState(null);
    const [finalValuationRatio, setFinalValuationRatio] = useState(null);
    const [commonSizeStatement, setCommonSizeStatement] = useState(null);
    const [currency, setCurrency] = useState("XAF");
    const navigate = useNavigate();
    const cancel = () =>{
        setPeriod(2);
        setYear(annee);
        setFormStep(0)
    }
    const setExtractedData = async (data)=>{
        console.log(data)
        setExtracted(data);
        setDataLength(data['income_statement'][0].length-1)
        const d_ = await listToMasses3(assets, liabilities, incomeStatement, cashflow, data);
        console.log(d_['liabilities']);
        setAssetsData(d_['assets']);
        setLiabilitiesData(d_['liabilities']);
        setCashflowData(d_['cashflow']);
        setIncomeStatementData(d_['income'])
    }

    const makeForcasting=()=>{
        setLoading(true);
        FFORCASTAPI.post(URLS.FF.forcast(transactionId),{"asumptions":asumptions, "restructured":restructuredData})
        .then((resp)=>{
            toast.success("Operation successfuly completed, Check your result", {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
            setForcast(resp.data.forecast);
            setFinalValuationRatio(resp.data.valuation_ratios)
            setLoading(false);
            setForcasted(true);
        }).catch((error)=>{
            setLoading(false);
            toast.error("Unable do perform operation. Wait a moment and reload again", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
        })
    }

    const getUnbalancedState = async () => {
        setLoading(true);
        console.log(extracted)
        await FFORCASTAPI.post(URLS.FF.getUnbalanced(transactionId),extracted)
        .then((resp)=>{
            // console.log(resp.data.unbalanced_forcast)
            setRestructuredData(resp.data.restructured);
            setForcast(resp.data.unbalanced_forcast);
            setAsumption(resp.data.assumptions);
            setCommonSizeStatement(resp.data.common_size_statement);
            setJsonRestructuredData(resp.data.json_restructured);
            setInitialValuationRatio(resp.data.valuation_ratios);
            setLoading(false)
            toast.success("Your Forasting was properly initiated", {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
            toast.warning("Make sure you provide the proper plugs assumption parameters and sumilate", {position:toast.POSITION.BOTTOM_RIGHT, theme:"colored", autoClose: false})
        }).catch((error)=>{
            setLoading(false)
            toast.error("Make sure your data is consistent and reload again", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
        })
        console.log(restructuredData)
    }

    const saveData = () => {
        setLoading(true);
        const data = {
            "balance": {
                "assets": assetsData,
                "liabilities":liabilitiesData,
                "income": incomeStatementData,
                "cashflow": cashflowData
            },
            "restructured": jsonRestructuredData,
            "common_size": commonSizeStatement,
            "forecast": forcast,
            "initial_valuation_ratio": initialValuationRatio,
            "final_valuation_ratio": finalValuationRatio,
            "asumptions": asumptions
        };
        console.log(data)
        FFORCASTAPI.post(URLS.FF.save(transactionId),data)
        .then((resp)=>{
            console.log(resp.data);
            toast.success("Forcasting Successfully saved", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
            navigate(`/f-forcasting/details/${resp.data.id}/${resp.data.id}`)
            setLoading(false);
        }).catch((err)=>{
            console.log(err)
            toast.error("Error saving your data, try in a moment", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
            setLoading(false);
        })
    }

    useEffect(()=>{
        setFormStep(0)
        axios.get(`${URLS.CONF_URL}/rating/masses/?limit=1000`)
        .then((resp)=>{
            console.log(resp.data.results);
            const d_ = getMasse(resp.data.results);
            setAssets(d_['assets']);
            setLiabilities(d_['liabilities']);
            setCashflow(d_['cashflow']);
            setIncomeStatement(d_['income'])
            CONFIGAPI.get(URLS.CONFIGURATIONS.FINFORCASTING.assumptions())
            .then((resp)=>{            
                setPreloading(false);
                setAsumptionList(resp.data.results);
            })
        }).catch((err)=>{
            console.log(err)
            setPreloading(false)
        })
    },[])
    // console.log(assets);
    return (
        <>
            <BreadCrumb title1="FF-SME" title2="New"/>
            {(preLoading||loading)?(
                <LoaderPage/>
            ):(
            <div className={"row"}>
                {/* <p className="text-center">Make new financial Forcasting</p> */}
                {transactionId===null?(
                    // <div className="col-12 justify-content-center" style={{display:"flex", alignItems:"center", height:"inherit"}}>
                    <SMEFFFormInit annee={annee} period={period} year={year} setPeriod={setPeriod} 
                    setStep={setFormStep} setYear={setYear} setTransactionId={setTransactionId} 
                    setLoading={setLoading} setExtractedData={setExtractedData}
                    forcastingPlug={forcastingPlug} setForcastingPlug={setForcastingPlug} currency={currency} setCurrency={setCurrency}/>
                    // </div>
                ):(
                    <>
                        {/* <DisplayFStatement assets={assets} liabilities={liabilities} income={incomeStatement} cashflow={cashflow}/> */}
                        {asumptions==null?(
                            <>
                                <div className="col-12 mt-3 mb-4"><button className="btn btn-primary float-right" onClick={(e)=>getUnbalancedState()}>Proceed</button></div>
                                <DisplayFStatement assets={assetsData} liabilities={liabilitiesData} income={incomeStatementData} cashflow={cashflowData} length={dataLength}/>
                            </>
                            ):
                            (<>
                                {/* <div className="row"> */}
                                    <div className="col-12 mb-4">
                                        {forcasted===true &&(
                                            <button className="btn btn-success float-right" onClick={(e)=>{saveData()}} style={{marginLeft:15}}>Save</button>
                                        )}
                                        <button className="btn btn-primary float-right" onClick={(e)=>{makeForcasting()}}>Simulates</button>
                                    </div>
                                {/* </div> */}
                                <AsumptionTable  transactionId={transactionId} setLoading={setLoading} assumptions={asumptionsList} actualAssumptions={asumptions} forcastingPlug={forcastingPlug} setForcastingPlug={setForcastingPlug}/>
                                <ForcastingData forecast={forcast} restructured={jsonRestructuredData} year={year} number={period}/>
                            </>)
                        }
                    </>
                )} 
            </div>
            )}
        </>
    )
}

export default SMEFForcastingForm;