import { useState } from "react";
import { curencyFormater } from "../../../components/utils/functions";
import { useTranslation } from "react-i18next";

const Forcasted = ({data,number, year}) => {
    const {t} = useTranslation()
 return (
    <table className="table-striped table-bordered table-responsive-lg table">
        <thead className="" style={{backgroundColor:"var(--tp-common-gray)"}}>
            <tr>
                <th scope="col">Label</th>
                {number>=5 &&(<th scope="col">{year-4}</th>)}
                {number>=4 &&(<th scope="col">{year-3}</th>)}
                {number>=3 &&(<th scope="col">{year-2}</th>)}
                <th scope="col">{year-1}</th>
                <th scope="col">{year}</th>
            </tr>
        </thead>
        <tbody className="table-striped table-bordered table-responsive-lg table">
            <tr><td colSpan={4} className="text-center" style={{backgroundColor:"var(--tp-common-gray)"}}>Income</td></tr>
            {data?.income.map((income, index)=>(
                <tr key={index}>
                    <td>{t(`FINFORCASTING.${income?.reference}`)}</td>
                    {number>=5 &&(<td className="text-right">{curencyFormater(income?.net4)}</td>)}
                    {number>=4 &&(<td className="text-right">{curencyFormater(income?.net3)}</td>)}
                    {number>=3 &&(<td className="text-right">{curencyFormater(income?.net2)}</td>)}
                    <td className="text-right">{curencyFormater(income?.net1)}</td>
                    <td className="text-right">{curencyFormater(income?.net)}</td>
                </tr>
            ))}
            <tr><td colSpan={4} className="text-center" style={{backgroundColor:"var(--tp-common-gray)"}}>Balance Sheet</td></tr>
            <tr><td colSpan={4} className="text-center" style={{backgroundColor:"var(--tp-common-gray)"}}>Assets</td></tr>
            {data?.assets.map((asset, index)=>(
                <tr key={index}>
                    <td>{t(`FINFORCASTING.${asset?.reference}`)}</td>
                    {number>=5 &&(<td className="text-right">{curencyFormater(asset?.net4)}</td>)}
                    {number>=4 &&(<td className="text-right">{curencyFormater(asset?.net3)}</td>)}
                    {number>=3 &&(<td className="text-right">{curencyFormater(asset?.net2)}</td>)}
                    <td className="text-right">{curencyFormater(asset?.net1)}</td>
                    <td className="text-right">{curencyFormater(asset?.net)}</td>
                </tr>
            ))}
            <tr><td colSpan={4} className="text-center" style={{backgroundColor:"var(--tp-common-gray)"}}>Liabilities and shareholder's Equity</td></tr>
            {data?.liabilities.map((liability, index)=>(
                <tr key={index}>
                    <td>{t(`FINFORCASTING.${liability?.reference}`)}</td>
                    {number>=5 &&(<td className="text-right">{curencyFormater(liability?.net4)}</td>)}
                    {number>=4 &&(<td className="text-right">{curencyFormater(liability?.net3)}</td>)}
                    {number>=3 &&(<td className="text-right">{curencyFormater(liability?.net2)}</td>)}
                    <td className="text-right">{curencyFormater(liability?.net1)}</td>
                    <td className="text-right">{curencyFormater(liability?.net)}</td>
                </tr>
            ))}
        </tbody>
    </table>);
}
const ForcastingData = ({forecast, restructured, year, number}) => {
    const [balanced, setBlanced] = useState(false)
    console.log(forecast)
    const {t} = useTranslation();
    return (
        <>
            {/* <h1 className="text-center">Forcasting forecast</h1> */}
            <div className="col-xl-6 mb-5">
                <div className="card text-left o-hidden">
                    <div className="card-header d-flex align-items-center border-0 my-header">
                        <h3 className="w-100 float-left card-title m-0 text-white">{t("textEndYear")} (Dec 31)</h3>
                    </div>
                    <div className="table-responsive">
                        <Forcasted data={restructured} year={year} number={number}/>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 mb-5">
                <div className="card text-left o-hidden">
                    <div className="card-header d-flex align-items-center border-0 my-header">
                        <h3 className="w-100 float-left card-title m-0 text-white">{t("textFNextYear")} {number} {t("textYear")}</h3>
                    </div>
                    <div className="table-responsive">
                        <Forcasted data={forecast} year={year} number={number}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForcastingData;