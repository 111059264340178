import { Link } from "react-router-dom"
import useSWR from "swr";
import { useState } from "react";
import { URLS } from "../../services/urls";
import LoaderPage from "../../components/loader";
import { CircleSpinnerOverlay, DotLoaderOverlay, DotLoader } from 'react-spinner-overlay'
import BreadCrumb from "../../components/breadcrumb";
import { CRRAPI } from "../../services/axios";
import NoTransaction from "../../components/no-transaction/emptypage";
const fetcher2 = (url) => CRRAPI.get(url).then(res => {
    console.log(res.data);return res.data})

const SMECRRList = () => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const { data: ratings, isLoading} = useSWR(URLS.CRR.list(page), fetcher2);
  const handleChangePage = (event, pg) => {
    event.preventDefault();
    setPage(pg);
  };
    return (
        <>
            {isLoading?(<LoaderPage/>):(
                <>
                    {/* {!isLoading && ratings?.count === 0 && (
                        <p className="text-center"> "No rating done" </p>
                    )} */}
                    {/* {!isLoading &&
                        ( */}
                <>
                    <BreadCrumb title1="CRR-SME" title2="List"/>
                    {ratings?.count===0? (
                        <NoTransaction transaction={"CRR"}/>
                    ):(
                    <div className="row mb-4">
                            <div className="col-md-12 mb-3">
                                <div className="card text-left mb-4 o-hidden">
                                    <div className="card-header d-flex align-items-center border-0 my-header">
                                        <h3 className="w-50 float-left card-title m-0 text-white">Rating list</h3>

                                    </div>
                                    <div>
                                        <div className="table-responsive">
                                            <table className="table table-hover" id="alternative_pagination_table" style={{width:"100%"}}>
                                                <thead className="">
                                                    <tr>
                                                        <th scope="col">Grade</th>
                                                        <th scope="col">Reference</th>
                                                        <th scope="col">Indicator</th>
                                                        <th scope="col">Year</th>
                                                        <th scope="col">Activity</th>
                                                        <th scope="col">Head Office</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ratings?.results?.map((rating)=>(
                                                        <tr key={rating.id}>
                                                            <td><span className="badge badge-round-info  m-1">{rating.grade}</span></td>
                                                            <td style={{padding:"auto"}}>{rating.reference}</td>
                                                            <td>{rating.indicator}</td>
                                                            <td>{rating.year}</td>
                                                            <td>{rating.activity.title}</td>
                                                            <td>{rating.head_office.name}</td>
                                                            <td>{rating.date}</td>
                                                            <td style={{alignContent:"right"}}>
                                                                <Link to={`/sme-crr/details/${rating.id}`}><button className="btn btn-primary" type="button" style={{marginRight:10}}><i className="nav-icon i-Eye"></i></button></Link>
                                                                {/* <button className="btn btn-danger" type="button"><i className="nav-icon i-Close-Window"></i></button> */}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>)}
                </>
                            {/* )
                    } */}
                </>
            )}
        </>
    );
}
export default SMECRRList;