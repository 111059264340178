import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatLinkString, isCompany, isIndividual } from "../utils/functions";
import { DEFAULT_PROFILE } from "../utils/consts";
import { logout } from "../../services/logout";
import LanguageSwitcher from "../language";

const DashboardHeader = () => {
    const {t} = useTranslation();
    function mobileHeaderActive() {
                const wrapper = window.$(".header-topnav");
                    wrapper.toggleClass('open')
            }
    function openSearch(){
		window.$(".search-area").toggleClass("search-opened");
		window.$(".search-overlay").toggleClass("opened");
    }
    const {
      auth,
    } = useSelector((state) => state.auth);
    return (      
        <>
        <div className="main-header" style={{color:"#fff", background: "var(--tp-common-white)"}} >
            <Link className="d-flex mr-10 logo" to="/">
                <img
                alt="Bk Research"
                src="./logo_final.png"
                className="logo"
                />
            </Link>  
            <div className="menu-toggle" onClick={()=>{mobileHeaderActive()}}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="d-flex align-items-center">
                {/* <!-- Mega menu--> */}
                <div className="dropdown mega-menu d-none d-md-block"><Link className="btn text-muted dropdown-toggle mr-3" id="dropdownMegaMenuButton" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Menu</Link>
                    <div className="dropdown-menu text-left" aria-labelledby="dropdownMenuButton">
                        <div className="row m-0">
                            <div className="col-md-4 p-4 bg-img">
                                <h2 className="title">More about <br /> B.K Research & Analytics</h2>
                                <p className="text-white">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Asperiores natus laboriosam fugit, consequatur.</p>
                                <p className="mb-4 text-white">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem odio amet eos dolore suscipit placeat.</p>
                                <button className="btn btn-lg btn-rounded btn-outline-warning text-white">Learn More</button>
                            </div>
                            <div className="col-md-4 p-4">
                                <p className="text-primary text--cap border-bottom-primary d-inline-block">Features</p>
                                <div className="menu-icon-grid w-auto p-0"><Link href="#"><i className="i-Shop-4"></i> Home</Link><Link href="#"><i className="i-Library"></i> UI Kits</Link><Link href="#"><i className="i-Drop"></i> Apps</Link><Link href="#"><i className="i-File-Clipboard-File--Text"></i> Forms</Link><Link href="#"><i className="i-Checked-User"></i> Sessions</Link><Link href="#"><i className="i-Ambulance"></i> Support</Link></div>
                            </div>
                            <div className="col-md-4 p-4">
                                <p className="text-primary text--cap border-bottom-primary d-inline-block">Components</p>
                                <ul className="links">
                                    <li><Link href="accordion.html">Accordion</Link></li>
                                    <li><Link href="alerts.html">Alerts</Link></li>
                                    <li><Link href="buttons.html">Buttons</Link></li>
                                    <li><Link href="badges.html">Badges</Link></li>
                                    <li><Link href="carousel.html">Carousels</Link></li>
                                    <li><Link href="lists.html">Lists</Link></li>
                                    <li><Link href="popover.html">Popover</Link></li>
                                    <li><Link href="tables.html">Tables</Link></li>
                                    <li><Link href="datatables.html">Datatables</Link></li>
                                    <li><Link href="modals.html">Modals</Link></li>
                                    <li><Link href="nouislider.html">Sliders</Link></li>
                                    <li><Link href="tabs.html">Tabs</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- / Mega menu--> */}
                <div className="search-bar" onClick={()=>{openSearch(1)}}>
                    <input type="text" placeholder="Search" autoComplete={"Search"} style={{display:"none"}}/><i className="search-icon text-muted i-Magnifi-Glass1"></i>
                </div>
            </div>
            <div style={{margin: 'auto'}}></div>
            <div className="header-part-right">
                <i className="i-Full-Screen header-icon d-none d-sm-inline-block" data-fullscreen=""></i>
                <LanguageSwitcher/>
                <div className="dropdown"><i className="i-Safe-Box text-muted header-icon" id="dropdownMenuButton" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <div className="menu-icon-grid"><Link href="#"><i className="i-Shop-4"></i> Home</Link><Link href="#"><i className="i-Library"></i> UI Kits</Link><Link href="#"><i className="i-Drop"></i> Apps</Link><Link href="#"><i className="i-File-Clipboard-File--Text"></i> Forms</Link><Link href="#"><i className="i-Checked-User"></i> Sessions</Link><Link href="#"><i className="i-Ambulance"></i> Support</Link></div>
                    </div>
                </div>
                <div className="dropdown">
                    <div className="badge-top-container" id="dropdownNotification" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="badge badge-primary" style={{background:"#1D4361", color:"#eef4ed"}}>3</span><i className="i-Bell text-muted header-icon"></i></div>
                    {/* <!-- Notification dropdown--> */}
                    <div className="dropdown-menu dropdown-menu-right notification-dropdown rtl-ps-none" aria-labelledby="dropdownNotification" data-perfect-scrollbar="" data-suppress-scroll-x="true">
                        <div className="dropdown-item d-flex">
                            <div className="notification-icon"><i className="i-Speach-Bubble-6 text-primary mr-1"></i></div>
                            <div className="notification-details flex-grow-1">
                                <p className="m-0 d-flex align-items-center"><span>New message</span><span className="badge badge-pill badge-primary ml-1 mr-1">new</span><span className="flex-grow-1"></span><span className="text-small text-muted ml-auto">10 sec ago</span></p>
                                <p className="text-small text-muted m-0">James: Hey! are you busy?</p>
                            </div>
                        </div>
                        <div className="dropdown-item d-flex">
                            <div className="notification-icon"><i className="i-Receipt-3 text-success mr-1"></i></div>
                            <div className="notification-details flex-grow-1">
                                <p className="m-0 d-flex align-items-center"><span>New order received</span><span className="badge badge-pill badge-success ml-1 mr-1">new</span><span className="flex-grow-1"></span><span className="text-small text-muted ml-auto">2 hours ago</span></p>
                                <p className="text-small text-muted m-0">1 Headphone, 3 iPhone x</p>
                            </div>
                        </div>
                        <div className="dropdown-item d-flex">
                            <div className="notification-icon"><i className="i-Empty-Box text-danger mr-1"></i></div>
                            <div className="notification-details flex-grow-1">
                                <p className="m-0 d-flex align-items-center"><span>Product out of stock</span><span className="badge badge-pill badge-danger ml-1 mr-1">3</span><span className="flex-grow-1"></span><span className="text-small text-muted ml-auto">10 hours ago</span></p>
                                <p className="text-small text-muted m-0">Headphone E67, R98, XL90, Q77</p>
                            </div>
                        </div>
                        <div className="dropdown-item d-flex">
                            <div className="notification-icon"><i className="i-Data-Power text-success mr-1"></i></div>
                            <div className="notification-details flex-grow-1">
                                <p className="m-0 d-flex align-items-center"><span>Server Up!</span><span className="badge badge-pill badge-success ml-1 mr-1">3</span><span className="flex-grow-1"></span><span className="text-small text-muted ml-auto">14 hours ago</span></p>
                                <p className="text-small text-muted m-0">Server rebooted successfully</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dropdown">
                    <div className="user col align-self-end"><img id="userDropdown" src={auth?.user.picture? formatLinkString(auth?.user?.picture): DEFAULT_PROFILE} alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                            <div className="dropdown-header"><i className="i-Lock-User mr-1"></i>{auth?.user.last_name}</div>
                            <Link className="dropdown-item" to="/user-profile-settings">{t("textAccSettings")}</Link>
                            {(isCompany() || isIndividual()) && (<Link className="dropdown-item" to="/billing">{t("textBillHistory")}</Link>)}
                            <Link className="dropdown-item" to="#" onClick={() => logout()}>{t("logOut")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="search-area">
                <div className="search-inner p-relative">
                    <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="search-wrapper">
                                <div className="search-close"  onClick={()=>{openSearch()}}>
                                <button className="search-close-btn">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 1L1 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M1 1L11 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                </div>
                                <div className="search-content pt-35">
                                <h3 className="heading text-center mb-30">{t("textSearch")}</h3>
                                <div className="d-flex justify-content-center px-5">
                                    <div className="search w-100 p-relative">
                                        <input type="text" className="search-input" placeholder="Search..."/>
                                        <button  className="search-icon">
                                        <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardHeader;