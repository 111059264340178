
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";
import DashboardHeader from "../header/DashboardHeader";
import DashboardFooter from "../footer/DashboardFooter";
import NavBar from "../navigation/DashboardNavigation";
import PrivateRoute from "../../routes/components/PrivateRoute";
import { PAYMENTAPI, USERAPI } from "../../services/axios";
import { URLS } from "../../services/urls";
import LoaderPage from "../loader";
import { setUserSubscribtions } from "../utils/functions";
import { setSubcribtions } from "../../store/slices/subscribtions";
const pFetcher = (url) => USERAPI.get(url).then((res) => res.data.results).catch((err)=>{console.log(err)});
const fetcher = (url) => PAYMENTAPI.get(url).then(res => {
  // const dispatch = useDispatch();
  console.log(res.data.datas)
  let subscribtions = [];
  res.data.datas.forEach(subs => {
    if(subs.active===true){
      subscribtions.push(subs.service_ref);
    }
  }).catch((err)=>{console.log(err)});
  setUserSubscribtions(subscribtions);
  // dispatch(setSubcribtions(subscribtions));
  return res.data.datas
})
const DashboardLayout = () => {
  const {data: subs, isLoading} = useSWR(URLS.PAYMENT.SUBSCRIBTIONS.list(), fetcher);
  const {data: accData, loadingAccDetails} = useSWR(URLS.AUTHS.me, pFetcher)
    return (
      <div className="app-admin-wrap layout-horizontal-bar">
        {loadingAccDetails?(<loadingAccDetails/>):(
          <PrivateRoute>
              <DashboardHeader />
              <NavBar />
              <div className="main-content-wrap d-flex flex-column" style={{minHeight:'85vh', background:"#ffff"}}>
              <Outlet />
              </div>
              <DashboardFooter/>
          </PrivateRoute>
        )}
      </div>
    );
  };
  
  export default DashboardLayout;
  