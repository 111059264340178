import Select from "react-select";
import { inLineSelectStyles} from "../../../components/utils/reactSelect";
const CountryRevenueForm = ({countryRevenu, countries, saveCountryRevenue, getCountryRevenu, t}) => {
    return (
        <div className="card text-left o-hidden">
            <div className="card-header d-flex align-items-center border-0 my-header">
                <h3 className="w-100 float-left card-title m-0 text-white">{t("OTHERCRRINFO.COUNTRYBREAKDOWN")}</h3>
            </div>
            <div className="table-responsive table-strip">
                <table className="table table-hover table-bordered">
                <thead style={{backgroundColor:"var(--tp-common-gray)"}}>
                    <tr>
                            <th scope="col">{t("textNumCountry")}</th>
                            <th scope="col" style={{padding:0, marginTop:0}}>
                                <Select 
                                className="basic-single"  
                                styles={inLineSelectStyles} 
                                menuPortalTarget={document.body}
                                onChange={(e)=>getCountryRevenu(e.value)}
                                    options={[
                                        {label:1, value:1},
                                        {label:2, value:2},
                                        {label:3, value:3},
                                        {label:4, value:4},
                                        {label:5, value:5}]}/>
                            </th>
                        </tr>
                    {countryRevenu.length>0 &&
                    <tr>
                        <th scope="col">{t("textCountry")}</th>
                        <th scope="col" style={{textAlign:"right"}}>Revenue</th>
                    </tr>}
                </thead>
                <tbody>
                    {countryRevenu.map((val, index)=>(
                        <tr key={index}>
                            <td style={{padding:0, marginTop:0}}>
                                <Select className="basic-single"
                                styles={inLineSelectStyles} 
                                menuPortalTarget={document.body}
                                onChange={(e)=>{saveCountryRevenue(e.value,"country",index)}}
                                options={countries.map((act)=>{
                                    return {label: t(`COUNTRY.${act.code2}`), value:act.id}
                                })}
                                />
                            </td>
                            <td style={{padding:0, marginTop:0}}>
                                <input type="number" 
                                style={{borderStyle:"none", marginBottom:0, width:"100%", textAlign:"right"}} 
                                placeholder="1 000 000 000" className=""  
                                onChange={(e)=>{saveCountryRevenue(e.target.value,"revenue",index)}}/>
                            </td>
                        </tr>
                    ))}
                </tbody>
                </table>
            </div>
        </div>
    )
}
export default CountryRevenueForm;