import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { percentageFormater } from "../../../components/utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FFORCASTAPI } from "../../../services/axios";
import { URLS } from "../../../services/urls";
import { useTranslation } from "react-i18next";

const AsumptionTable = ({assumptions, actualAssumptions, forcastingPlug, setForcastingPlug, setLoading, transactionId}) =>{
    const {t} = useTranslation();
    const [timer, setTimer] = useState(null)
    const [definedAsumptions, setDefinedAsumptions] = useState(actualAssumptions)
    const setAssumption = (value, key) => {
        clearTimeout(timer)
        console.log(key,value);
        let nAsumption = definedAsumptions;
        nAsumption[key] = value??0;
        setDefinedAsumptions(nAsumption)
        console.log(nAsumption[key])
        const newTimer = setTimeout(() => {}, 500)
      
        setTimer(newTimer)
    }
    const changePlug=(value)=>{
        setLoading(true)
        FFORCASTAPI.patch(URLS.FF.patch(transactionId),{"plug": value})
        .then((resp)=>{
            setForcastingPlug(value);
            setLoading(false);
        }).catch((error)=>{
            toast.error("Could not reset Plug, try in a moment", {position: toast.POSITION.TOP_CENTER})
            setLoading(false);
        })

    }

    useEffect(()=>{
        const timeoutId = setTimeout(() => console.log(`I can see you're not typing. I can use "${definedAsumptions}" now!`), 1000);
        return () => clearTimeout(timeoutId);
    },[definedAsumptions])
    return (
        <>
        <div className="">
            <div className="row justify-content-between">
                <div className="col-xl-9"></div>
                <div className="mb-4 col-xl-3 float-right">
                    <label>Update Your plug <span className="ml-2 text-danger text-14">*</span></label>
                    <FontAwesomeIcon icon={faQuestionCircle} className="float-right" data-toggle="tooltip" data-placement="bottom"  title="Describtion in tooltip"/>
                    <select className="form-control" onChange={(e)=>{changePlug(e.target.value)}} value={forcastingPlug}>
                        <option value="">Select </option>
                        <option value={'STD'}>Short Term Debt</option>
                        <option value={'STD-LTD'}>Short term Debt and Long Term Debt</option>
                        <option value={'STD-LTD-EQ'}>Short term Debt and long Term </option>
                    </select>
                </div>
            </div>
            <div className="card mb-45" style={{background:"var(--tp-common-gray)", padding:10}}>
                <div className="row">
                <div className="col-xl-4">
                    <h6 className="text-center">On Income</h6>
                    {assumptions.map((ass)=>(
                        <>
                        {ass.category==="Income Statement"&&
                        <div className="row no-gutters">
                            <div className="col-4"  style={{paddingTop:20}}>{t(`ASSUMPTIONS.${ass.title}`)}</div>
                            <div className="col-3 input-group">
                                <input className="form-control" style={{height:40}} type="number" 
                                    // value={percentageFormater(actualAssumptions[ass.title]??"0")} 
                                    placeholder={(actualAssumptions[ass.title]??"0")} 
                                    onChange={(e)=>{setAssumption(e.target.value,ass.title)}}/>
                                <div class="input-group-prepend" style={{height:40}}><span class="input-group-text">%</span></div>
                            </div>
                            <div className="col-1">
                                <button className="btn btn-default" type="button" data-toggle="tooltip" data-placement="bottom"  title={ass.description}  style={{height:40}}>
                                    <FontAwesomeIcon icon={faQuestionCircle}/>
                                </button>
                            </div>
                            {/* <div className="col-1"><button className="button" ><i class="nav-icon i-question-2"></i></button></div> */}
                            {/* <div className="col-4" style={{paddingTop:20}}>{ass.description.substring(0,25)} {ass.description.length>25&&<span>...</span>}</div> */}
                        </div>
                        }
                        </>
                    ))}
                </div>
                <div className="col-xl-4">
                    <h6 className="text-center">On Assets</h6>
                    {assumptions.map((ass)=>(
                        <>
                        {ass.category==="Assets"&&
                        <div className="row no-gutters">
                            <div className="col-4" style={{paddingTop:20}}>{t(`ASSUMPTIONS.${ass.title}`)}</div>
                            <div className="col-3 input-group">
                                <input className="form-control" style={{height:40}} type="number" 
                                    // value={percentageFormater(actualAssumptions[ass.title]??"0")} 
                                    placeholder={percentageFormater(actualAssumptions[ass.title]??"0")}
                                    onChange={(e)=>{setAssumption(e.target.value,ass.title)}}/>
                                <div class="input-group-prepend" style={{height:40}}><span class="input-group-text">%</span></div>
                            </div>
                            <div className="col-1">
                                <button className="btn btn-default" type="button" data-toggle="tooltip" data-placement="bottom"  title={ass.description}  style={{height:40}}>
                                    <FontAwesomeIcon icon={faQuestionCircle}/>
                                </button>
                            </div>
                            {/* <div className="col-4" style={{paddingTop:20}}>{ass.description.substring(0,25)} {ass.description.length>25&&<span>...</span>}</div> */}
                        </div>
                        }
                        </>
                    ))}
                </div>
                <div className="col-xl-4">
                    <h6 className="text-center">On Equity</h6>
                    {assumptions.map((ass)=>(
                        <>
                        {ass.category==="Assets"&&
                        <div className="row no-gutters">
                            <div className="col-4" style={{paddingTop:20}}>{t(`ASSUMPTIONS.${ass.title}`)}</div>
                            <div className="col-3 input-group">
                                <input className="form-control" style={{height:40}} type="number" 
                                placeholder={percentageFormater(actualAssumptions[ass.title]??"0")}
                                onChange={(e)=>{setAssumption(e.target.value,ass.title)}}/>
                                <div class="input-group-prepend" style={{height:40}}><span class="input-group-text">%</span></div>
                            </div>
                            <div className="col-1">
                                <button className="btn btn-default" type="button" data-toggle="tooltip" data-placement="bottom"  title={ass.description}  style={{height:40}}>
                                    <FontAwesomeIcon icon={faQuestionCircle}/>
                                </button>
                            </div>
                            {/* <div className="col-4" style={{paddingTop:20}}>{ass.description.substring(0,25)} {ass.description.length>25&&<span>...</span>}</div> */}
                        </div>
                        }
                        </>
                    ))}
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default AsumptionTable;