import {BarChart} from "echarts/charts"; 
import {GridComponent, TitleComponent, TooltipComponent} from "echarts/components"; 
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';



const MBarChart = ({data_axis, data_seriels, t}) => {
    echarts.use([TitleComponent, BarChart, TooltipComponent, GridComponent, CanvasRenderer])
    const option = {
        color: ["#1D2461", "#00ACCC", "#1D1460", "#8877bd", "#9181bd", "#6957af",],
        xAxis: {
          type: 'category',
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: data_axis?.map((elt)=>`${t(elt[0])} - ${elt[1]}`)
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: data_seriels,
            type: 'bar'
          }
        ]
      };
    return <ReactEChartsCore  echarts={echarts} option={option}/>
}

export default MBarChart;