import { Link, useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { API, AUTHAPI } from "../../../services/axios";
import { URLS } from "../../../services/urls";
import { setUserDetails } from "../../../components/utils/functions";
import { loginSuccess } from "../../../store/slices/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faL } from '@fortawesome/free-solid-svg-icons';
import LoaderPage from "../../../components/loader";
import { CircleSpinnerOverlay, DotLoaderOverlay, DotLoader } from 'react-spinner-overlay'
import LanguageSwitcher from "../../../components/language";
import { CHECK_MARK_SVG } from "../../../components/utils/consts";
import OTPForm from "./otp-page";



const LoginPage = () => {
    const {t} = useTranslation();
    const { emailKey } = useParams();
    const [loading, setLoading] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const [showPassword, setShowPassword] = useState(false);
    const [verified, setVerified] = useState(0);
    const [otpId, setOtpId] = useState("test");
    const [otpCode, setOTPCode] = useState();
    const [otpValid, setOTPValid] = useState(true);
    const [user,setUser] = useState(null);
    const [verify, setVerify] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({});
    useEffect(()=>{
        if(emailKey){
            // console.log(`Account validation...,${emailKey}`)
            API.post(URLS.AUTHS.VERIFY.email,{key:emailKey})
            .then((resp)=>{
                // toast.success("Account Successfully Verified")
                setVerified(1)
            }).catch((error)=>{
                if (error?.response?.status) {
                    if(verified===0){
                        toast.warning("Your Account has already been verified");
                        setVerified(2)
                    }
                } else {
                    if(verified===0){
                        toast.error(error?.response?.data?.detail ?? "Account Validation Error");
                        setVerified(3)
                    }
                }
            })
        }else{
            // console.log("No email verif")
        }
    })


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onResendOTP = () => {
        // console.log(otpId);
        setLoading(true)
        AUTHAPI.post(URLS.AUTHS.LOGIN_OTP_RESEND, otpId).then((resp)=>{
            toast.success(t("textResendSuccess"))
            setLoading(false)
        }).catch((err)=>{
            toast.error(t("textResendFailed"))
            setLoading(false);
        })
    }
    const onVerifyOTP = () => {
        setLoading(true)
        setOTPValid(true)
        let regex = /^[a-zA-Z]+$/;
        if(regex.test(otpCode) || otpCode?.length!==6){
            setOTPValid(false);
            setLoading(false);
            return;
        }
        // console.log(otpCode)
        let data = user;
        data['otp']=otpCode
        // console.log(data)
        AUTHAPI.post(URLS.AUTHS.LOGIN_VERIFY, data)
          .then((resp) => {
            const redirect = window.localStorage.getItem("redirect")?.includes("account/")?"/":window.localStorage.getItem("redirect");
            setLoading(false);
            let usrData = resp.data;
            usrData.user.id = usrData.user.pk;
            setUserDetails(usrData);
            dispatch(loginSuccess(usrData));
            // console.log("Sucess login")
            navigate(searchParams.get("redirect") ?? redirect ?? "/");
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.detail ?? t("textOTPVFailed"));
          });
    }
    const onSubmit = (data) => {
        // console.log(data)
        setLoading(true);
        AUTHAPI.post(URLS.AUTHS.LOGIN, data)
          .then((resp) => {
            // console.log(resp.data)
            setVerify(true)
            setUser(data);
            setLoading(false);
            setOtpId(resp.data);
            toast.success(t("textLogInSuccess"))
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.detail ?? t("textLoginFailed"));
          });
      };
    return ( 
        <section className={loading?"tp-testimonial-area tp-testimonial-bg-color p-relative pt-80 pb-50 disabled":"tp-testimonial-area tp-testimonial-bg-color p-relative pt-80 pb-50"} data-bg-color="#F6F6F9">
            <div className="tp-testimonial-bg">
               <img src="/assets/img/testimonial/bg-shape.png" alt=""/>
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="tp-testimonial-title">
                        <span className="tp-section-title-pre"><img
                                    alt="B.K. Research"
                                    src="/assets/img/logo/logo_small.png"
                                    className="app-logo"
                                /></span>
                        <h3 className="tp-section-title" style={{background:"linear-gradient(90deg, #004D6E 0%, #00ACCC 100%)", WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>{t("textWelcomeBack")}!</h3>
                     </div>
                  </div>
                    {verified===1 && <div className="">
                        <div style={{width:"48%", minWidth:"450px"}}>
                            <div className="tp-faq-tab-content tp-accordion wow fadeInDown bg-alert" style={{paddingTop:0}}>
                                <div className="row">
                                    <div className="col-3" style={{display: "flex",alignItems: "center",justifyContent: "center", height:"100%"}}>
                                        <div className="my-box"><img src={CHECK_MARK_SVG} className="my-img" alt="enterprise"/></div>
                                    </div>
                                    <div className="col-8 text-center">
                                    <label className="mt-4">{t("textAccVerSuccess")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <>
                    {verify?(<OTPForm t={t} loading={loading} isValidOTP={otpValid} setOTP={setOTPCode} submit={onVerifyOTP} resend={onResendOTP}/>):(<div className="swiper-wrapper mb-30 mt-20">
                        <div className="tp-faq-tab-content tp-accordion wow fadeInRight">
                            <div className="mb-20" style={{minWidth:"450px"}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="">
                                                <div className="mb-3 inline">
                                                    <span>{t("textNoAccount")}? </span>
                                                    <Link to="/account/sign-up" style={{fontWeight:"bold", textDecoration:"underline"}} className="text-primary">{t("textSingHere")}</Link>
                                                    <span><br/>{t("textMin")}</span>
                                                </div>
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="form-group">
                                                        <label htmlFor="email">{t("textEmail")}</label>
                                                        <input className="form-control" id="email" type="email"
                                                            {...register("email", {
                                                            required: "Email required",
                                                            validate: {
                                                                maxLength: (v) =>
                                                                v.length <= 50 ||
                                                                "Maximun of 50 caracters",
                                                                matchPattern: (v) =>
                                                                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                                                    v
                                                                ) ||
                                                                "invalide email",
                                                            },
                                                            })}
                                                            placeholder="stevenjob@gmail.com"
                                                        />
                                                        <span className="text-primary">
                                                            {errors.email?.message && (
                                                            <small>{errors.email.message}</small>
                                                            )}
                                                    </span>
                                                    </div>
                                                        <label htmlFor="password">{t("textPassword")}</label>
                                                    <div className="input-group mb-3">
                                                        <input className="form-control " id="password"
                                                            type={showPassword ? 'text' : 'password'}
                                                            {...register("password")}
                                                            required=""
                                                            placeholder="************"
                                                            style={{minHeight:"55px"}}
                                                        />
                                                        <div className="input-group-append" onClick={togglePasswordVisibility} style={{cursor:"pointer",minHeight:"55px"}}>
                                                            <span  className="eye-icon input-group-text" id="basic-addon2">
                                                            <FontAwesomeIcon icon={showPassword ? faEye: faEyeSlash } /></span>
                                                        </div>
                                                    </div>
                                                    <button className="btn btn-primary btn-block mt-2" disabled={loading}>
                                                        {t("textSignIn")}</button>
                                                </form>
                                                <div className="mt-3 inline"style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", width:"inherit"}}>
                                                    <span>{t("textPwForgot")}?</span>
                                                    <Link to="/account/reset-password" style={{fontWeight:"bold", textDecoration:"underline"}} className="text-primary">{t("textRecover")}</Link>
                                                </div>
                                            <div className="mt-3" style={{display: "flex", flexWrap: "wrap", justifyContent: "center", width:"inherit"}}>
                                                <LanguageSwitcher/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}</>
            </div>
            </div>
         </section>
        
    );
}
export default LoginPage;