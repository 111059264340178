import Select from "react-select";
import { inLineSelectStyles} from "../../../components/utils/reactSelect";
const CompanyDetailsForm = ({setHeadOffice, countries, setLegalForm, legalForm, legalStatus, loading, taxRate, setTaxRate, 
    vatRate, setVatRate, firstYear, setFirstYear,setControle, setVisa, t}) => {
    return (
        <div className="card text-left o-hidden">
            <div className="card-header d-flex align-items-center border-0 my-header">
                <h3 className="w-100 float-left card-title m-0 text-white">{t("OTHERCRRINFO.COMPANYDETAILS")}</h3>
            </div>
            <div className="table-responsive table-strip">
                    <table className="table table-hover table-bordered">
                        <tbody>
                            <tr>
                                <td colSpan={2} >{t("OTHERCRRINFO.HEADQ")}</td> 
                                <td style={{padding:0, marginTop:0}}>
                                    <Select 
                                        className="basic-single" 
                                        styles={inLineSelectStyles}
                                        menuPortalTarget={document.body}
                                        onChange={(e)=>{setHeadOffice(e.id)}}
                                        isSearchable
                                        // isDisabled={loading}
                                        inputId="headOffice"
                                        // value={headOffice}
                                        options={countries.map((count)=>{
                                            return {label:t(`COUNTRY.${count.code2}`), id:count.id}
                                            // <option key={count.id} value={count.id}>{count.name}</option>
                                        })}
                                        isLoading={loading}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{t("OTHERCRRINFO.LEGALFORM")}</td>
                                {!legalForm? 
                                (<td style={{padding:0, marginTop:0}}>
                                    <Select className="basic-single" 
                                    styles={inLineSelectStyles} 
                                    menuPortalTarget={document.body}
                                    onChange={(e)=>{setLegalForm(e)}}
                                    options={legalStatus.map((lgs)=>{
                                        return {label:lgs.sigle, value:lgs.id}
                                    })}
                                    />
                                </td>):(<td style={{alignItems:"right"}}>{legalForm}</td>)}
                            </tr>
                            <tr>
                                <td colSpan={2}>{t("OTHERCRRINFO.TAX")}</td>
                                <td style={{padding:0, marginTop:0}}>
                                    <input type="number" placeholder={taxRate} style={{borderStyle:"none", marginBottom:0, width:"100%", textAlign:"right"}} onChange={(e)=>{setTaxRate(e.target.value)}}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{t("OTHERCRRINFO.VTA")}</td>
                                <td style={{padding:0, marginTop:0}}>
                                    <input type="number" placeholder={vatRate} style={{borderStyle:"none", marginBottom:0, width:"100%", textAlign:"right"}} onChange={(e)=>{setVatRate(e.target.value)}}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{t("OTHERCRRINFO.VISA")}</td>
                                <td style={{padding:0, marginTop:0}}>
                                    <Select className="basic-single" 
                                        styles={inLineSelectStyles} 
                                        menuPortalTarget={document.body}
                                        onChange={(e)=>{setVisa(e)}}
                                        options={[
                                            {label:t("textYes"), value:"oui"}, {label:t("textNo"), value:"non"}]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{t("OTHERCRRINFO.UNDERCONTROLE")}</td>
                                <td style={{padding:0, marginTop:0}}>
                                <Select className="basic-single" 
                                        styles={inLineSelectStyles} 
                                        menuPortalTarget={document.body}
                                        onChange={(e)=>{setControle(e)}}
                                        options={[
                                            {label:t("textYes"), value:"oui"}, {label:t("textNo"), value:"non"}]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{t("OTHERCRRINFO.YEARCREATE")}</td>
                                <td>
                                    <span style={{width:"inherit", textAlign:"right"}}>{firstYear}</span>
                                    {/* {firstYear?(
                                    <span style={{width:"inherit", textAlign:"right"}}>{firstYear}</span>):
                                    (<input style={{borderStyle:"none", marginBottom:0, width:"100%", textAlign:"right"}}  type="number" placeholder="1990" onChange={(e)=>{setFirstYear(e.target.value)}}/>)} */}
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </div>
        </div>
    )
}
export default CompanyDetailsForm;