import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"
import i18n from "../../translations/translations";
import { changeLanguage } from "../../store/slices/language";

const LanguageSwitcher = () =>{
    const dispatch = useDispatch();
    const lang = window.localStorage.getItem("lang");
    const handleChangeLanguage = (lng) => {
        window.localStorage.setItem("lang",lng)
        dispatch(changeLanguage(lng === "fr-FR" ? lng : "en-US"))
        i18n.changeLanguage(lng === "fr-FR" ? lng : "en-US");
    }

    return (
        <div className="dropdown">
            <i className={lang==="fr-FR"?"fi fi-fr mr-2":"fi fi-gb mr-2"}id="languageDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
            {/* <i className="i-Globe text-muted header-icon" id="languageDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> */}
            <div className="dropdown-menu" aria-labelledby="languageDropdown">
                <Link className="dropdown-item" to="#" onClick={() => handleChangeLanguage("fr-FR")}><span className="fi fi-fr mr-2"></span> Francais</Link> 
                <Link className="dropdown-item" to="#" onClick={() => handleChangeLanguage("en-US")}><span className="fi fi-gb mr-2"></span> English</Link>
            </div>
        </div>
    )
}
export default LanguageSwitcher;