import { Link, NavLink, useLocation } from "react-router-dom";
import { isSubAccount, isBank, isConsultingFirm, isIndividual, isMicrofinance, isOtherFinancialFirm, isOtherFirm, isCompany} from "../utils/functions";


const NavBar = (props) => {
    const { pathname } = useLocation();
    return (
        <div className="nav">
            <div className="horizontal-bar-wrap">
                <div className="header-topnav">
                    <div className="container-fluid" 
                    style={{background: "var(--tp-common-gray)",borderTop:"solid 2px", borderTopColor:"linear-gradient(90deg, #1D3461 0%, #00ACCC 100%)"}}
                    >
                        <div className="topnav rtl-ps-none" id="" data-perfect-scrollbar="" data-suppress-scroll-x="true">
                            <ul className="menu float-left">
                                <li>
                                    <div className={`${pathname === "/" ? 'active' : ''}`}> 
                                        <div>
                                            <label className="toggle" htmlFor="drop-2">Dashboard</label><Link to="/"><i className="nav-icon mr-2 i-Home-Window"></i> Dashboard</Link>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className={` ${pathname.includes("/sme-crr") ? 'active' : ''}`}>
                                        <div>
                                            <label className="toggle" htmlFor="drop-2">CRR-SME</label><Link to="sme-crr/"><i className="nav-icon mr-2 i-David-Star"></i> CR<sup>2</sup>-SME</Link>
                                            <input id="drop-2" type="checkbox" />
                                            <ul>
                                                <li><Link to="sme-crr/new"><i className="nav-icon mr-2 i-Add"></i><span className="item-name">New</span></Link></li>
                                                <li><Link to="sme-crr/list"><i className="nav-icon mr-2 i-Speach-Bubble-2"></i><span className="item-name">List</span></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className={` ${pathname.includes("/f-forcasting") ? 'active' : ''}`}>
                                        <div>
                                            <label className="toggle" htmlFor="drop-2">SME-BA</label><Link to="f-forcasting"><i className="nav-icon i-Money-Bag mr-2"></i>SME-BA</Link>
                                            <input id="drop-2" type="checkbox" />
                                            <ul>
                                                <li><Link to="f-forcasting/new"><i className="nav-icon mr-2 i-Add"></i><span className="item-name">New</span></Link></li>
                                                <li><Link to="f-forcasting/list"><i className="nav-icon mr-2 i-Home-2"></i><span className="item-name">List</span></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className={` ${pathname.includes("/capital-budgetting") ? 'active' : ''}`}>
                                            <label className="toggle" htmlFor="drop-2">SME-CB</label><Link to="#"><i className="nav-icon mr-2 i-Computer-Secure"></i>SME-CB</Link>
                                            <input id="drop-2" type="checkbox" />
                                            <ul>
                                                <li className="disabled"><Link to="#"><i className="nav-icon mr-2 i-Home-2"></i><span className="item-name">Home</span></Link></li>
                                                <li className="disabled"><Link to="#"><i className="nav-icon mr-2 i-Add"></i><span className="item-name">New</span></Link></li>
                                                <li className="disabled"><Link to="#"><i className="nav-icon mr-2 i-Speach-Bubble-3"></i><span className="item-name">Pending</span></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                
                                {
                                    isCompany() && (
                                        <li>
                                            <div className={` ${pathname.includes("members") ? 'active' : ''}`}>
                                                <div>
                                                    <label className="toggle" htmlFor="drop-2">Users</label>
                                                    <Link to="/group-members"><i className="nav-icon mr-2 i-Checked-User"></i> Users</Link>
                                                    {/* <input id="drop-2" type="checkbox" />
                                                    <ul>
                                                        <li><Link to="group-members/new"><i className="nav-icon mr-2 i-Add"></i><span className="item-name">New</span></Link></li>
                                                        <li><Link to="group-members/list"><i className="nav-icon mr-2 i-Speach-Bubble-2"></i><span className="item-name">List</span></Link></li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }
                                <li>
                                    <div className={` ${pathname.includes("profile-settings") ? 'active' : ''}`}>
                                        <div>
                                            <label className="toggle" htmlFor="drop-2">Settings</label><Link to="/user-profile-settings"><i className="nav-icon mr-2 i-Gear"></i> Settings</Link>
                                        </div>
                                    </div>
                                </li>
                                {
                                    (isCompany() || isIndividual()) && (<li>
                                        <div className={` ${pathname.includes("billing") ? 'active' : ''}`}>
                                            <div>
                                                <label className="toggle" htmlFor="drop-2">Billing</label><Link to="/billing"><i className="nav-icon mr-2 i-Add-File"></i> Billing</Link>
                                            </div>
                                        </div>
                                    </li>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div> 
        </div>

    )
}
export default NavBar;