import Select from "react-select";
import { inLineSelectStyles} from "../../../components/utils/reactSelect";
const QualitativeDataForm = ({qualitativeData, getQualitativeDate, t}) => {
    return (
        <div className="card text-left o-hidden">
            <div className="card-header d-flex align-items-center border-0 my-header">
                <h3 className="w-100 float-left card-title m-0 text-white"> {t("FIN_STATEMENT.QDATA")} ({t("FIN_STATEMENT.QDATADESC")})</h3>
            </div>
            <div className="table-responsive table-strip">
                <table className="table table-hover table-bordered">
                    <thead style={{backgroundColor:"var(--tp-common-gray)"}}>
                        <tr>
                            <th>Question</th>
                            <th>Modalite</th>
                        </tr>
                    </thead>
                    <tbody>
                        {qualitativeData.map((value)=>(
                            <tr key={value.variable}>
                                <td>{t(`QUALITATIVE_DATA.${value.variable}`)}</td>
                            {(value.variable==="Var_1" || value.variable==="Var_2" || value.variable==="Var_3")?
                                <td style={{padding:"0", margin:"0", height:"fit-content"}}>
                                    <Select className="basic-single" 
                                    style={{borderStyle:"none"}}
                                    styles={inLineSelectStyles}
                                    menuPortalTarget={document.body}
                                    onChange={(e)=>{getQualitativeDate(value.variable, e.value)}}
                                    options={[{label:t("textYes"), value:1},{label:t("textNo"), value:2}]}
                                    />
                                </td>:
                                <td style={{padding:0, margin:0, height:"fit-content"}}>
                                <Select className="basic-single"
                                    menuPortalTarget={document.body}
                                    style={{borderStyle:"none", marginBottom:0}}
                                    styles={inLineSelectStyles}
                                    onChange={(e)=>{getQualitativeDate(value.variable, e.value)}}
                                    options={[{label:t("textWeak"), value:3},{label:t("textHalf"), value:2},{label:t("textHight"), value:1}]}
                                /></td>
                            }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default QualitativeDataForm;