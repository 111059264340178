import { MASTERCARD_LOGO, VERVE_LOGO, VISA_LOGO } from "../../components/utils/consts";

const CardForm = () => {
    let year = new Date().getFullYear()
    return (
        <div className="card">
            <div className="card-body" style={{background:"#ffff"}}>
                <div className="ul-widget-list__payment-method mb-3"><img src={MASTERCARD_LOGO} style={{width: "50px"}} alt="" srcSet="" /><img src={VISA_LOGO} style={{width: "50px"}} alt="" srcSet="" /><img src={VERVE_LOGO} style={{width: "50px"}} alt="" srcSet="" /></div>
                <form action="">
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label className="ul-form__label" htmlFor="inputEmail4">Full Name:</label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend"><span className="input-group-text" id="basic-addon1"><i className="i-Male-21 text-18"></i></span></div>
                                <input className="form-control" type="text" placeholder="" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label className="ul-form__label" htmlFor="inputEmail4">Card Number:</label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend"><span className="input-group-text" id="basic-addon1"><i className="i-Credit-Card-2 text-18"></i></span></div>
                                <input className="form-control" type="text" placeholder="" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                        </div>
                    </div>
                    <div className="form-row row">
                        <div className="form-group col-4">
                            <label className="ul-form__label" htmlFor="inputEmail4">Expiration Year:</label>
                            <select className="form-control" style={{width:"100%"}} >
                                <option>Year</option>
                                <option>{year}</option>
                                <option>{year+1}</option>
                                <option>{year+2}</option>
                                <option>{year+3}</option>
                                <option>{year+4}</option>
                                <option>{year+5}</option>
                            </select>
                        </div>
                        <div className="form-group col-4">
                            <label className="ul-form__label" htmlFor="inputEmail4">Expiration Month:</label>
                            <div className="form-inline">
                                <select className="form-control" style={{width:"100%"}}>
                                    <option>Month</option>
                                    <option>Janiary</option>
                                    <option>February</option>
                                    <option>February</option>
                                    <option>March</option>
                                    <option>April</option>
                                    <option>May</option>
                                    <option>June</option>
                                    <option>July</option>
                                    <option>September</option>
                                    <option>October</option>
                                    <option>November</option>
                                    <option>December</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group col-4">
                            <label className="ul-form__label" htmlFor="inputEmail4">CVV:</label>
                            <div className="input-group mb-3">
                                <input className="form-control" type="text" placeholder="234" aria-label="cvv" aria-describedby="basic-addon1" />
                            </div>
                        </div>
                    </div>
                    <button className="btn my-header btn-block m-1" type="button">Confirm</button>
                </form>
            </div>
        </div>
    )
}
export default CardForm;