import { getUserDetails, setUserDetails } from '../components/utils/functions';
import { API } from './axios';
import { URLS } from './urls';


export const refreshToken = async () => {
    let token = getUserDetails();
    console.log("Token refreshing...........")
    const newToken = await API.post(URLS.AUTHS.REFRESH, {
        refresh: token?.refresh_token
    }).then((resp) => {
            console.log("Token successfully refreshed");
            token.access_token = resp.data?.access;
            // console.log(resp.data.refresh)
            setUserDetails(token);
            return resp.data?.access;
        })
        .catch((error) => {
            const redirect = window.location.pathname.includes('accounts')?"/":window.location.pathname
            console.log("\n\n Unable to refresh token \n\n")
            window.localStorage.setItem('redirect', redirect);
            window.localStorage.removeItem('user-details');
            window.location.pathname = '/account/login';
        });
    return newToken;
};
