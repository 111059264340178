import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import { CRRAPI, USERAPI } from "../../services/axios";
import { URLS } from "../../services/urls";
import LoaderPage from "../../components/loader";
import BreadCrumb from "../../components/breadcrumb";
import { DEFAULT_LOGO, DEFAULT_PROFILE } from "../../components/utils/consts";
import BarChart from "../../components/chart/BarChart";
import { useSelector } from "react-redux";
import { formatLinkString } from "../../components/utils/functions";
const fetcher = (url) => USERAPI.get(url).then(res => res.data);
const fetcher2 = (url) => CRRAPI.get(url).then(res => res.data);

const MemberDetails = () =>{
    const { key } = useParams();
    const {data, isLoading} = useSWR(URLS.USERS.details(key), fetcher);
    const {data: ratings, isLoading2} = useSWR(URLS.CRR.list(1), fetcher2);
    const {
        auth,
      } = useSelector((state) => state.auth);
    return (
        <>
            {(isLoading || isLoading2)?(<LoaderPage/>):(
                <>
                    <BreadCrumb title1="Users/Members" title2="Details" title3={data.first_name} link="/group-members/"/>
                    <div className="row">
                        <div className="col-xl-6">    
                            <div className="card user-profile o-hidden mb-4">
                                <div className="header-cover" style={{backgroundImage: `url('${auth?.user.picture? formatLinkString(auth.user.picture): DEFAULT_LOGO}')`, backgroundPosition:"center", backgroundSize:"cover", backgroundRepeat:"no-repeat", bottom:0, top:0,}}></div>
                                <div className="user-info"><img className="profile-picture avatar-lg mb-2" src={data?.picture?? DEFAULT_PROFILE} alt={data?.first_name} />
                                    <p className="m-0 text-24">{data?.first_name} {data?.last_name}</p>
                                    <p className="text-muted m-0">{data?.profession}</p>
                                </div>
                                <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6 col-6">
                                                <div className="mb-4">
                                                    <p className="text-primary mb-1"><i className="i-Email text-16 mr-2"></i>email</p><span>{data?.email}</span>
                                                </div>
                                                <div className="mb-4">
                                                    <p className="text-primary mb-1"><i className="i-Telephone text-16 mr-2"></i>Phone Number</p><span>{data?.phone_number}</span>
                                                </div>
                                                <div className="mb-4">
                                                    <p className="text-primary mb-1"><i className="i-Calendar text-16 mr-2"></i>Date Joined</p><span>{(new Date(data?.date_joined)).toLocaleString()}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-6">
                                                <div className="mb-4">
                                                    <p className="text-primary mb-1"><i className="i-Email text-16 mr-2"></i>Secondary Email</p><span>{data?.secondary_email}</span>
                                                </div>
                                                <div className="mb-4">
                                                    <p className="text-primary mb-1"><i className="i-Telephone-2 text-16 mr-2"></i>mobile</p><span>{data?.mobile?? "Not set"}</span>
                                                </div>
                                                <div className="mb-4">
                                                    <p className="text-primary mb-1"><i className="i-Calendar text-16 mr-2"></i>Last Login</p><span>{(new Date(data?.last_login)).toLocaleString()}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-6">
                                                <div className="mb-4">
                                                    <p className="text-primary mb-1"><i className="i-Edit-Map text-16 mr-2"></i>Address</p><span>{data?.address?? "not Set"}</span>
                                                </div>
                                                <div className="mb-4">
                                                    <p className="text-primary mb-1"><i className="i-MaleFemale text-16 mr-2"></i>Gender</p><span>{data?.gender}</span>
                                                </div>
                                                <div className="mb-4">
                                                    <p className="text-primary mb-1"><i className="i-MaleFemale text-16 mr-2"></i>Language</p><span>{data?.language==="EN"?"English":"French"}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-6">
                                                <div className="mb-4">
                                                    <p className="text-primary mb-1"><i className="i-Edit-Map text-16 mr-2"></i>Zip Code</p><span>{data?.zip_code?? "not Set"}</span>
                                                </div>
                                                <div className="mb-4">
                                                    <p className="text-primary mb-1"><i className="i-Globe text-16 mr-2"></i>Lives in</p><span>{data?.address?? "not Set"}</span>
                                                </div>
                                                <div className="mb-4">
                                                    <p className="text-primary mb-1"><i className="i-Edit text-16 mr-2"></i>Status</p><span className={data.is_active?"badge badge-pill badge-success":"badge badge-pill badge-danger"}>{data?.is_active?"Active":"Not Active"}</span>
                                                </div>
                                            </div>
                                            <div className="separator-breadcrumb border-top"></div>
                                            <div className="col-6">
                                                <button className="btn btn-danger" style={{float:"left"}}>
                                                    Delete Account
                                                </button>
                                                <label style={{fontWeight:200}} className="mt-2">This account will be deleted. You cannot cancel this process</label>
                                            </div>
                                            <div className="col-6">
                                                <button className="btn btn-warning" style={{float:"right"}}>
                                                    Disable Account
                                                </button>
                                                <label style={{fontWeight:200, float:"right"}}  className="mt-2">The account will be temporarily inactive. You can rever it later</label>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12">
                                <div className="card mb-4 o-hidden">
                                    <div className="card-body ul-card__widget-chart">
                                        <div className="card-header d-flex align-items-center border-0 my-header">
                                            <h5 className="heading text-white">Users' Activities</h5>
                                        </div>
                                        <BarChart/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card mb-4 o-hidden">
                                    <div className="card-body ul-card__widget-chart">
                                        <div className="card-header d-flex align-items-center border-0 my-header">
                                            <h5 className="heading text-white">Trasaction Break down</h5>
                                        </div>
                                        <BarChart/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card mb-4 o-hidden">
                                    <div className="card-body ul-card__widget-chart">
                                        <div className="card-header d-flex align-items-center border-0 my-header">
                                            <h5 className="heading text-white">Latest Transactions</h5>
                                        </div>
                                        <div className="table-responsive">
                                                <table className="table text-center" id="user_table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Score</th>
                                                            <th scope="col">Identifier</th>
                                                            <th scope="col">Indicator</th>
                                                            <th scope="col">Year</th>
                                                            <th scope="col">Date</th>
                                                            <th scope="col">Details</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {ratings?.results?.map((rating)=>{if(rating.owner?.id===key){
                                                            return <tr key={rating.id}>
                                                                <td><span className="badge badge-round-info">{rating.grade}</span></td>
                                                                <th scope="row">{rating.identifier}</th>
                                                                <td>{rating.indicator}</td>
                                                                <td>{rating.year}</td>
                                                                <td>{(new Date(rating.date)).toLocaleString()}</td>
                                                                <td>
                                                                    <Link className="text-success mr-2" to={`/sme-crr/details/${rating.id}`}><i className="nav-icon i-Eye font-weight-bold"></i></Link>
                                                                </td>
                                                            </tr>}}
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default MemberDetails;