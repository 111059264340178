export const fr = {
    textExperience: "Expériences",
    textHappyClient: "Clients Satisfaits",
    textContactQ: "<span>Contactez nous</span><br/> <span>pour toute question</span>",
    textContactQDesc: `Si vous avez des questions ou des commentaires, n'hésitez pas à nous contacter. 
    Nous sommes toujours heureux de vous entendre et de vous aider à répondre à vos besoins. 
    Vous pouvez nous appeler au +237 696 888 241, ou nous envoyer un courriel à 
    <a href="mailto:contact@bkresearchandanalytics.com">contact@bkresearchandanalytics.com</a> .
    Nous espérons avoir bientôt de vos nouvelles.`,
    textWelcomeBack: "Bienvenue",
    textHome: "Acceuil",
    textAboutUs: "A Propos de Nous",
    textService: "Service",
    textTeam: "Equipe",
    textBlog: "Blog",
    textContact: "Contact",
    textLngFrench: "Francais",
    textLngEnglish: "Anglais",
    textBilling: "Facturation",
    textSearch: "Chercher",
    textAccSettings: "Parametres du profil",
    textBillHistory: "Historique des Facturations",
    logOut: "Deconnexion",
    textPwForgot: "Mot de passe oublié",
    textRecover: "Réinitalise ici",
    textSignIn: "Se Connecter",
    textPassword: "Mot de passe",
    textEmail: "Adresse Email",
    textEmailRep: "Email Requis",
    textSendLink: "Recevoir le lien",
    textPWRecovered: "Mot de passe récupéré avec succès",
    textEmailConfirmErr: "Erreur de verification du l'adresse email",
    textEmailSentMsg: "Mail envoyé: verifiez votre adresse",
    textEmailNotFound: "Email Introuvqble",
    textNoAccount: "Pas de compte",
    textHaveAccount: "Vous avez un compte",
    textSingHere: "Creer Ici",
    textMin: "Juste Une Minute",
    textLogIn: "Se connecter Ici",
    textLogInSuccess: "Vous avez reçu un OTP",
    textTypeNewPW: "Nouveau mot de passe",
    textReTypePW: "Ressaisir le mot de passe",
    textAccVerSuccess: "Votre a été validé avec succèss. Lancez vous",
    textSelectAccType: "Selectionnez le type de compte",
    textAccType: "Type de compte",
    textAccDet: "Details du compte",
    textStarParams: "Les parametres suivis d'une etoile",
    textMandatory: "Sont Obligatoire",
    textSelectComp: "Select le Type Compte",
    textFinFirm: "Institutions Financière",
    textOtherFirm: "Autre Entreprise",
    textIndividual: "Personnel",
    textReset: "Réinitialiser",
    textFinInd: "Indicateurs Financiers",
    textForcastingDataYear: 'Prevision Financiere sur ',
    textYear: "Années",
    textYear_: "Années",
    textNext: "Suivant",
    textGetStarted: "Commencez",
    textPeriod: "Periode",
    textEndYear: "Fin de l'année",
    textFNextYear: "Prévison pour les prochaines",
    textCancel: "Annuler",
    textProcedd: "Demarrer",
    textOthers: "Autres Informations",
    textWeak: "Faible",
    textHalf: "Moyen",
    textHight: "Elevé",
    textYes: "Oui",
    textCountry: "Pays",
    textCountrySelect: "Selectionnez votre pays",
    textAmount: "Montant",
    textNumYears: "Nombre d'années",
    textNumCountry: "Nombre de Pays",
    textNo: "Non",
    textRatingSuccess: "Notation Effectuée",
    textRatingReadRes:"Lisez attentivement le rapport qui vous est founis ici. Contactez nous en cas d'incomprehension",
    textCRRDetails: "Détails du Rating",
    txtDownloadRep: "Telecharger le Rapport",
    textNew: "Nouveau",
    textViewAll: "Tout lister",
    textView: "Plus",
    textFirstName: "Nom",
    textLastName: "Prénom",
    textFinInstType: "Type d'Institution financière",
    textCompName: "Nom de l'entreprise",
    textProfession: "Profession",
    textProEmail: "Adresse Email Professionnelle",
    textCity: "Ville",
    textBank: "Banque",
    textMicrofin: "Micro finance",
    textOthersEnt: "Autres",
    textConsFirms: "Cabinet de consultation",
    textPhonNum: "Numéro de Téléphone",
    textConfirmPW: "confirmez le mot de passe",
    textPWDesc: "Le mot de passe doit être une combinaison de caractères spéciaux, majuscules, minuscules et numériques.",
    textAccept: "Accepter nos",
    textTerm: "Termes",
    textAnd: "et",
    textPrivacyPolicy: "Politiques de confidentialité",
    textFirstName_: "Nom Requis",
    textFinInstType_: "Choisissez le Type de votre Entreprise",
    textCompName_: "Donnez le Nom de Votre entreprise",
    textProfession_: "Quelle est Votre Profession",
    textProEmail_: "Adresse Email Invalide",
    textCity_: "Choisir une Ville",
    textPhonNum_: "Numéro de Téléphone Invalide",
    textConfirmPW_: "Reentrez le mot de passe precedent",
    textAgreeTerms_: "Lisez et Acceptez nor termes et conditions pour continuer",
    textOTPMsg: `Veuillez saisir l'OTP envoyé à votre adresse électronique <br/> ou à votre numéro de téléphone.`,
    textOTP: "One Time Password",
    textOTP_: "Code OTP invalide",
    textNoOTP: "Vous n'avez pas recu votre OTP",
    textOTPVFailed: "Impossible de verifier cet OTP",
    textResend: "Renvoyer",
    textResendSuccess: "OTP Renvoyé",
    textResendFailed: "Impossible de renvoyer le code OTP",
    textLoginFailed: "Email ou mot de passe incorrect",
    textContinue: "Continuez",
    textInvOldPwError: "Votre mot de passe actuel est incorrect",
    textOldPwError: "Votre nouveau mot de passe est egal a l'ancien: Veillez donner un nouveau",
    textNoOldPwError: "Entrez votre ancien mot de passw",
    textNotEqualPw: "Mots de passes non equivalants",
    textPwUpdated:"Mot de passw mis a jour",
    MONTHS: {
        JAN:"Janvier",
        FEB: "Février",
        MAR: "Mars",
        APR: "Avril",
        MAY: "Mai",
        JUN: "Juin",
        JUL: "Juillet",
        AUG: "Aout",
        SEP: "Septe,bre",
        OCT: "Octobre",
        NOV: "Novembre",
        DEC: "Decembre"
    },
    OTHERCRRINFO: {
        "CRRBREAKDOWN": "Repartition des Score",
        "MONTHCRR": "Rating Mensuels",
        "MNEWCRR": "Nouveau",
        "LATECRR": "Notations Rescentes",
        "LASTCRR": "Dernière Notation",
        "FINEXPCOVRATIO":"Ratio de couvertures des charges financières et ratios de couverture des charges fixes",
        "OPWCRATIO": "Ratio de besoin en fond de roulement d'exploitation",
        "DERATIOB":"Ratio d'endettement fondés sur la valeur comptable des dettes",
        "PROFRATIO":"Ration de profitabilité",
        "CURRASS":"Actifs Circulants",
        "CURRLIAB": "Passifs a court terme",
        "CURRRATIO": "Ratio de liquidete Generale",
        "CASHEQ":"Disponibilite et valeurs mobilieres",
        "STLIAB": "Passifs a court Termes",
        "LIQRIATIO":"Ratio de liquidité Réduite",
        "REF":"Reférence",
        "CSCORE": "Score du Crédit",
        "DEFAULTPROB": "Probabilité de défaut",
        "BETA":"Beta",
        "RISKPREM": "Risk premium",
        "EQUITYCOST": "Coût des fonds propres",
        "DEBTCOST": "Coût de la dêtte",
        "CAPCOST": "Coût du capital",
        "STINRISK":"Risque d'insolvabilite a court termes",
        "ACTIVTYBREAKDOWN": "Chiffres d'affaire par Secteur d'activite",
        "COUNTRYBREAKDOWN": "Chiffres d'affaire par Pays",
        "OLEXPENSE":"Dépenses en Leasing",
        "RDEXPENSE":"Dépenses de Recherches et Développement",
        "ENTRYMETHOD": "Méthode d'entrée",
        "COMPANYDETAILS": "Caracteristiques de l'entreprise",
        "LEGALFORM": "Forme Juridique",
        "TAX": "Taxe",
        "VTA": "T.V.A",
        "YEARCREATE": "Année de Création",
        "UNDERCONTROLE": "Sous Control d'une Entitée",
        "VISA": "Visa de l'Expert",
        "AMORTPERIOD": "Période d'Ammortissement",
        "TYEAR":"Cette année",
        "HEADQ": "Head Quater",
        "DEBTDETAILS": "Détails de la Dette",
        "DEBTMATURITY": "Maturité de la Dette",
        "DEBTESTIMATION": "Approche d'estimation du coût de la dette",
        "DEBTBT": "Cout de la dette avant taxe",
        "CMPTYPE": "Type d'entreprise",
        "VALUE": "Valeur",
        "ActualRating": "Score Actuel",
        "DirectInput": "Entré ",
        "SyntheticRating": "Rating Synthetic"
    },

	FIN_STATEMENT:{
		"MODE_": "Mode étendu",
		"MODE": "Mode réduit",
		"VIEW": "Paysage",
		"VIEW_": "Portrait",
		"ASSETS": "Actif",
		"BALANCESHEET": "Bilan",
		"LIABILITIES": "Passif",
		"CASHFLOW": "Flux de trésorerie",
		"INCOME_STATEMENT": "Compte de Resultat",
		"LABEL": "Libelé",
		"GROSS": "Brut",
		"AMORT": "Amortissement/Depreciation",
		"FOOT_NOTE": "Notes Annexes",
        "QDATA": "Données Qualitatives",
        "QDATADESC": "A propos de l'entrpreneur",
        "UPLOAD": "Uploadez vos fichiers de DSF",
        "YEARE": "Excesice"
	},
    ASSETS: {
        "AD": 	"IMMOBILISATIONS INCORPORELLES",
        "AE": "Frais de recherche et développement",
        "AF": "Brevet, licences, logiciels et droits similaires",
        "AG": "Fond commercial et droit au bail",
        "AH": "Autres immobilisations incorporelles",
        "AI": "IMMOBILISATIONS CORPORELLES",
        "AJ": "Terrains (1) dont placement en net…………….",
        "AK": "Bâtiments (1) dont placement net ………………..",
        "AL": "Aménagements, agencements et installations",
        "AM": "Matériel, mobilier et actifs biologiques",
        "AN": "Matériel de transport",
        "AP": "AVANCES ET ACOMPTES VERSES SUR IMMOBILISATION",
        "AQ": "IMMOBILISATION FINANCIERES",
        "AR": "Titres de participation",
        "AS": "Autres immobilisations financières",
        "AZ": "ACTIF IMMOBILISE",
        "BA": "Actif circulant HAO",
        "BB": "Stocks et encours",
        "BC": "Créances et emplois assimilés",
        "BH": "Fournisseurs avances versées",
        "BI": "Clients",
        "BJ": "Autres créances",
        "BK": "ACTIF CIRCULANT",
        "BQ": "Titres de placement",
        "BR": "Valeurs à encaisser ",
        "BS": "Banques, chèques postaux, caisse et assimilés",
        "BT": "TRESORERIE - ACTIF",
        "BU": "Ecart de conversion - Actif",
        "BZ": "GENERAL - ACTIF"
    },
    LIABILITIES:{
        "CA": "Capital",
        "CB": "Apporteurs capital non appelé  (-)",
        "CD": "Primes liées au capital social",
        "CE": "Ecarts de réévaluation",
        "CF": "Réserves indisponibles",
        "CG": "Réserves libres",
        "CH": "Report à nouveau   (+ ou -)",
        "CJ": "Résultat net de l'exercice (bénéfices + ou perte -)",
        "CL": "Subventions d'investissement",
        "CM": "Povisions réglementées",
        "CP": "CAPITAUX PROPRES ET RESSOURCES ASSIMILEES",
        "DA": "Emprunts et dettes financières diverses",
        "DB": "Dettes de location acquisition",
        "DC": "Povisions pour risques et charges",
        "DD": "DETTES FINANCIERES ET RESSOURCES ASSIMILEES",
        "DF": "RESSOURCES STABLES",
        "DH": "Dettes circulantes HAO",
        "DI": "Clients, avances reçues",
        "DJ": "Fournisseurs d'exploitation",
        "DK": "Dettes fiscales et sociales",
        "DM": "Autres dettes",
        "DN": "Provisions pour risques à court terme",
        "DP": "PASIF CIRCULANT",
        "DQ": "Banques, crédits d'escompte",
        "DR": "Banques, établissements financiers et crédits de trésorerie",
        "DT": "TRESORERIE - PASSIF ",
        "DY": "Ecart de conversion - Passif",
        "DV": "Ecart de conversion - Passif",
        "DZ": "TOTAL GENERAL - PASSIF"
    },
    INCOME_STATEMENT:{
        "TA": "vente de marchandises",
        "RA": "Achat de marchandises",
        "RB": "Variation de stocks de marchandises",
        "XA": "MARGE COMMERCIALE",
        "TB": "Vente de produits fabriqués",
        "TC": "Travaux, services vendus",
        "TD": "Produits accessoires",
        "XB": "CHIFFRE D'AFFAIRES",
        "TE": "Produits stockés (ou destokage)",
        "TF": "Production immobilisée",
        "TG": "Subventions d'exploitation",
        "TH": "Autres produits",
        "TI": "Transfert de charges d'exploitation",
        "RC": "Achats de matières premières et fournitures liées",
        "RD": "Variation de stocks de matières premières et fournitures liées",
        "RE": "Autres achats",
        "RF": "Variation de stock d'autres approvisionnement",
        "RG": "Transports",
        "RH": "Services extérieurs",
        "RI": "Impôts et taxes",
        "RJ": "Autres charges",
        "XC": "VALEUR AJOUTEE",
        "RK": "Charges de personnel",
        "XD": "EXEDENT BRUT D'EXPLOITATION",
        "TJ": "Reprises d'amortissements, provisions et dépréciations",
        "RL": "Dotations aux amortissements, aux provisions et dépréciations",
        "XE": "RESULTAT D'EXPLOITATIION",
        "TK": "Revenus financiers et assimilés",
        "TL": "Reprises de provisions et dépréciations financières",
        "TM": "Transfert de charges financières",
        "RM": "Frais financiers et charges assimilées",
        "RN": "Dotations aux provisions et aux dépréciations financières",
        "XF":   "RESULTAT FINANCIER",
        "XG":   "RESULTAT DES ACTIVITES ORDINAIRES",
        "TN": "Produits et cessions d'immobilisations",
        "TO": "Autres Produits HAO",
        "RO": "Valeurs comptables de cessions d'immobilisations",
        "RP": "Autres charges HAO",
        "XH": "RESULTATS ACTIVITES ORDINAIRES",
        "RQ": "Participation des travailleurs",
        "RS": "Impôt sur le résultat",
        "XI": "RESULTAT NET"		
    },
    CASH_FLOW:{
        "ZA" : "Trésorerie nette au 1er janvier",
        "FA": "Capacité d'Autofinancement Globale",
        "FB": "Actif circulant HAO",
        "FC": "Variation des stocks",
        "FD": "Variation des créances",
        "FE": "Variation du passif circulant",
        "ZB": "Flux de trésorerie activités opérationnelles",
        "FF": "Décais. liés aux acq. d'immob. incorporelles",
        "FG": "Décais. liés aux acq. d'immob. corporelles",
        "FH": "Décais. liés aux acq. d'immob. financières",
        "FI": "Encais. liés aux cessions d'immob. incorp. et corp.",
        "FJ": "Encais. liés aux cessions d'immob. financières",
        "ZC": "Flux de trésorerie activités d'investissements",
        "FK": "Augmentations de capital ",
        "FL": "Subventions d'investissement recues",
        "FM": "Prélèvements sur le capital",
        "FN": "Dividendes versés",
        "ZD": "Flux de trésorerie capitaux propres",
        "FO": "Emprunts",
        "FP": "Autres dettes financières",
        "FQ": "-Remboursements des emprunts et autres dettes financières",
        "ZE": "Flux de trésorerie capitaux étrangers",
        "ZF": "Flux de trésorerie des activités de financement",
        "ZG": "Variation de la tresorerie nette de la periode",
        "ZH": "Tresorerie nette au 31 Décembre"
    },
    FOOTNOTE:{
        "TAB1": "Ecart de Conversion",
        "TAB2": "Capital & Dettes",
        "TAB3": "Regulrisation Charges",
        "TAB4": "Dettes et Charges Financières",
        "TAB5": "Actif & Passif Circulant",
        "TAB6": "Amortissement & Provisions",
        "TAB7": "Acquisition et Cession",
        "TAB_1": "Charges d'emprunt",
        "TAB_2": "Reprises H.A.O",
        "TAB_3": "Dotation H.A.O ",
        "TAB_4": "Frais d'établissement",
        "TAB_5": "Primes de remboursement obligation",
        "TAB_6": "Charge à repartir",
        "TAB_7": "Interets courus sur prêt",
        "TAB_8": "ECA/immo",
        "TAB_9": "ECP/Immo",
        "TAB_10": "ECA/Stocks",
        "TAB_11": "ECP/Stocks ",
        "TAB_12": "ECA/RAO",
        "TAB_13": "ECP/RAO",
        "TAB_14": "ECA/RHAO",
        "TAB_15": "ECP/RHAO",
        "TAB_16": "ECA/Emprunt ",
        "TAB_17": "ECP/Emprunt ",
        "TAB_18": "Interets courus sur emprunt",
        "TAB_19": "ECA/dettes ",
        "TAB_20": "ECP/dettes",
        "TAB_21": "ECA/dettes hao",
        "TAB_22": "ECP/dettes hao ",
        "TAB_23": "Acquisition Immo. Inc",
        "TAB_24": "Realisable +1an ",
        "TAB_25": "Immo Fin -1an",
        "TAB_26": "Emprunt Contracté",
        "TAB_27": "Creances sur Immob",
        "TAB_28": "Fourn. Investissement (dette)",
        "TAB_29": "Acquisition Immo. Cor ",
        "TAB_30": "Acquisition Immo. Fin ",
        "TAB_31": "Cession Immo. Inc.",
        "TAB_32": "Cession Immo. Cor. ",
        "TAB_33": "Cession Immo. Fin.",
        "TAB_34": "Prelevement sur le capital",
        "TAB_35": "dividendes",
        "TAB_36": "Remboursement de dette",
        "TAB_37": "Dettes à moins d'1 an",
        "TAB_38": "Fournisseurs à plus d'1 an ",
        "TAB_39": "Provision sur Stock ",
        "TAB_40": "Provision sur Creances",
        "TAB_41": "Ammortissement Cumul. Inc",
        "TAB_42": "Ammortissement Cumul. corp",
        "TAB_43": "Ammortissement Cumul. fin",
        "TAB_44": "Autre dettes financieres",
        "TAB_45": "Provision sur tresorerie Actif",
        "TAB_46": "Nombre de parts sociales",
        "effectif": "Effectif"
    },
    QUALITATIVE_DATA:{
        "LABEL": "Au Sujet de l'entrepreneur",
        "MOD": "Modalites",
        "Var_1": "Quel est le degre d'Aversion au risque [du gérant/promoteur] ?",
        "Var_2": "L'entrepreneur est intègre et joui d'une bonne réputation",
        "Var_3": "Le gerant/entrepreneur a-t-il une Experience dans le meme secteur ?",
        "Var_4": "Entrepreneur agé [>40 ans] ?",
        "Var_5": "Quel est la Capacité de l'entreprise ?",
        "Var_6": "Respect des délais d’engagement",
        "Var_7": "Quel est le degré de Transparence dans la communication [du gérant] ?",
        "Var_8": "Quel est la Fiabilité [du gérant/promoteur] en tant que source d'info"
    },
    RATINGS_DATA:{
		"NOTE_LABEL": "Note de Crédit",
		"DEFAULT_PROBABILITY": "Probabilté de Défaut",
		"BETA": "Béta",
		"RISK_PREMIUM": "Prime de Risque",
		"FOND_PROPRE": "Cout Fonds Propres",
		"COST_CREDIT": "Cout du Crédit",
		"COST_CAPITAL": "Cout du Capital",


		"LIQUIDITY_TITLE": "Risque d'insolvabilité à court terme",
		"LIQUIDITY_ASSETS": "Actif circulant",
		"LIQUIDITY_LIAB": "Passif à court terme",
		"LIQUIDITY_CMS": "Disponibilité et Valeur Mobilière",
		"LIQUIDITY_CR": "Ratio Liquidité General",
		"LIQUIDITY_QR": "Ratio Liquidité Réduite",
		"WORKING_CAPITAL_MGT_TITLE": "Ratio de besoin en fond de roulement d'exploitation",
		"WORKING_CAPITAL_MGT_SALES": "Ventes",
		"WORKING_CAPITAL_MGT_AR": "Créances clients",
		"WORKING_CAPITAL_MGT_ART": "Rotation des crédits clients",
		"WORKING_CAPITAL_MGT_DR": "Durée crédit client",
		"WORKING_CAPITAL_MGT_COG": "Coût d'achat des marchandises vendues",
		"WORKING_CAPITAL_MGT_INV": "Stocks",
		"WORKING_CAPITAL_MGT_IT": "Taux de rotation de stock",
		"WORKING_CAPITAL_MGT_DIH": "Jour de stockage",
		"WORKING_CAPITAL_MGT_PUR": "Achats",
		"WORKING_CAPITAL_MGT_AP": "Dettes aux fournisseurs",
		"WORKING_CAPITAL_MGT_APT": "Rotation du credit fournisseurs",
		"WORKING_CAPITAL_MGT_DAP": "Durée de credit fournisseurs",
		"WORKING_CAPITAL_MGT_RFP": "Jour de besoin en fond de roulement à financer",

		"WORKING_CAPITAL_VAR_SJ": "Emploi Stable",
		"WORKING_CAPITAL_VAR_SR": "Ressource Stable",
		"WORKING_CAPITAL_VAR_WC": "Fond de roulement",
		"WORKING_CAPITAL_VAR_VWC": "Variation de Fond de Roulement",
		"WORKING_CAPITAL_VAR_CAN": "Actif Circulant d'Exploitation",
		"WORKING_CAPITAL_VAR_CLN": "Passif à Court Terme d'Exploitation",
		"WORKING_CAPITAL_VAR_NCWCN": "Besoin en Fond de Roulement d'Exploitation",
		"WORKING_CAPITAL_VAR_VWCN": "Variation du BFRE",
		"WORKING_CAPITAL_VAR_CAE": "Actif Circulant Hors Exploitation",
		"WORKING_CAPITAL_VAR_CLE": "Passif à Court Terme Hors Exploitation",
		"WORKING_CAPITAL_VAR_NCWCE": "Besoin en Fond de Roulement Hors Exploitation",
		"WORKING_CAPITAL_VAR_VWCHAO": "Variation du BFREHE",
		"WORKING_CAPITAL_VAR_NCWCR": "Besoin en Fond de Roulement",
		"WORKING_CAPITAL_VAR_VWCR": "Variation du BFR",
		"WORKING_CAPITAL_VAR_CM": "Disponibilités et Valeur Mobilière de Placement",
		"WORKING_CAPITAL_VAR_STDB": "Tresorerie Passif",
		"WORKING_CAPITAL_VAR_CWC": "Tresorie",
		"WORKING_CAPITAL_VAR_WCR": "Besoin en Fond de Roulement d'Exploitation",
		"WORKING_CAPITAL_VAR_WCRHAO": "Besoin en Fond de Roulement Hors Exploitation",
		"WORKING_CAPITAL_CAFG": "Capacité d'Auto Financement",

		"CHARGE_COVERAGE_RATIO_TITLE": "Ratio de couvertures des charges financières et ratios de couverture des charges fixes",
		"CHARGE_COVERAGE_RATIO_EBIT": "Résultat d'Exploitation avant Intérêts et impôts",
		"CHARGE_COVERAGE_RATIO_IE": "Charges d'Interêts",
		"CHARGE_COVERAGE_RATIO_ICR": "Ratio de Couverture des Intérêts",
		"CHARGE_COVERAGE_RATIO_OLE": "Charges sur Contrats de location-exploitation",
		"CHARGE_COVERAGE_RATIO_FCCR": "Ratio de Couverture des Charges Fixes",
		"CHARGE_COVERAGE_RATIO_EBEXP": "Resultat avant interêt, impot, dépreciations et amortissement",
		"CHARGE_COVERAGE_RATIO_CFC": "Charges fixes décaissables",
		"CHARGE_COVERAGE_RATIO_CFHCR": "Ratio de Couverture de charges fixe décaissé",
		"CHARGE_COVERAGE_RATIO_CFO": "Capacité d'autofinancement",
		"CHARGE_COVERAGE_RATIO_CE": "Dépenses d'investissement",
		"CHARGE_COVERAGE_RATIO_CF": "Ratio de Capacité d'autofinancement des dépenses d'investissement",

		"DEBT_RATIO_TITLE": "Ratio d'endettement fondés sur la valeur comptable des dettes",
		"DEBT_RATIO_LTD": "Dette à Court Terme",
		"DEBT_RATIO_STD": "Dette à Court Terme",
		"DEBT_RATIO_BVE": "Valeur comptable des Capitaux Propres",
		"DEBT_RATIO_LTDE": "Ratio de structure du capital à long terme",
		"DEBT_RATIO_LTD_LTDE": "Ratio d'endettement à long terme",
		"DEBT_RATIO_DE": "Ratio de structure du capital",
		"DEBT_RATIO_DDE": "Ratio d'endettement",
		"DEBT_RATIO_ER": "Equity Ratio",

		"RETURN_RATIO_TITLE": "Return Ratio",
		"RETURN_RATIO_SALES": "Chiffre d'affaire",
		"RETURN_RATIO_OI": "Résultat d'Exploitation",
		"RETURN_RATIO_ATOI": "Résultat d'Exploitation après Impôt",
		"RETURN_RATIO_NE": "Résultat Net",
		"RETURN_RATIO_OM": "Marge Bénéficiaire",
		"RETURN_RATIO_ETOM": "Marge Bénéficiaire après Impôt",
		"RETURN_RATIO_NM": "Marge Net",
		"RETURN_RATIO_EBITM": "Marge Excedent Exploitation",
		"RETURN_RATIO_BVEB": "Valeur comptable des Capitaux Propres de début",
		"RETURN_RATIO_BVEE": "Valeur comptable finale des Capitaux Propres",
		"RETURN_RATIO_BVEA": "Valeur comptable Moyenne annuelle",
		"RETURN_RATIO_ROEBA": "Rentabilitée des Capitaux Propres (moyenne)",
		"RETURN_RATIO_ROEBB": "Rentabilitée des Capitaux Propres (début d'exercice)",
		"RETURN_RATIO_BVCB": "Valeur comptable du Capitale de début",
		"RETURN_RATIO_BVCE": "Valeur comptable finale du Capital",
		"RETURN_RATIO_BVCA": "Valeur comptable Moyenne annuelle du Capitale",
		"RETURN_RATIO_ROCBB": "Rentabilité du Capital(début d'exercice)",
		"RETURN_RATIO_ROCBA": "Rentabilité du Capital(moyenne)",
		"RETURN_RATIO_ROCBE": "Rentabilité du Capital(fin exercise)",
		"RETURN_RATIO_BVCIB": "Valeur comptable du Capital Investi de début",
		"RETURN_RATIO_BVCIE": "Valeur comptable finale du Capital Investi",
		"RETURN_RATIO_BVCIA": "Valeur comptable Moyenne annuelle du Capital Investi",
		"RETURN_RATIO_ROICBA": "Rentabilité du Capital Investi(moyenne)",
		"RETURN_RATIO_ROICBB": "Rentabilité du Capital Investi(début d'exercice)",
		"RETURN_RATIO_BVAB": "Valeur comptable d'Actif de début",
		"RETURN_RATIO_BVAE": "Valeur comptable final d'Actif",
		"RETURN_RATIO_BVAA": "Valeur comptable Moyenne d'Actif",
		"RETURN_RATIO_ROABB": "Rentabilté de l'Actif(de début)",
		"RETURN_RATIO_ROABA": "Rentabilité de l'Actif (moyenne)",
		"RETURN_RATIO_ATROC": "Rentabilité des Capitaux après Impôt",
		"RETURN_RATIO_DER": "Ratio Dettes/ Capitaux Propres",
		"RETURN_RATIO_BIR": "Taux d'Interêt Apparent(1- Taux d'Impôt)",
		"RETURN_RATIO_ROE": "Rentabilité des Capitaux Propres",
		"RETURN_RATIO_ATO": "Taux de Rotation d'Actif",
		"ADJUSTED": "Ajusté",
		"UNADJUSTED": "Non Ajusté",
		"GRADE1": "Très Favorable",
		"GRADE2": "Favorable",
		"GRADE3": "Neutre",
		"GRADE4": "Peu risqué",
		"GRADE5": "Risqué",
		"GRADE6": "Très risqué",
		"GRADE7": "Défaut",
		"NOTE": "Note",
		"IDANTIFIANT": "Identifiant",
		"DATE": "Date",
		"ACTIVITY": "Activité",
		"INDICATOR": "Indicateur",
		"DOWNLOAD": "Télécharger",
		"DETAILS": "Détails"
	},
    textAssumption: "Hypothéses",
    ASSUMPTIONS: {
        kAccPayab: "Account Payable",
        kAccRecev: "Account Receivable",
        kCashMarket: "Cash market secutities",
        kCostOfSales: "cost of Sales",
        kDepreciation: "Depreciation on Current Assets",
        kDeprOthNonCur: "Depreciation on non-current Assets",
        kDividend: "Divident",
        kInterestInc: "Interest Income",
        kInventories: "Inventories",
        kOthCurAss: "Other Current Assets",
        kOthCurLiab: "Other Current Liabilities",
        kOtherNetInc: "Other Net Income",
        kOthNonCur: "Other Non Current Assets",
        kOthNonCurLiab: "Other non Current Liabilities",
        kPpe: "Property, Equity and plant",
        kSales: "Sales",
        kSellingGeneAdm:"Selling and Gen. Admin",
        kShortDeprStrict: "Short term Depreciation",
        kStd: "Short term Debt",
        kTax: "Tax",
        kTaxSocialLiab: "Tax and Social Liabilites",
        longTermInt: "Long term interest",
        shortTermRate: "Short term rate",
        kShortDepr: "Short term Depreciation"
    },

    FINFORCASTING: {
        Sales: "Sales",
        COS: "Cost of Sales",
        GOInc: "Gross Operating Income",
        SGAExp: "Selling, General & Adm. Expenses",
        Deprec: "Depreciation",
        OthNIncExp: "Other net (Income)/Expenses",
        EBIT: "EBIT",
        IntInc: "Interest Income",
        IntExp: "Interest Expense",
        PreTaxInc: "Pretax Income",
        IncTax: "Income Tax",
        NetInc: "Net Income",
        Dividend: "Dividends",
        AddRet: "Addition Retained",
        CMSec: "Cash and Marketable Securities",
        AccRec: "Account Receivalbe",
        Inv: "Inventories",
        OthCurrAss: "Other Current Assets",
        OthCurrAccumDepr: "Accumulated Depreciation",
        ToCurrAss: "Total Current Assets",
        PPEGr: "Property, Plant and Equipment, Gross",
        PPEAcummDepr: "Accumulated Depreciation",
        PPENet: "Property, Plant and Equipment Net",
        OthNCurrAss: "Other Non-Current Assets",
        OthNCurrAccDepr: "Accumulated Depreciation",
        ToNCurrAss: " Total Non-Current Assets",
        ToAss: "  Total Assets",
        AccPay: "Account Payable",
        TSLiab: "Tax and Social Liabilities",
        STD: "Short-term Debt",
        OthCurrLiab: "Other Current Liabilities",
        ToCurrLiab: "   Total Current Liabilities",
        LTDeb: "Long Term Debt",
        OthNCurrLiab: "Other Non-Current Liabilities",
        ToLiab: "Total Liabilities",
        Pcap: "Paid Capital",
        RetEarn: "Retained Earnings",
        ToShaEq: "   Total Shareholders's Equity",
        ToLiabEq: " Total Liabilities and Shareholders' Equity",
        FDN: "Discretionary Funding Need (FDN)",
        EPS: "EPS",
        DivPSh: "Dividend per Share",
        PERatio: "P/E Ratio",
        PBRatio: "P/B Ratio",
        DivPR: "Dividend Payout Ratio",
        ROS: "Return On Sales",
        ROE: "Return On Equity",
        ROA: "Return On Asset",
        SGR: "Ventes",
        EBITGR: "Croissance de EBIT ",
        NetIncGR: "Croissance du Resultat Net",
        CurrR: "Ratio Liquidité General",
        QuR: "Ratio Liquidité Réduite",
        Income: "Resultat",
        ProfRatio: "Ratio de profitabilité",
        GrowthRatio: "Ratio de croissance",
        LiqRatio: "Ratio de liquidité",
        LeverageRatio: "Leverage Ratio",
        coverageRatio: "Ratio de couverture des charges",
        textInitialFI: "Indicateurs Finaciers Initiaux",
        textAssumptionConsidered: "Hypotheses Considérées",
        textForcastingOnFI: "Prévision sur les Indicateurs financier",
        DTC: "Total debt to Total capitalization",
        TLDTC: "Long term debt to Total capitalisation",
        DEQ: "Total debt to Equity",
        TIE: "Time Interest Earned (TIE)",
        CCR: "Cash coverage ratio",
        StatementBefore: "Financial Statement before the forecasted period",
        CSStatement: "Common size Statement"
    },
    COUNTRY: {
        "AF": "Afghanistan",
        "AX": "Åland",
        "AL": "Albanie",
        "DZ": "Algérie",
        "AS": "Samoa américaines",
        "AD": "Andorre",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antarctique",
        "AG": "Antigua et Barbuda",
        "AR": "Argentine",
        "AM": "Arménie",
        "AW": "Aruba",
        "AU": "Australie",
        "AT": "Autriche",
        "AZ": "Azerbaïdjan",
        "BS": "Bahamas",
        "BH": "Bahreïn",
        "BD": "Pakistan oriental",
        "BB": "Barbade",
        "BY": "Biélorussie",
        "BE": "Belgique",
        "BZ": "Belize",
        "BJ": "Bénin",
        "BM": "Bermudes",
        "BT": "Bhoutan",
        "BO": "Bolivie",
        "BQ": "Pays-Bas caribéens",
        "BA": "Bosnie-Herzégovine",
        "BW": "Botswana",
        "BV": "Île Bouvet",
        "BR": "Brésil",
        "IO": "Territoire britannique de l'océan Indien",
        "VG": "Îles Vierges britanniques",
        "BN": "Brunéi Darussalam",
        "BG": "Bulgarie",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "CV": "Cap-Vert",
        "KH": "Cambodge",
        "CM": "Cameroun",
        "CA": "Canada",
        "KY": "Iles Caïmanes",
        "CF": "République Centrafricaine",
        "TD": "République du Tchad",
        "CL": "Chili",
        "CN": "République populaire de Chine",
        "CX": "Île Christmas",
        "CC": "Îles Cocos",
        "CO": "Colombie",
        "KM": "Comores (Archipel)",
        "CK": "Îles Cook",
        "CR": "Costa Rica",
        "HR": "Croatie",
        "CU": "Cuba",
        "CW": "Curaçao",
        "CY": "Chypre",
        "CZ": "République tchèque",
        "CD": "République Démocratique du Congo",
        "DK": "Denmark",
        "DJ": "République de Djibouti",
        "DM": "Dominique",
        "DO": "République dominicaine",
        "EC": "Équateur",
        "EG": "Égypte",
        "SV": "Salvador",
        "GQ": "Guinée équatoriale",
        "ER": "Érythrée",
        "EE": "Estonie",
        "SZ": "Royaume du Swaziland",
        "ET": "Éthiopie",
        "FK": "Îles Malouines",
        "FO": "Îles Féroé",
        "FJ": "Îles Fidji",
        "FI": "Finlande",
        "FR": "République Française",
        "GF": "Guyane Française",
        "PF": "Polynésie française",
        "TF": "Terres Australes et Antarctiques Françaises",
        "GA": "République Gabonaise",
        "GM": "Gambie",
        "GE": "Géorgie",
        "DE": "Allemagne",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GR": "Grèce",
        "GL": "Groenland",
        "GD": "Grenade",
        "GP": "Guadeloupe",
        "GU": "Guam",
        "GT": "Guatemala",
        "GG": "Guernesey",
        "GN": "Guinée-Conakry",
        "GW": "Guinée-Bissau",
        "GY": "Guyane britannique",
        "HT": "Haïti",
        "HM": "Îles Heard-et-MacDonald",
        "HN": "Honduras",
        "HK": "Hong Kong",
        "HU": "Hongrie",
        "IS": "Islande",
        "IN": "Inde",
        "ID": "Indonésie",
        "IR": "Iran (République islamique de)",
        "IQ": "Irak",
        "IE": "Irlande",
        "IM": "Île de Man",
        "IL": "Israël",
        "IT": "Italie",
        "CI": "République de Côte d'Ivoire",
        "JM": "Jamaïque",
        "JP": "Japon",
        "JE": "Bailliage de Jersey",
        "JO": "Jordanie",
        "KZ": "Kazakhstan",
        "KE": "Kenya",
        "KI": "Kiribati",
        "XK": "Kosovo",
        "KW": "Koweït",
        "KG": "Kirghizstan",
        "LA": "Laos",
        "LV": "Lettonie",
        "LB": "Liban",
        "LS": "Lesotho",
        "LR": "Libéria",
        "LY": "Libye",
        "LI": "Liechtenstein",
        "LT": "Lituanie",
        "LU": "Grand-Duché de Luxembourg",
        "MO": "Macau",
        "MG": "Madagascar",
        "MW": "Malawi",
        "MY": "Malaisie",
        "MV": "Maldives",
        "ML": "République du Mali",
        "MT": "Malte",
        "MH": "îles;Îles Marshall",
        "MQ": "Martinique",
        "MR": "Mauritanie",
        "MU": "Île Maurice",
        "YT": "Mayotte",
        "MX": "Mexique",
        "FM": "États fédérés de Micronésie",
        "MD": "République de Moldavie",
        "MC": "Principauté de Monaco",
        "MN": "Mongolie",
        "ME": "République du Monténégro",
        "MS": "Montserrat",
        "MA": "Royaume du Maroc",
        "MZ": "Mozambique",
        "MM": "Birmanie",
        "NA": "Namibie",
        "NR": "Nauru",
        "NP": "Népal",
        "AN": "Antilles Néerlandaises",
        "NC": "Nouvelle Calédonie",
        "NZ": "Nouvelle Zélande",
        "NI": "Nicaragua",
        "NE": "République du Niger",
        "NG": "Nigéria",
        "NU": "Nioué",
        "NF": "Ile de Norfolk",
        "MP": "Îles Mariannes du Nord",
        "KP": "Corée du Nord",
        "MK": "Macédoine du Nord",
        "NO": "Norvège",
        "OM": "Oman",
        "PK": "Pakistan",
        "PW": "Palaos",
        "PS": "Palestine",
        "PA": "Panamá",
        "PG": "Papouasie-Nouvelle-Guinée",
        "PY": "Paraguay",
        "PE": "Pérou",
        "PH": "Philippines",
        "PN": "Îles Pitcairn",
        "PL": "Pologne",
        "PT": "Portugal",
        "PR": "Porto Rico",
        "QA": "Qatar",
        "CG": "République du Congo",
        "RE": "La Réunion",
        "RO": "Roumanie",
        "RU": ";Russie",
        "RW": "République du Rwanda",
        "BL": "Saint Barthélemy",
        "SH": "Île Sainte-Hélène",
        "KN": "Saint-Christophe-et-Niévès",
        "LC": "Sainte-Lucie",
        "MF": "Collectivité de Saint-Martin",
        "PM": "Collectivité Territoriale de Saint-Pierre et Miquelon",
        "VC": "Saint-Vincent-et-les-Grenadines",
        "WS": "Samoa",
        "SM": "Saint-Marin",
        "ST": "Sao Tomé-et-Principe",
        "SA": "Arabie saoudite",
        "SN": "Sénégal",
        "RS": "Serbie",
        "CS": "Serbie-et-Monténégro",
        "SC": "Seychelles",
        "SL": "Sierra Leone",
        "SG": "Singapour",
        "SX": "État de Saint-Martin",
        "SK": "République slovaque",
        "SI": "Slovénie",
        "SB": "Îles Salomon",
        "SO": "Somalie",
        "ZA": "Afrique du Sud",
        "GS": "Géorgie du Sud et îles Sandwich du Sud",
        "KR": "Corée du Sud",
        "SS": "Soudan du Sud",
        "ES": "Espagne",
        "LK": "Sri Lanka",
        "SD": "Soudan",
        "SR": "Surinam",
        "SJ": "Svalbard et Île Jan Mayen",
        "SE": "Suède",
        "CH": "Confédération Suisse",
        "SY": "République arabe syrienne",
        "TW": "Taïwan",
        "TJ": "Tadjikistan",
        "TZ": "Tanzanie",
        "TH": "Thaïlande",
        "NL": "Pays-Bas",
        "TL": "Timor Oriental",
        "TG": "République Togolaise",
        "TK": "Tokelau",
        "TO": "Tonga",
        "TT": "Trinidad et Tobago",
        "TN": "Tunisie",
        "TR": "Turkey",
        "TM": "Turkménistan",
        "TC": "Îles Turks et Caïques",
        "TV": "Tuvalu",
        "UG": "Ouganda",
        "UA": "Ukraine",
        "AE": "Émirats Arabes Unis",
        "GB": "Royaume-Uni",
        "US": "États Unis",
        "UM": "Îles mineures éloignées des États-Unis",
        "UY": "Uruguay",
        "VI": "Îles Vierges des États-Unis",
        "UZ": "Ouzbékistan",
        "VU": "République de Vanuatu",
        "VA": "Saint-Siège (Etat de la Cité du Vatican)",
        "VE": "Venezuela",
        "VN": "Viêt Nam",
        "WF": "Territoire des Îles Wallis et Futuna",
        "EH": "Sahara Occidental",
        "YE": "Yémen",
        "ZM": "Zambie",
        "ZW": "Zimbabwe"
    }
}