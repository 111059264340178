import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { API } from "../../../services/axios";
import { URLS } from "../../../services/urls";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../../components/language";



const PasswordRecoverPage = () => {
    const [loading, setLoading] = useState(false);
    const { uuid, token } = useParams()
    const [verified, setVerified] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const {t} = useTranslation();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({});
    const navigate=useNavigate()
    const onSubmit = (data) => {
        setLoading(true);
        if(uuid && token){
            const info={
                new_password1: data.password,
                new_password2: data.confirmPassword,
                uid: uuid,
                token: token,
              };
            API.post(URLS.AUTHS.PASSWORD.confirm, info).then((resp)=>{
                setVerified(1)
                toast.success(t("textPWRecovered"));
                navigate("/account/login");
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
                if(verified===0){
                    toast.error(error?.response?.data?.detail ?? t("textEmailConfirmErr"));
                }
              });
        }else{
            API.post(URLS.AUTHS.PASSWORD.reset, data)
            .then((resp) => {
                setLoading(false);
                toast.success(resp?.data?.detail ?? t("textEmailSentMsg"));
                navigate("/account/login");
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.detail ?? t("textEmailNotFound"));
            });
        }
      };
    return (uuid && token)? (
        <div>
            <section className={loading?"tp-testimonial-area tp-testimonial-bg-color p-relative pt-80 pb-50 disabled":"tp-testimonial-area tp-testimonial-bg-color p-relative pt-80 pb-50"} data-bg-color="#F6F6F9">
            <div className="tp-testimonial-bg">
               <img src="/assets/img/testimonial/bg-shape.png" alt=""/>
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="tp-testimonial-title">
                        <span className="tp-section-title-pre"><img
                                    alt="B.K. Research"
                                    src="/assets/img/logo/logo_small.png"
                                    className="app-logo"
                                /></span>
                        <h3 className="tp-section-title" style={{background:"linear-gradient(90deg, #004D6E 0%, #00ACCC 100%)", WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>Recover Your Password!</h3>
                     </div>
                  </div>
                     <div className="swiper-wrapper mb-30 mt-20">
                        <div className="tp-faq-tab-content tp-accordion wow fadeInRight">
                            <div className="mb-40" style={{minWidth:"450px"}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="">
                                            <div className="mb-3 inline">
                                                <span>{t("textHaveAccount")}? </span>
                                                <Link to="/account/login" style={{fontWeight:"bold", textDecoration:"underline"}} className="text-primary">{t("textLogIn")}</Link>
                                                {/* <span><br/>It takes less than a minute</span> */}
                                            </div>
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                    <label className="form-label" htmlFor="input-4">
                                                        {t("textTypeNewPW")} <span className="text-danger">*</span>
                                                    </label>
                                                <div className="form-group input-group">
                                                    <input
                                                        className="form-control"
                                                        id="input-4"
                                                        type={showPassword ? 'text' : 'password'}
                                                        {...register("password", { required: "Type password"})}
                                                        placeholder="************"
                                                    />
                                                    <div className="input-group-append" onClick={togglePasswordVisibility} style={{cursor:"pointer",minHeight:"55px"}}>
                                                        <span  className="eye-icon input-group-text" id="basic-addon2">
                                                        <FontAwesomeIcon icon={showPassword ? faEye: faEyeSlash } /></span>
                                                    </div>
                                                </div>
                                                    <label className="form-label" htmlFor="input-4">
                                                    {t("textReTypePW")} <span className="text-danger">*</span>
                                                    </label>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        id="input-4"
                                                        type="password"
                                                        {...register("confirmPassword", {
                                                        required: "retype password",
                                                        validate: (val) => {
                                                            if (watch("password") !== val) {
                                                            return "Password Must mathc";
                                                            }
                                                        },
                                                        })}
                                                        placeholder="************"
                                                    />
                                                    <span className="text-primary">
                                                        {errors.confirmPassword?.message && (
                                                        <small>{errors.confirmPassword.message}</small>
                                                        )}
                                                    </span>
                                                </div>
                                                <button className="btn btn-primary btn-block mt-2" disabled={loading}>
                                                    {t("textReset")}</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3" style={{display: "flex", flexWrap: "wrap", justifyContent: "center", width:"inherit"}}>
                                    <LanguageSwitcher/>
                                </div>
                            </div>
                        </div>
                  </div>
            </div>
            </div>
         </section>
        </div>
    ):( 
        <section className={loading?"tp-testimonial-area tp-testimonial-bg-color p-relative pt-80 pb-50 disabled":"tp-testimonial-area tp-testimonial-bg-color p-relative pt-80 pb-50"} data-bg-color="#F6F6F9">
            <div className="tp-testimonial-bg">
               <img src="/assets/img/testimonial/bg-shape.png" alt=""/>
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="tp-testimonial-title">
                        <span className="tp-section-title-pre"><img
                                    alt="B.K. Research"
                                    src="/assets/img/logo/logo_small.png"
                                    className="app-logo"
                                /></span>
                        <h3 className="tp-section-title" style={{background:"linear-gradient(90deg, #004D6E 0%, #00ACCC 100%)", WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>Recover Your Password!</h3>
                     </div>
                  </div>
                    <div className="swiper-wrapper mb-30 mt-20">
                        <div className="tp-faq-tab-content tp-accordion wow fadeInRight">
                            <div className="mb-40" style={{minWidth:"450px"}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="">
                                            <div className="mb-3 inline">
                                                <span>{t("textHaveAccount")}? </span>
                                                <Link to="/account/login" style={{fontWeight:"bold", textDecoration:"underline"}} className="text-primary">{t("textLogIn")}</Link>
                                                {/* <span><br/>It takes less than a minute</span> */}
                                            </div>
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="form-group">
                                                    <label htmlFor="email">{t("textEmail")}</label>
                                                    <input className="form-control" id="email" type="email"
                                                        {...register("email", {
                                                        required: t("textEmailRep"),
                                                        validate: {
                                                            maxLength: (v) =>
                                                            v.length <= 50 ||
                                                            "Maximun of 50 caracters",
                                                            matchPattern: (v) =>
                                                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                                                v
                                                            ) ||
                                                            "invalide email",
                                                        },
                                                        })}
                                                        placeholder="stevenjob@gmail.com"
                                                    />
                                                    <span className="text-primary">
                                                        {errors.email?.message && (
                                                        <small>{errors.email.message}</small>
                                                        )}
                                                </span>
                                                </div>
                                                <button className="btn btn-primary btn-block mt-2" disabled={loading}>
                                                    {t("textSendLink")}</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3" style={{display: "flex", flexWrap: "wrap", justifyContent: "center", width:"inherit"}}>
                                    <LanguageSwitcher/>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            </div>
         </section>
        
    );
}
export default PasswordRecoverPage;