export const URLS = {
    AUHT_URL: process.env.REACT_APP_AUHT_URL,
    CONF_URL: process.env.REACT_APP_CONF_URL,
    PARAM_URL: process.env.REACT_APP_PARAM_URL,
    RATING_URL: process.env.REACT_APP_RATING_URL,
    FFORCAST_URL: process.env.REACT_APP_FFORCAST_URL,
    PAYMENT_URL: process.env.REACT_APP_PAYMENT_URL,
    CLIENT_ID: "rgFz0scCkvH2qAm4iBWwk5m8f6qC9SVM5RHzrKze",
    AUTHS: {
      LOGIN: "auth/otp_login/",
      LOGIN_VERIFY: "auth/otp_login/verify/",
      LOGIN_OTP_RESEND: "auth/otp_login/resend/",
      REFRESH: "auth/token/refresh/",
      LOGOUT: "auth/logout",
      REGISTRATION: "accounts/registration/",
      MANAGER_REGISTRATION: "accounts/staff/registration/",
      VERIFY_EMAIL: (key) => `accounts/confirm-email/${key}/`,
      VERIFY_ACCOUNT: (id) => `/users/verify/${id}/`,
      resetPassword: "accounts/password-reset/",
      resetPasswordAdmin: "accounts/password/admin-reset/",
      me: "v1/user/me/",
      PASSWORD: {
        reset: "auth/password/reset/",
        confirm: "auth/password/reset/confirm/",
        change: "/auth/password/change/",
        verify: "/v1/user/password_verify/"
      },
      VERIFY: {
        email: "accounts/email/verification/",
        resend: "accounts/resend-email/",
      },
    },
    PAYMENT: {
      INVOICE: {
        list: ()=>`/invoices/me`,
        details: (id)=>`/invoices/${id}`,
        add: () => `/invoices`,
        download: () => `/invoices/download/`
      },
      PACKAGES: {
        list: ()=>`/products/`,
        details: (id)=>`products/getById/${id}`
      },
      SUBSCRIBTIONS: {
        list: ()=>`/subscription/me`,
        create: (packageId)=>`/subscription/${packageId}`,
        delete: (packageId) => `/subscription/delete/${packageId}`,
        update: (packageId) => `/subscription/update/${packageId}`,
      },
      CREDENTIALS: {
        list: () => `/payment_credentials/me`,
        create: () => `/payment_credentials`
      }
    },
    USERS: {
      ENTERPRISE: {
        formList: (search, page)=>`/enterprises/banks/?search=${search}`,
        list: (search, page)=>`/enterprises/banks/?search=${search}&offset=${((page ?? 1)-1)*25}&page=${page ?? 1}`,
        details: (id)=> `/enterprises/banks/${id}/`,
        listAll:(limit) => `/enterprises/banks/?limit=${limit}`,
      },
      me: "v1/user/me/",
      create: "v1/user/",
      update: (id) => `v1/user/${id}/`,
      details: (id) => `v1/user/${id}/`,
      updateEnterprise: (id) => `v1/user/${id}/update_enterprise/`,
      list: (search, page)=>`/v1/user/?search=${search}&offset=${((page ?? 1)-1)*25}&page=${page ?? 1}`
    },
    CRR: {
      list: (page) => `/rating/?offset=${((page ?? 1)-1)*25}&page=${page ?? 1}`,
      pending: (page) => `/rating/?offset=${((page ?? 1)-1)*25}&page=${page ?? 1}`,
      details: (id) => `/rating/${id}/`,
      downloadReport: (id) => `/rating/${id}/download_report/`,
      create: () => `/rating/`,
      extractData: () => `rating/extract_data/`
    },
    CONFIGURATIONS: {
      ACCOUNTS:{},
      MASSES: {
        formList: (search, page)=>`rating/masses/?search=${search}`,
        list:(search, page, limit) => `rating/masses/?limit=${limit}search=${search}&offset=${((page ?? 1)-1)*25}&page=${page ?? 1}`,
        listAll:(limit) => `rating/masses/?limit=${limit}`
      },
      QUALITATIVEDATA: {
        list:(search, page) => `/rating/qualitativedata/?search=${search}&offset=${((page ?? 1)-1)*25}&page=${page ?? 1}`,
      },
      // Business analytics
      FINFORCASTING: {
        assumptions: () => `businessanalysis/asumptions/`
      }
    },
    PARAMS: {
      ACTIVITY:{
        listAll:(limit) => `/params/activities/?limit=${limit}`
      },
      LEGALSTATUS:{
        listAll: (limit) => `/industries_params/legal_status/?limit=${limit}`
      },
      COUNTRYPARM: {
        CDSSPREAD: {
          list: (search, page) => `/countries_params/cds_spread/?search=${search}&offset=${((page ?? 1)-1)*25}&page=${page ?? 1}`,
          details: (id) => `/countries_params/cds_spread/${id}`
        }
      },
      COUNTRY: {
        formList: (search, page)=>`/countries/countries/?search=${search}`,
        list: (search, page) => `/countries/countries/?search=${search}&offset=${((page ?? 1)-1)*25}&page=${page ?? 1}`,
        listAll: (limit) => `/countries/countries/?limit=${limit}`,
        details: (id) => `/countries/countries/${id}/`,
        ohada: (limit) => `/countries_params/countries/ohada/?limit=${limit}`,
        CYTIES: {
          list: (search, page) => `/countries/cities/?search=${search}&offset=${((page ?? 1)-1)*25}&page=${page ?? 1}`,
          listAll: (limit) => `/countries/cities/?limit=${limit}`,
          details: (id) => `/countries/cities/${id}`,
          ohada: (limit) => `/countries_params/cities/ohada/?limit=${limit}`,
          african: (limit) => `/countries_params/cities/?limit=${limit}`,
        },
        SUBREGIONS: {
          list: (search, page) => `/countries/subregions/?search=${search}&offset=${((page ?? 1)-1)*25}&page=${page ?? 1}`,
          details: (id) => `/countries/subregions/${id}`
        },
        REGIONS: {
          list: (search, page) => `/countries/regions/?search=${search}&offset=${((page ?? 1)-1)*25}&page=${page ?? 1}`,
          details: (id) => `/countries/regions/${id}`
        }
      },
    },
    FF:{
      list: () => '/',
      get: (id) => `/${id}/`,
      create: () => '/',
      patch: (id) =>`/${id}/`,
      extractData: (id) => `/${id}/extract_data/`,
      getUnbalanced: (id) => `/${id}/get_unbalanced_state/` ,
      forcast: (id) => `/${id}/forcast/`,
      save: (id) => `/${id}/save_forcast/`,
      downloadPdf: (ref) => `/media/reports/${ref}`
    }
  }