import Select from "react-select";
import { inLineSelectStyles} from "../../../components/utils/reactSelect";
const LeasingForm = ({getOlExpenses, olExpenses, annee, saveOLExpenses, t}) => {
    return (
        <div className="card text-left o-hidden">
            <div className="card-header d-flex align-items-center border-0 my-header">
                <h3 className="w-100 float-left card-title m-0 text-white">{t("OTHERCRRINFO.OLEXPENSE")}</h3>
            </div>
            <div className="table-responsive table-strip">
                    <table className="table table-hover table-bordered">
                    <thead style={{backgroundColor:"var(--tp-common-gray)"}}>
                        <tr>
                                <th >{t("OTHERCRRINFO.AMORTPERIOD")}</th>
                                <th scope="col" style={{padding:0, marginTop:0}}>
                                    <Select className="basic-single"  
                                    styles={inLineSelectStyles} 
                                    menuPortalTarget={document.body}
                                    onChange={(e)=>getOlExpenses(e.value)}
                                    options={[
                                        {label:1, value:1},
                                        {label:2, value:2},
                                        {label:3, value:3},
                                        {label:4, value:4},
                                        {label:5, value:5}]}/>
                                </th>
                            </tr>
                        {olExpenses.length>0 &&
                            <tr style={{backgroundColor:"var(--tp-common-gray)"}}>
                                <th scope="col">Year</th>
                                <th scope="col" style={{textAlign:"right"}}>{t("textAmount")}</th>
                            </tr>
                        }
                    </thead>
                    <tbody>
                        {olExpenses.map((val, index)=>(
                            <tr>
                                {
                                    val.year===annee &&
                                    <td>{t("OTHERCRRINFO.TYEAR")}</td>
                                }
                                {
                                    val.year!==annee &&
                                    <td>{val.year}</td>
                                }
                                <td style={{padding:0, marginTop:0}}>
                                    <input type="number" style={{borderStyle:"none", marginBottom:0, width:"100%", textAlign:"right"}} 
                                    placeholder={val.amount} className="" 
                                    onChange={(e)=>saveOLExpenses(e,index)}/></td>
                            </tr>)
                        )
                        }
                    </tbody>
                    </table>
            </div>
        </div>
    )
}
export default LeasingForm;