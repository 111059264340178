import { Link, json, useParams } from "react-router-dom"
import useSWR from "swr";
import { toast } from "react-toastify";
import BreadCrumb from "../../components/breadcrumb"

import { useEffect, useState } from "react";
import { MockData } from "../../mock-data/data";
import LoaderPage from "../../components/loader";
import { curencyFormater, percentageFormater } from "../../components/utils/functions";
import { useTranslation } from "react-i18next";
import { FFORCASTAPI } from "../../services/axios";
import { URLS } from "../../services/urls";
const fetcher = (url) => FFORCASTAPI.get(url).then((res)=> {return res.data})
const DisplayFinData = ({data, dCategory, years, translation}) => {
    // const {t} = useTranslation();
    return (
        <tbody>
            {dCategory!==null&&(<tr><td colSpan={years+1} className="text-center" style={{backgroundColor:"var(--tp-common-gray)"}}>{dCategory}</td></tr>)}
            {data?.map((element)=>(
                <tr key={element.id}>
                    <td>{translation(`FINFORCASTING.${element?.reference}`)}</td>
                    <td  style={{textAlign:"right"}}>{curencyFormater(element?.net)}</td>
                    <td  style={{textAlign:"right"}}>{curencyFormater(element?.net1)}</td>
                    {years>=3 &&(<td  style={{textAlign:"right"}}>{curencyFormater(element?.net2)}</td>)}
                    {years>=4 &&(<td  style={{textAlign:"right"}}>{curencyFormater(element?.net3)}</td>)}
                    {years>=5 &&(<td  style={{textAlign:"right"}}>{curencyFormater(element?.net4)}</td>)}
                </tr>
            ))}
        </tbody>
    )
}

const DisplayRatioData = ({data, dCategory, years, translation}) => {
    // const {t} = useTranslation();
    return (
        <tbody>
            {dCategory!==null&&(<tr><td colSpan={years+1} className="text-center" style={{backgroundColor:"var(--tp-common-gray)"}}>{dCategory}</td></tr>)}
            {data?.map((element)=>(
                <tr key={element.id}>
                    <td>{translation(`FINFORCASTING.${element?.reference}`)}</td>
                    <td  style={{textAlign:"right"}}>{percentageFormater(element?.net)}</td>
                    <td  style={{textAlign:"right"}}>{percentageFormater(element?.net1)}</td>
                    {years>=3 &&(<td  style={{textAlign:"right"}}>{percentageFormater(element?.net2)}</td>)}
                    {years>=4 &&(<td  style={{textAlign:"right"}}>{percentageFormater(element?.net3)}</td>)}
                    {years>=5 &&(<td  style={{textAlign:"right"}}>{percentageFormater(element?.net4)}</td>)}
                </tr>
            ))}
        </tbody>
    )
}

const DisplayCSS = ({data, dCategory, years, translation}) => {
    // const {t} = useTranslation();
    return (
        <tbody>
            {dCategory!==null&&(<tr><td colSpan={years+2} className="text-center" style={{backgroundColor:"var(--tp-common-gray)"}}>{dCategory}</td></tr>)}
            {data?.map((element)=>(
                <tr key={element.id}>
                    {/* <td>{translation(`FINFORCASTING.${element?.reference}`)}</td> */}
                    <td  style={{textAlign:"right"}}>{percentageFormater(element?.net)}</td>
                    <td  style={{textAlign:"right"}}>{percentageFormater(element?.net1)}</td>
                    {years>=3 &&(<td  style={{textAlign:"right"}}>{percentageFormater(element?.net2)}</td>)}
                    {years>=4 &&(<td  style={{textAlign:"right"}}>{percentageFormater(element?.net3)}</td>)}
                    {years>=5 &&(<td  style={{textAlign:"right"}}>{percentageFormater(element?.net4)}</td>)}
                    <td  style={{textAlign:"right"}}>{percentageFormater(element?.average)}</td>
                </tr>
            ))}
        </tbody>
    )
}

const FForcastingDetails = () => {
    const {t} = useTranslation();
    const { key, ref } = useParams();
    let {data, loadingData} = useSWR(URLS.FF.get(key), fetcher);
    const [loading, setLoading] = useState(false);

    const downloadPDFRep = () => {
        setLoading(true)
        const name=String(data?.pdf_report).split('/');
        FFORCASTAPI.get(URLS.FF.downloadPdf(name[name.length-1]))
        .then((res)=>{
            console.log(res.headers['content-type'])
            const blob = new Blob([res.data], {type: res.headers['content-type']})
            const href=URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = `data:application/pdf;base64,${res.data}`;
            link.setAttribute('download', `${name[name.length-1]}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setLoading(false)

        }).catch((err)=>{
            setLoading(false)
            toast.error("Unable to download your file, retry in a moment", {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
        })
    }
    useEffect(()=>{
        if(ref){
            toast.success("Your new Forecasting was successfully carried out", {position:toast.POSITION.TOP_RIGHT, theme:"colored"})
            toast.info("Take care to read the results carefully and if you have any enquiry , feel free to contact us", {position:toast.POSITION.BOTTOM_RIGHT, theme:"colored", autoClose: false})
        }
        // Mockdata?.response.forEach((forcast)=>{
        //     if(forcast.id===key){
        //         setData(forcast)
        //         setLoading(false);
        //     }
        // })
    },[])
    return (
        <>
            <BreadCrumb title1="FF-SME" title2="details" title3={data?.reference} link="/f-forcasting"/>
            <div className="row">
                {(loadingData || !data || loading)?(
                    <LoaderPage/>
                ):(
                    <>
                        <div className="row no-gutters">
                            <div className="col-9"></div>
                            <div className="col-lg-3 col-xl-3 col-md-3">
                                <div className="tp-service-widget-download mb-4" style={{marginRight:0, marginLeft:"auto"}}>
                                    <Link to={data?.pdf_report} target="_blank"><span style={{fontSize:"12pt"}}>Download Report <img src="/assets/img/service/service-details/icon-3.svg" alt=""/></span></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 mb-5">
                            <div className="card text-left o-hidden">
                                <div className="card-header d-flex align-items-center border-0 my-header">
                                    <h3 className="w-100 float-left card-title m-0 text-white">{t("textForcastingDataYear")} {data?.years} {t("textYear")}</h3>
                                </div>
                                <div className="table-responsive table-stripe">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-center">Label</th>
                                                <th scope="col" className="text-center">{data?.first_year+1}</th>
                                                <th scope="col" className="text-center">{data?.first_year+2}</th>
                                                {data?.years>=3 &&(<th scope="col" className="text-center">{data?.first_year+3}</th>)}
                                                {data?.years>=4 &&(<th scope="col" className="text-center">{data?.first_year+4}</th>)}
                                                {data?.years>=5 &&(<th scope="col" className="text-center">{data?.first_year+5}</th>)}
                                            </tr>
                                        </thead>
                                        <DisplayFinData data={data?.output_data?.forcasting?.income} dCategory={t("Income")} years={data?.years} translation={t}/>
                                        <DisplayFinData data={data?.output_data?.forcasting?.assets} dCategory={t("FIN_STATEMENT.ASSETS")} years={data?.years} translation={t}/>
                                        <DisplayFinData data={data?.output_data?.forcasting?.liabilities} dCategory= {t("FIN_STATEMENT.LIABILITIES")} years={data?.years} translation={t}/>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 mb-5">
                            <div className="card text-left mb-4 o-hidden">
                                <div className="card-header d-flex align-items-center border-0 my-header">
                                    <h3 className="w-100 float-left card-title m-0 text-white">{t("FINFORCASTING.textForcastingOnFI")}</h3>
                                </div>
                                <div className="table-responsive table-stripe">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-center">Label</th>
                                                <th scope="col" className="text-center">{data?.first_year+1}</th>
                                                <th scope="col" className="text-center">{data?.first_year+2}</th>
                                                {data?.years>=3 &&(<th scope="col" className="text-center">{data?.first_year+3}</th>)}
                                                {data?.years>=4 &&(<th scope="col" className="text-center">{data?.first_year+4}</th>)}
                                                {data?.years>=5 &&(<th scope="col" className="text-center">{data?.first_year+5}</th>)}
                                            </tr>
                                        </thead>
                                        <DisplayRatioData data={data?.output_data?.financial_indicator?.profitability_ratio} dCategory= {t("FINFORCASTING.ProfRatio")} years= {data?.years} translation={t}/>
                                        <DisplayRatioData data= {data?.output_data?.financial_indicator?.growth_rate} dCategory= {t("FINFORCASTING.GrowthRatio")} years= {data?.years} translation={t}/>
                                        <DisplayRatioData data= {data?.output_data?.financial_indicator?.liquidity_ratio} dCategory= {t("FINFORCASTING.LiqRatio")} years= {data?.years} translation={t}/>
                                        {/* <DisplayRatioData data= {data?.output_data?.financial_indicator?.operating_efficiency} dCategory={"Operating Efficiency"} years={data?.years} translation={t}/> */}
                                        <DisplayRatioData data= {data?.output_data?.financial_indicator?.leverage_ratio} dCategory= {t("FINFORCASTING.LeverageRatio")} years= {data?.years} translation={t}/>
                                        <DisplayRatioData data= {data?.output_data?.financial_indicator?.coverage_ratio} dCategory= {t("FINFORCASTING.coverageRatio")} years= {data?.years} translation={t}/>
                                    </table>
                                </div>
                            </div>
                            <div className="card text-left mt-5 mb-4 o-hidden">
                                <div className="card-header d-flex align-items-center border-0 my-header">
                                    <h3 className="w-100 float-left card-title m-0 text-white">{t("FINFORCASTING.textInitialFI")}</h3>
                                </div>
                                <div className="table-responsive table-stripe">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-center">Label</th>
                                                <th scope="col" className="text-center">{data?.first_year+1}</th>
                                                <th scope="col" className="text-center">{data?.first_year+2}</th>
                                                {data?.years>=3 &&(<th scope="col" className="text-center">{data?.first_year+3}</th>)}
                                                {data?.years>=4 &&(<th scope="col" className="text-center">{data?.first_year+4}</th>)}
                                                {data?.years>=5 &&(<th scope="col" className="text-center">{data?.first_year+5}</th>)}
                                            </tr>
                                        </thead>
                                        <DisplayRatioData data={data?.initial_indicator?.profitability_ratio} dCategory= {t("ProfRatio")} years= {data?.years} translation={t}/>
                                        <DisplayRatioData data= {data?.initial_indicator?.growth_rate} dCategory= {t("GrowthRatio")} years= {data?.years} translation={t}/>
                                        <DisplayRatioData data= {data?.initial_indicator?.liquidity_ratio} dCategory= {t("LiqRatio")} years= {data?.years} translation={t}/>
                                        {/* <DisplayRatioData data= {data?.initial_indicator?.operating_efficiency} dCategory={"Operating Efficiency"} years={data?.years} translation={t}/> */}
                                        <DisplayRatioData data= {data?.initial_indicator?.leverage_ratio} dCategory= {t("LeverageRatio")} years= {data?.years} translation={t}/>
                                        <DisplayRatioData data= {data?.initial_indicator?.coverage_ratio} dCategory= {t("coverageRatio")} years= {data?.years} translation={t}/>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                                <div className="card text-left o-hidden">
                                    <div className="card-header d-flex align-items-center border-0 my-header">
                                        <h3 className="w-100 float-left card-title m-0 text-white">{t("FINFORCASTING.textAssumptionConsidered")}</h3>
                                    </div>
                                    <div className="row" style={{backgroundColor:"var(--tp-common-gray)"}}>
                                        {data?.assumptions.map((assumption)=>(
                                            <div className="col-xl-4 col-md-6">
                                                <div className="row" style={{margin:5}}>
                                                    <div className="col-6">{t(`ASSUMPTIONS.${assumption.code}`)}</div>
                                                    <div className="col-6">{percentageFormater(assumption.value)}</div>
                                                </div> 
                                            </div>
                                        ))}
                                    </div>
                                </div>
                        </div>
                        <div className="col-xl-8 col-md-8 mb-4">
                            <div className="card text-left o-hidden">
                                <div className="card-header d-flex align-items-center border-0 my-header">
                                    <h3 className="w-100 float-left card-title m-0 text-white">{t("FINFORCASTING.StatementBefore")}</h3>
                                </div>
                                <div className="table-responsive table-stripe">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-center">Label</th>
                                                <th scope="col" className="text-center">{data?.first_year}</th>
                                                <th scope="col" className="text-center">{data?.first_year-1}</th>
                                                {data?.years>=3 &&(<th scope="col" className="text-center">{data?.first_year-2}</th>)}
                                                {data?.years>=4 &&(<th scope="col" className="text-center">{data?.first_year-3}</th>)}
                                                {data?.years>=5 &&(<th scope="col" className="text-center">{data?.first_year-4}</th>)}
                                            </tr>
                                        </thead>
                                        <DisplayFinData data={data?.output_data.is_bs?.income} dCategory={t("Income")} years={data?.years} translation={t}/>
                                        <DisplayFinData data={data?.output_data.is_bs?.assets} dCategory={t("FIN_STATEMENT.ASSETS")} years={data?.years} translation={t}/>
                                        <DisplayFinData data={data?.output_data.is_bs?.liabilities} dCategory={t("FIN_STATEMENT.LIABILITIES")} years={data?.years} translation={t}/>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4 mb-4">
                            <div className="card text-left o-hidden">
                                <div className="card-header d-flex align-items-center border-0 my-header">
                                    <h3 className="w-100 float-left card-title m-0 text-white">{t("FINFORCASTING.CSStatement")}</h3>
                                </div>
                                <div className="table-responsive table-stripe">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                {/* <th scope="col" className="text-center">Label</th> */}
                                                <th scope="col" className="text-center">{data?.first_year}</th>
                                                <th scope="col" className="text-center">{data?.first_year-1}</th>
                                                {data?.years>=3 &&(<th scope="col" className="text-center">{data?.first_year-2}</th>)}
                                                {data?.years>=4 &&(<th scope="col" className="text-center">{data?.first_year-3}</th>)}
                                                {data?.years>=5 &&(<th scope="col" className="text-center">{data?.first_year-4}</th>)}
                                                <th scope="col" className="text-center">Average</th>
                                            </tr>
                                        </thead>
                                        <DisplayCSS data={data?.output_data.common_size_statement?.income} dCategory={t("Income")} years={data?.years} translation={t}/>
                                        <DisplayCSS data={data?.output_data.common_size_statement?.assets} dCategory={t("FIN_STATEMENT.ASSETS")} years={data?.years} translation={t}/>
                                        <DisplayCSS data={data?.output_data.common_size_statement?.liabilities} dCategory={t("FIN_STATEMENT.LIABILITIES")} years={data?.years} translation={t}/>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default FForcastingDetails;