import { curencyFormater } from "../utils/functions"

const DisplayLiability = ({liabilities, t, landscape}) => {
    return (
        <div className="card text-left o-hidden">
            <div className="card-header d-flex align-items-center border-0 my-header">
                <h3 className="w-100 float-left card-title m-0 text-white"> {t("FIN_STATEMENT.LIABILITIES")}</h3>
            </div>
            <div className="table-responsive table-stripe">
                <table className="table table-hover table-bordered">
                    <thead className="" style={{backgroundColor:"var(--tp-common-gray)"}}>
                        <tr>
                            <th scope="col">{landscape?(<>{t("OTHERCRRINFO.REF")}</>):(<>{t("FIN_STATEMENT.LABEL")}</>)}</th>
                            <th scope="col" style={{textAlign:"right"}}>Net</th>
                            <th scope="col" style={{textAlign:"right"}}>Net2</th>
                            <th scope="col" style={{textAlign:"right"}}>Net3</th>
                        </tr>
                    </thead>
                    {liabilities?.map((value)=>(
                        <tbody key={value.reference}>
                            {value.rubriques?.map((rub)=>
                                (<>
                                    {rub.posts?.map((post)=>(
                                        <tr key={post.reference}>
                                            {/* {landScape &&<td>{post.reference}</td>} */}
                                            <td >{landscape?(<>{post.reference}</>):(<>{t(`LIABILITIES.${post.reference}`)}</>)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(post.net?post.net:0)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(post.net1?post.net1:0)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(post.net2?post.net2:0)}</td>
                                        </tr>
                                    ))}
                                    {rub.reference==="DQ" && (
                                    <tr style={{backgroundColor:"inherit", color:"white"}}>
                                        {/* {landScape &&<td ></td>} */}
                                        <td >5000000000000</td>
                                        <td ></td>
                                        <td ></td>
                                        <td ></td>
                                    </tr>

                                    )}
                                    <tr key={rub.reference} style={{backgroundColor:rub.posts?.length!==0?"var(--tp-common-gray)":"inherit", color:rub.posts?.length!==0?"black":"inherit"}}>
                                    {/* {landScape &&<td >{rub.reference}</td>} */}
                                        <td >{landscape?(<>{rub.reference}</>):(<>{t(`LIABILITIES.${rub.reference}`)}</>)}</td>
                                        <td style={{textAlign:"right"}}>{curencyFormater(rub.net?rub.net:0)}</td>
                                        <td style={{textAlign:"right"}}>{curencyFormater(rub.net1?rub.net1:0)}</td>
                                        <td style={{textAlign:"right"}}>{curencyFormater(rub.net2?rub.net2:0)}</td>
                                    </tr>
                                </>)
                            )}
                            <tr key={value.reference} style={{textTransform:value.rubriques?.length!==0 || value.reference==="DZ"?"uppercase":"inherit",backgroundColor:value.rubriques?.length!==0 || value.reference==="DZ"?"#8da9c4":"inherit", color:value.rubriques?.length!==0 || value.reference==="DZ"?"white":"inherit"}}>
                            {/* {landScape &&<td >{value.reference}</td>} */}
                                <td >{landscape?(<>{value.reference}</>):(<>{t(`LIABILITIES.${value.reference}`)}</>)}</td>
                                <td style={{textAlign:"right"}}>{curencyFormater(value.net?value.net:0)}</td>
                                <td style={{textAlign:"right"}}>{curencyFormater(value.net1?value.net1:0)}</td>
                                <td style={{textAlign:"right"}}>{curencyFormater(value.net2?value.net2:0)}</td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>

    )
}

export default DisplayLiability