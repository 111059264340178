import { Link } from "react-router-dom";
import BreadCrumb from "../../../components/breadcrumb";
import MPieChart from "../../../components/chart/PieChart";
import MBarChart from "../../../components/chart/BarChart";
import CountUp from "react-countup";
import { CRRAPI, USERAPI } from "../../../services/axios";
import useSWR from "swr";
import { URLS } from "../../../services/urls";
import LoaderPage from "../../../components/loader";
import { isCompany } from "../../../components/utils/functions";
import AreaChart from "../../../components/chart/AreaChart";
const fetcher = (url) => USERAPI.get(url).then(res => {return res.data});
const fetcher2 = (url) => CRRAPI.get(url).then(res => {return res.data})

const DashBoardPage = () =>{ 
    const {data: userData, isLoading} = useSWR(URLS.USERS.list(1), fetcher) ;
    const {data: ratings, isLoading2} = useSWR(URLS.CRR.list(1), fetcher2);
    const data2=[
        {
        value: 535,
        name: "Vince",
        },
        {
        value: 610,
        name: "Rone",
        },
        {
        value: 734,
        name: "Nday",
        },
    ]
    return (
        <>
        {(isLoading || isLoading2)?(<LoaderPage/>):(
            <div className="main-content">
                    <BreadCrumb title1="Dashboard" title2="Home"/>
                    {/* <div className="separator-breadcrumb border-top"></div> */}
                    <div className="row">
                        {/* <!-- ICON BG--> */}
                        {isCompany() &&
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                                <div className="card-body text-center"><i className="i-Add-User"></i>
                                    <div className="content">
                                        <p className="text-muted mt-2 mb-0 text-12">Users</p>
                                        <p className="text-primary text-24 line-height-1 mb-2"><CountUp start={0} delay={1} end={userData?.total}/></p>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div className={isCompany()?"col-lg-3 col-md-6 col-sm-6":"col-lg-4 col-md-6 col-sm-6"}>
                            <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                                <div className="card-body text-center"><i className="i-Financial"></i>
                                    <div className="content">
                                        <p className="text-muted mt-2 mb-0 text-12">CR<sup>2</sup>-SME</p>
                                        <p className="text-primary text-24 line-height-1 mb-2"><CountUp start={0} delay={1} end={ratings?.count}/></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={isCompany()?"col-lg-3 col-md-6 col-sm-6":"col-lg-4 col-md-6 col-sm-6"}>
                            <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                                <div className="card-body text-center"><i className="i-Money-2"></i>
                                    <div className="content">
                                        <p className="text-muted mt-2 mb-0 text-12">SME-CB</p>
                                        <p className="text-primary text-24 line-height-1 mb-2"><CountUp start={0} delay={1} end={0}/></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={isCompany()?"col-lg-3 col-md-6 col-sm-6":"col-lg-4 col-md-6 col-sm-6"}>
                            <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                                <div className="card-body text-center"><i className="i-Checkout-Basket"></i>
                                    <div className="content">
                                        <p className="text-muted mt-2 mb-0 text-12">SME-BA</p>
                                        <p className="text-primary text-24 line-height-1 mb-2"><CountUp start={0} delay={1} end={0}/></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card o-hidden mb-4">
                                        <div className="card-header d-flex align-items-center border-0 my-header">
                                            <h3 className="w-50 float-left card-title m-0 text-white">Latest Transactions</h3>
                                        </div>
                                        <div>
                                            <div className="table-responsive">
                                                <table className="table text-center" id="user_table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Score</th>
                                                            <th scope="col">Identifier</th>
                                                            <th scope="col">Indicator</th>
                                                            <th scope="col">Year</th>
                                                            <th scope="col">Date</th>
                                                            <th scope="col">Details</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {ratings?.results?.map((rating)=>(
                                                            <tr key={rating.id}>
                                                                <td><span className="badge badge-round-info">{rating.grade}</span></td>
                                                                <th scope="row">{rating.reference}</th>
                                                                <td>{rating.indicator}</td>
                                                                <td>{rating.year}</td>
                                                                <td>{(new Date(rating?.created_at)).toLocaleString()}</td>
                                                                <td>
                                                                    <Link className="text-success mr-2" to={`/sme-crr/details/${rating.id}`}><i className="nav-icon i-Eye font-weight-bold"></i></Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {(isCompany() && userData?.results?.length>0) &&
                                    <div className="card o-hidden mb-4">
                                        <div className="card-header d-flex align-items-center border-0 my-header">
                                            <h3 className="w-50 float-left card-title m-0 text-white">New Users</h3>
                                            <div className="dropdown dropleft text-right w-50 float-right">
                                                <button className="btn " style={{background:"none"}}id="dropdownMenuButton1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="nav-icon i-Gear-2"></i></button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <Link className="dropdown-item" to="#">Add new user</Link>
                                                    <Link className="dropdown-item" to="group-members">View All users</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="table-responsive">
                                                <table className="table text-center" id="user_table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Avatar</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Last Login</th>
                                                            <th scope="col">Details</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {userData?.results?.map((user, index)=>(
                                                        <tr key={user.id}>
                                                            <td><img className="rounded-circle m-0 avatar-sm-table" src={user.picture} alt="" /></td>
                                                            <td>{user.first_name} {user.last_name}</td>
                                                            <td>{user.email}</td>
                                                            <td><span className="">{(new Date(user.last_login)).toLocaleString()}</span></td>
                                                            <td>
                                                                <Link className="text-success mr-2" to="#"><i className="nav-icon i-Eye font-weight-bold"></i></Link>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                <div className="col-12">
                                    <div className="card text-left o-hidden mb-4">
                                        <h5 className="accordion-header" id="headingOne">
                                            <div className="card-header d-flex align-items-center border-0 my-header">
                                            Transaction Breakdown <span className="float-right"><button><i className="nav-icon i-coffee font-weight-bold"></i></button></span>
                                            </div>
                                        </h5>
                                        <div className="card-body">
                                            <MPieChart data={[
                                                    {
                                                    value: ratings?.count??0,
                                                    name: "SME-CRR",
                                                    },
                                                    {
                                                    value: 1,
                                                    name: "SME-CB",
                                                    },
                                                    {
                                                    value: 1,
                                                    name: "SME-BA",
                                                    },
                                                ]}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="card text-left o-hidden mb-4">
                                <h5 className="accordion-header" id="headingOne">
                                    <div className="card-header d-flex align-items-center border-0 my-header">
                                    Activities <span className="float-right"><button><i className="nav-icon i-coffee font-weight-bold"></i></button></span>
                                    </div>
                                </h5>
                                <div className="card-body">
                                    <MPieChart data={[
                                            {
                                            value: ratings?.count??0,
                                            name: "SME-CRR",
                                            },
                                            {
                                            value: 1,
                                            name: "SME-CB",
                                            },
                                            {
                                            value: 1,
                                            name: "SME-BA",
                                            },
                                        ]}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                            <div className="card text-left o-hidden mb-4">
                                <h5 className="accordion-header" id="headingOne">
                                    <div className="card-header d-flex align-items-center border-0 my-header">
                                        Expenses
                                    </div>
                                </h5>
                                <div className="card-body ul-card__widget-chart">
                                    <AreaChart data={data2}/>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )}
        </>
    )
}

export default DashBoardPage;