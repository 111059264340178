
const styles = {
    input: (baseStyles, state) => ({
      ...baseStyles,
      height: "50px",
      // width:"500px",
      margin:"-10"
    }),
    // placeholder: (baseStyles, state) => ({
    //   ...baseStyles,
    //   height: state.isFocused ? "15px" : '12px',
    //   display: 'country'
    // })
  }

const inLineSelectStyles = {
  input: (baseStyles, state) => ({
    ...baseStyles,
    height: "35px",
    padding:"auto",
    textAlign:"right"
    // width:"500px",
    // margin:"-10"
  }),
  // placeholder: (baseStyles, state) => ({
  //   textAlign:"right"
  // })
}
const inLineSelectStyles2 = {
  input: (baseStyles, state) => ({
    ...baseStyles,
    height: "25px",
    paddingTop:"10",
    textAlign:"right"
  })
}
export {styles, inLineSelectStyles, inLineSelectStyles2}