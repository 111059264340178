import { useEffect } from "react";
import { MASTERCARD_LOGO, VERVE_LOGO } from "../../components/utils/consts";

const CardComponent = ({creds}) => {
    let image;
    console.log(creds.card_type)
    if(creds.card_type==="VERVE"){
        image = VERVE_LOGO
    }
    console.log(image)
    // useEffect(()=>{
    //     if(creds.type==="VERVE"){
    //         image = VERVE_LOGO
    //     }
    // },[])
    return (<div className="ul-widget4__item ul-widget4__users" style={{paddingTop: ".8em", paddingBottom:".8em"}}>
                <div className="ul-widget4__img"><img id="userDropdown" src={image} alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" /></div>
                <div className="ul-widget2__info ul-widget4__users-info">
                    <span className="ul-widget2__title">{creds.card_first_digits}**********{creds.card_last_digits} ({creds.card_expiry})</span>
                    <span className="ul-widget2__username">{creds.card_issuer}</span>
                </div>
                <span class="ul-widget4__number t-font-boldest text-success">
                    {creds.active? (
                        <span className="badge badge-success m-2">Active</span>
                    ):(
                        <span className="badge badge-danger m-2">Not active</span>
                    )}
                </span>
            </div>)
}

export default CardComponent;