import _ from "lodash";
import {URLS} from "../../services/urls"

let ls = {}

ls.set = window.localStorage.setItem.bind(localStorage);
ls.get = window.localStorage.getItem.bind(localStorage);

const getUserDetails = () => JSON.parse( window.localStorage.getItem("user-details"));
const setUserDetails = (details) => window.localStorage.setItem("user-details", JSON.stringify(details));
const clearUserDetails = () => window.localStorage.removeItem("user-details");
const setUserSubscribtions = (subs) => window.localStorage.setItem("subscribtions", JSON.stringify(subs));
const getUserSubscribtions = () => JSON.parse(window.localStorage.getItem("subscribtions"))??[];
const getLanguage = () => window.localStorage.getItem("lang");

// does account belongs to a company?
const isSubAccount = (user = null ) =>{return ["analyst"].includes(user? user?.role: getUserDetails()?.user?.role) && (getUserDetails()?.user?.parent_account!==null || getUserDetails()?.user?.parent_account!==undefined);}

// is it an individuals account?
const isIndividual = (user = null) => 
{return ["individual"].includes(user? user?.role: getUserDetails()?.user?.role) && (getUserDetails()?.user?.parent_account==null || getUserDetails()?.user?.parent_account===undefined);}


// is it an consulting firm account?
const isConsultingFirm = (user = null) => {return ["enterprise"].includes(user?user?.role: getUserDetails()?.user?.role) && ["consulting_firm"].includes(getUserDetails()?.user?.sub_category)}

// is it another type of firm (not consulting, bank or microfinance)
const isOtherFirm = (user = null) => {return ["enterprise"].includes(user?user?.role: getUserDetails()?.user?.role) && ["other_firm"].includes(getUserDetails()?.user?.sub_category)}

// is it a bank's account
const isBank = (user = null) => {return ["bank"].includes(user? user?.sub_category: getUserDetails()?.user?.sub_category) && ['financial_inst'].includes(getUserDetails()?.user.role)}

// is it a bank's account
const isMicrofinance = (user = null) => {return ["micro_finance"].includes(user? user?.sub_category: getUserDetails()?.user?.sub_category) && ['financial_inst'].includes(getUserDetails()?.user.role)}

// is it a bank's account
const isOtherFinancialFirm = (user = null) => {return ["other_fin"].includes(user? user?.sub_category: getUserDetails()?.user?.sub_category) && ['financial_inst'].includes(getUserDetails()?.user.role)}

// is it a company's account
const isCompany = (user = null) => {return !isSubAccount() && (isConsultingFirm() || isOtherFinancialFirm() || isBank() || isMicrofinance() || isOtherFirm())}

// is account activated
const isActivatedAcc = (user = null) => {console.log(); return getUserDetails()?.user?.activated}
const appScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
}

const formatLinkString = (link) => {
  if(link?.includes("https://") || link?.includes("http://")){
    return link;
  } else {
    return URLS.AUHT_URL + link;
  }
}

const validatePhoneNumber = (
  inputNumber,
  country,
  isDirty,
  phoneLength
) => {
  if (isDirty) {
    if (
      inputNumber &&
      inputNumber?.replace(country.dialCode, '')?.trim() === ''
    ) {
      return false;
    } else if (inputNumber.length < phoneLength) {
      return false;
    }
    return true;
  }
  return false;
};

const getQuantity = (category, searchResults, defaultValue, canEvaluate) => {

  if(canEvaluate){

    return searchResults.filter((item) => item.industry.id === category.id).length

  } else {

    return defaultValue;
  }

}

// const convetToCurrency=(e) => {
//   let value = e.target.value.replace(/,/g, '');
//   value = parseFloat(value).toLocaleString('en-US', {
//     style: 'decimal',
//     maximumFractionDigits: 2,
//     minimumFractionDigits: 2
//   });
//   return value;
// };
const percentageFormater = (number)=>{
  const lang = getLanguage()
  const formater = new Intl.NumberFormat(lang,{
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return number>1?formater.format(number/100):formater.format(number);
}
const curencyFormater =(amount) =>{ 
  // const lang = getLanguage();
  const lang = getLanguage();
  const formater = new Intl.NumberFormat(
      lang, // this should be taken from the system, en-US of fr-FR
      {
          style: 'currency',
          currency: 'XAF', //USD pour $ et EUR pour euro, XAF pour FCFA
      }
  )
  return formater.format(amount);
}
const getMasse=(masses)=>{
  const assets_=[];
  const liabilities_ = [];
  const incomeStatement_ = [];
  const cashflow_ = [];
  const footNote_ = []
  masses.forEach(element => {
  if(element['category']==='asset'){
    assets_.push(element)
  }
  if(element['category']==="liability"){
    liabilities_.push(element)
  }
  if(element['category']==="income statement"){
    incomeStatement_.push(element)
  }
  if(element['category']==="cashflow"){
    cashflow_.push(element)
  }
  if(element['category']==="footnote table"){
    footNote_.push(element)
  }
  });
  return {
    "assets":assets_,
    "liabilities":liabilities_,
    "income":incomeStatement_,
    "cashflow":cashflow_
  }
}

const listToMasses3=(assets,liabilities, income,cashflow,dataList)=>{
  const assets_=assets;
  const liabilities_ = liabilities;
  const incomeStatement_ = income;
  const cashflow_ = cashflow;
  const length = dataList['income_statement'][0].length
  const ass_length = dataList['assets'][0].length

  for(let i=0; i<incomeStatement_.length; i++){
    for(let j=0; j<dataList['income_statement'].length; j++){
      if(incomeStatement_[i].reference.trim()===dataList['income_statement'][j][0].trim()){
        if(length===7){incomeStatement_[i].net5= dataList['income_statement'][j][length-6];}
        if(length===6){incomeStatement_[i].net4= dataList['income_statement'][j][length-5];}
        if(length===5){incomeStatement_[i].net3= dataList['income_statement'][j][length-4];}
        incomeStatement_[i].net2=dataList['income_statement'][j][length-3];
        incomeStatement_[i].net1=dataList['income_statement'][j][length-2];
        incomeStatement_[i].net=dataList['income_statement'][j][length-1];
      }else{
        for(let k=0; k<incomeStatement_[i].rubriques?.length;k++){
          if(incomeStatement_[i].rubriques[k].reference.trim()===dataList['income_statement'][j][0].trim()){
            if(length===7){incomeStatement_[i].rubriques[k].net5=dataList['income_statement'][j][length-6];}
            if(length===6){incomeStatement_[i].rubriques[k].net4=dataList['income_statement'][j][length-5];}
            if(length===5){incomeStatement_[i].rubriques[k].net3=dataList['income_statement'][j][length-4];}
            incomeStatement_[i].rubriques[k].net2=dataList['income_statement'][j][length-3];
            incomeStatement_[i].rubriques[k].net1=dataList['income_statement'][j][length-2];
            incomeStatement_[i].rubriques[k].net=dataList['income_statement'][j][length-1];
          }
          else{
            for(let l=0; l<incomeStatement_[i].rubriques[k].posts?.length; l++){
              if(incomeStatement_[i].rubriques[k].posts[l].reference.trim()===dataList['income_statement'][j][0].trim()){
                if(length===7){incomeStatement_[i].rubriques[k].posts[l].net5= dataList['income_statement'][j][length-6];}
                if(length===6){incomeStatement_[i].rubriques[k].posts[l].net4= dataList['income_statement'][j][length-5];}
                if(length===5){incomeStatement_[i].rubriques[k].posts[l].net3= dataList['income_statement'][j][length-4];}
                incomeStatement_[i].rubriques[k].posts[l].net2=dataList['income_statement'][j][length-3];
                incomeStatement_[i].rubriques[k].posts[l].net1=dataList['income_statement'][j][length-2];
                incomeStatement_[i].rubriques[k].posts[l].net=dataList['income_statement'][j][length-1];
              }
            }
          }
        }
      }
    }
  }

  for(let i=0; i<cashflow_.length; i++){
    for(let j=0; j<dataList['cashflow'].length; j++){
      if(cashflow_[i].reference.trim()===dataList['cashflow'][j][0].trim()){
        if(length===7){cashflow_[i].net5=dataList['cashflow'][j][length-6];}
        if(length===6){cashflow_[i].net4=dataList['cashflow'][j][length-5];}
        if(length===5){cashflow_[i].net3=dataList['cashflow'][j][length-4];}
        cashflow_[i].net2=dataList['cashflow'][j][length-3];
        cashflow_[i].net1=dataList['cashflow'][j][length-2];
        cashflow_[i].net=dataList['cashflow'][j][length-1];
      }else{
        for(let k=0; k<cashflow_[i].rubriques.length;k++){
          if(cashflow_[i].rubriques[k].reference.trim()===dataList['cashflow'][j][0].trim()){
            if(length===7){cashflow_[i].rubriques[k].net5=dataList['cashflow'][j][length-6];}
            if(length===6){cashflow_[i].rubriques[k].net4=dataList['cashflow'][j][length-5];}
            if(length===5){cashflow_[i].rubriques[k].net3=dataList['cashflow'][j][length-4];}
            cashflow_[i].rubriques[k].net2=dataList['cashflow'][j][length-3];
            cashflow_[i].rubriques[k].net1=dataList['cashflow'][j][length-2];
            cashflow_[i].rubriques[k].net=dataList['cashflow'][j][length-1];
          }
          else{
            for(let l=0; l<cashflow_[i].rubriques[k].posts?.length; l++){
              if(cashflow_[i].rubriques[k].posts[l].reference.trim()===dataList['cashflow'][j][0].trim()){
                if(length===7){cashflow_[i].rubriques[k].posts[l].net5=dataList['cashflow'][j][length-6];}
                if(length===6){cashflow_[i].rubriques[k].posts[l].net4=dataList['cashflow'][j][length-5];}
                if(length===5){cashflow_[i].rubriques[k].posts[l].net3=dataList['cashflow'][j][length-4];}
                cashflow_[i].rubriques[k].posts[l].net2=dataList['cashflow'][j][length-3];
                cashflow_[i].rubriques[k].posts[l].net1=dataList['cashflow'][j][length-2];
                cashflow_[i].rubriques[k].posts[l].net=dataList['cashflow'][j][length-1];
              }
            }
          }
        }
      }
    }
  }

  for(let i=0; i<liabilities_.length; i++){
    for(let j=0; j<dataList['liabilities'].length; j++){
      if(liabilities_[i].reference.trim()===dataList['liabilities'][j][0].trim()){
        if(length===7){liabilities_[i].net5=dataList['liabilities'][j][length-6];}
        if(length===6){liabilities_[i].net4=dataList['liabilities'][j][length-5];}
        if(length===5){liabilities_[i].net3=dataList['liabilities'][j][length-4];}
        liabilities_[i].net2=dataList['liabilities'][j][length-3];
        liabilities_[i].net1=dataList['liabilities'][j][length-2];
        liabilities_[i].net=dataList['liabilities'][j][length-1];
      }else{
        for(let k=0; k<liabilities_[i].rubriques.length;k++){
          if(liabilities_[i].rubriques[k].reference.trim()===dataList['liabilities'][j][0].trim()){
            if(length===7){liabilities_[i].rubriques[k].net5=dataList['liabilities'][j][length-6]};
            if(length===6){liabilities_[i].rubriques[k].net4=dataList['liabilities'][j][length-5]};
            if(length===5){liabilities_[i].rubriques[k].net3=dataList['liabilities'][j][length-4]};
            liabilities_[i].rubriques[k].net2=dataList['liabilities'][j][length-3];
            liabilities_[i].rubriques[k].net1=dataList['liabilities'][j][length-2];
            liabilities_[i].rubriques[k].net=dataList['liabilities'][j][length-1];
          }
          else{
            for(let l=0; l<liabilities_[i].rubriques[k].posts?.length; l++){
              if(liabilities_[i].rubriques[k].posts[l].reference.trim()===dataList['liabilities'][j][0].trim()){
                if(length===7){liabilities_[i].rubriques[k].posts[l].net5=dataList['liabilities'][j][length-6];}
                if(length===6){liabilities_[i].rubriques[k].posts[l].net4=dataList['liabilities'][j][length-5];}
                if(length===5){liabilities_[i].rubriques[k].posts[l].net3=dataList['liabilities'][j][length-4];}
                liabilities_[i].rubriques[k].posts[l].net2=dataList['liabilities'][j][length-3];
                liabilities_[i].rubriques[k].posts[l].net1=dataList['liabilities'][j][length-2];
                liabilities_[i].rubriques[k].posts[l].net=dataList['liabilities'][j][length-1];
              }
            }
          }
        }
      }
    }
  }

  for(let i=0; i<assets_.length; i++){
    // console.log(assets_[i].reference.trim())
    for(let j=0; j<dataList['assets'].length; j++){
      // console.log(dataList['assets'][j][0].trim())
      if(assets_[i].reference.trim()===dataList['assets'][j][0].trim()){
        if(ass_length===17){
          assets_[i].net5=dataList['assets'][j][ass_length-16];

          assets_[i].brut4=dataList['assets'][j][ass_length-15];
          assets_[i].amort_deprec4=dataList['assets'][j][ass_length-14]
          assets_[i].net4=dataList['assets'][j][ass_length-13];

          assets_[i].brut3=dataList['assets'][j][ass_length-12];
          assets_[i].amort_deprec3=dataList['assets'][j][ass_length-11]
          assets_[i].net3=dataList['assets'][j][ass_length-10];

          assets_[i].brut2=dataList['assets'][j][ass_length-9];
          assets_[i].amort_deprec2=dataList['assets'][j][ass_length-8]
          assets_[i].net2=dataList['assets'][j][ass_length-7];
        }
        if(ass_length===14){
          assets_[i].net4=dataList['assets'][j][ass_length-13];

          assets_[i].brut3=dataList['assets'][j][ass_length-12];
          assets_[i].amort_deprec3=dataList['assets'][j][ass_length-11]
          assets_[i].net3=dataList['assets'][j][ass_length-10];

          assets_[i].brut2=dataList['assets'][j][ass_length-9];
          assets_[i].amort_deprec2=dataList['assets'][j][ass_length-8]
          assets_[i].net2=dataList['assets'][j][ass_length-7];
        }
        if(ass_length===11){
          assets_[i].net3=dataList['assets'][j][ass_length-10];

          assets_[i].brut2=dataList['assets'][j][ass_length-9];
          assets_[i].amort_deprec2=dataList['assets'][j][ass_length-8]
          assets_[i].net2=dataList['assets'][j][ass_length-7];
        }
        if(ass_length===8){
          // forcasting ins done on two years
          assets_[i].net2=dataList['assets'][j][ass_length-7];
        }

        assets_[i].brut1=dataList['assets'][j][ass_length-6];
        assets_[i].amort_deprec1=dataList['assets'][j][ass_length-5]
        assets_[i].net1=dataList['assets'][j][ass_length-4];

        assets_[i].brut=dataList['assets'][j][ass_length-3];
        assets_[i].amort_deprec=dataList['assets'][j][ass_length-2]
        assets_[i].net=dataList['assets'][j][ass_length-1];
      }else{
        for(let k=0; k<assets_[i].rubriques.length;k++){
          if(assets_[i].rubriques[k].reference.trim()===dataList['assets'][j][0].trim()){
            // assets_[i].rubriques[k].net3=dataList['assets'][j][1];

            // assets_[i].rubriques[k].brut2=dataList['assets'][j][2];
            // assets_[i].rubriques[k].amort_deprec2=dataList['assets'][j][3]
            // assets_[i].rubriques[k].net2=dataList['assets'][j][4];
            if(ass_length===17){
              assets_[i].rubriques[k].net5=dataList['assets'][j][ass_length-16];
    
              assets_[i].rubriques[k].brut4=dataList['assets'][j][ass_length-15];
              assets_[i].rubriques[k].amort_deprec4=dataList['assets'][j][ass_length-14]
              assets_[i].rubriques[k].net4=dataList['assets'][j][ass_length-13];
    
              assets_[i].rubriques[k].brut3=dataList['assets'][j][ass_length-12];
              assets_[i].rubriques[k].amort_deprec3=dataList['assets'][j][ass_length-11]
              assets_[i].rubriques[k].net3=dataList['assets'][j][ass_length-10];
    
              assets_[i].rubriques[k].brut2=dataList['assets'][j][ass_length-9];
              assets_[i].rubriques[k].amort_deprec2=dataList['assets'][j][ass_length-8]
              assets_[i].rubriques[k].net2=dataList['assets'][j][ass_length-7];
            }

            if(ass_length===14){
              assets_[i].rubriques[k].net4=dataList['assets'][j][ass_length-13];
    
              assets_[i].rubriques[k].brut3=dataList['assets'][j][ass_length-12];
              assets_[i].rubriques[k].amort_deprec3=dataList['assets'][j][ass_length-11]
              assets_[i].rubriques[k].net3=dataList['assets'][j][ass_length-10];
    
              assets_[i].rubriques[k].brut2=dataList['assets'][j][ass_length-9];
              assets_[i].rubriques[k].amort_deprec2=dataList['assets'][j][ass_length-8]
              assets_[i].rubriques[k].net2=dataList['assets'][j][ass_length-7];
            }

            if(ass_length===11){
              assets_[i].rubriques[k].net3=dataList['assets'][j][ass_length-10];
    
              assets_[i].rubriques[k].brut2=dataList['assets'][j][ass_length-9];
              assets_[i].rubriques[k].amort_deprec2=dataList['assets'][j][ass_length-8]
              assets_[i].rubriques[k].net2=dataList['assets'][j][ass_length-7];
            }
            if(ass_length===8){
              // forcasting ins done on two years
              assets_[i].rubriques[k].net2=dataList['assets'][j][ass_length-7];
            }

            assets_[i].rubriques[k].brut1=dataList['assets'][j][ass_length-6];
            assets_[i].rubriques[k].amort_deprec1=dataList['assets'][j][ass_length-5]
            assets_[i].rubriques[k].net1=dataList['assets'][j][ass_length-4];

            assets_[i].rubriques[k].brut=dataList['assets'][j][ass_length-3];
            assets_[i].rubriques[k].amort_deprec=dataList['assets'][j][ass_length-2]
            assets_[i].rubriques[k].net=dataList['assets'][j][ass_length-1];
          }
          else{
            for(let l=0; l<assets_[i].rubriques[k].posts?.length; l++){
              if(assets_[i].rubriques[k].posts[l].reference.trim()===dataList['assets'][j][0].trim()){
                // assets_[i].rubriques[k].posts[l].net3=dataList['assets'][j][1];
    
                // assets_[i].rubriques[k].posts[l].brut2=dataList['assets'][j][2];
                // assets_[i].rubriques[k].posts[l].amort_deprec2=dataList['assets'][j][3]
                // assets_[i].rubriques[k].posts[l].net2=dataList['assets'][j][4];

                if(ass_length===17){
                  assets_[i].rubriques[k].posts[l].net5=dataList['assets'][j][ass_length-16];
        
                  assets_[i].rubriques[k].posts[l].brut4=dataList['assets'][j][ass_length-15];
                  assets_[i].rubriques[k].posts[l].amort_deprec4=dataList['assets'][j][ass_length-14]
                  assets_[i].rubriques[k].posts[l].net4=dataList['assets'][j][ass_length-13];
        
                  assets_[i].rubriques[k].posts[l].brut3=dataList['assets'][j][ass_length-12];
                  assets_[i].rubriques[k].posts[l].amort_deprec3=dataList['assets'][j][ass_length-11]
                  assets_[i].rubriques[k].posts[l].net3=dataList['assets'][j][ass_length-10];
        
                  assets_[i].rubriques[k].posts[l].brut2=dataList['assets'][j][ass_length-9];
                  assets_[i].rubriques[k].posts[l].amort_deprec2=dataList['assets'][j][ass_length-8]
                  assets_[i].rubriques[k].posts[l].net2=dataList['assets'][j][ass_length-7];
                }

                if(ass_length===14){
                  assets_[i].rubriques[k].posts[l].net4=dataList['assets'][j][ass_length-13];
        
                  assets_[i].rubriques[k].posts[l].brut3=dataList['assets'][j][ass_length-12];
                  assets_[i].rubriques[k].posts[l].amort_deprec3=dataList['assets'][j][ass_length-11]
                  assets_[i].rubriques[k].posts[l].net3=dataList['assets'][j][ass_length-10];
        
                  assets_[i].rubriques[k].posts[l].brut2=dataList['assets'][j][ass_length-9];
                  assets_[i].rubriques[k].posts[l].amort_deprec2=dataList['assets'][j][ass_length-8]
                  assets_[i].rubriques[k].posts[l].net2=dataList['assets'][j][ass_length-7];
                }

                if(ass_length===11){
                  assets_[i].rubriques[k].posts[l].net3=dataList['assets'][j][ass_length-10];
        
                  assets_[i].rubriques[k].posts[l].brut2=dataList['assets'][j][ass_length-9];
                  assets_[i].rubriques[k].posts[l].amort_deprec2=dataList['assets'][j][ass_length-8]
                  assets_[i].rubriques[k].posts[l].net2=dataList['assets'][j][ass_length-7];
                }

                if(ass_length===8){
                  // forcasting ins done on two years
                  assets_[i].rubriques[k].posts[l].net2=dataList['assets'][j][ass_length-7];
                }
    
                assets_[i].rubriques[k].posts[l].brut1=dataList['assets'][j][ass_length-6];
                assets_[i].rubriques[k].posts[l].amort_deprec1=dataList['assets'][j][ass_length-5]
                assets_[i].rubriques[k].posts[l].net1=dataList['assets'][j][ass_length-4];
    
                assets_[i].rubriques[k].posts[l].brut=dataList['assets'][j][ass_length-3];
                assets_[i].rubriques[k].posts[l].amort_deprec=dataList['assets'][j][ass_length-2]
                assets_[i].rubriques[k].posts[l].net=dataList['assets'][j][ass_length-1];
              }
            }
          }
        }
      }
    }
  }
  console.log(assets_)
  return {
    "assets":assets_,
    "liabilities":liabilities_,
    "income":incomeStatement_,
    "cashflow":cashflow_
  }
}
const mapNumToMonth=(month)=>{
  const mnames = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"];
  return mnames[month];
}

export {
  ls, 
  setUserDetails, 
  getUserDetails, 
  clearUserDetails,
  appScrollTo, 
  formatLinkString,
  validatePhoneNumber,
  getQuantity,
  // convetToCurrency,
  isIndividual,
  isConsultingFirm,
  isOtherFinancialFirm,
  isOtherFirm,
  isBank,
  isCompany,
  isMicrofinance,
  isSubAccount,
  isActivatedAcc,
  setUserSubscribtions,
  curencyFormater,
  getUserSubscribtions,
  percentageFormater,
  getLanguage,
  getMasse,
  listToMasses3,
  mapNumToMonth
}

