import { useEffect, useMemo, useState } from "react";
import axios from 'axios'
import BreadCrumb from "../../components/breadcrumb"
import useSWR from "swr";
import { URLS } from "../../services/urls";
import LoaderPage from "../../components/loader";
import { AUTHAPI, USERAPI } from "../../services/axios";
import { Link } from "react-router-dom";

const fetcher = (url) => USERAPI.get(url).then(res => res.data);

const MembersList = () => {
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const {data: partners, isLoading, mutate} = useSWR(URLS.USERS.list(page), fetcher);
    const usersList = partners?.results || [];
    const [filteredUsers, setFilteredUsers] = useState([])
    useEffect(()=>{
        setFilteredUsers(usersList)
    },[])


    return (
        <>
            {isLoading?(<LoaderPage/>):
            <>
                <BreadCrumb title1="Members Accounts" title2="List"/>
                <div className="row mb-4">
                    <div className="col-md-12 mb-3">
                        <div className="card text-left mb-4 o-hidden">
                            <div className="card-header d-flex align-items-center border-0 my-header">
                                <h3 className="w-50 float-left card-title m-0 text-white">Rating list</h3>

                            </div>
                            <div className="">
                                <div className="table-responsive">
                                    <table className="table table-hover" id="alternative_pagination_table" style={{width:"100%"}}>
                                        <thead className="">
                                            <tr>
                                                <th scope="col">First name</th>
                                                <th scope="col">Last name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone Number</th>
                                                <th scope="col">Gender</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {partners?.results?.map((user)=>(
                                                <tr key={user.id}>
                                                    <td>{user.first_name}</td>
                                                    <td>{user.last_name}</td>
                                                    <td>{user.email}</td>
                                                    <td>{user.phone_number}</td>
                                                    <td>{user.gender}</td>
                                                    <td style={{alignContent:"right"}}>
                                                        <Link to={`/group-members/details/${user.id}`}><button className="btn btn-primary" type="button" style={{marginRight:10}}><i className="nav-icon i-Eye"></i></button></Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    )
}
export default MembersList