import { Link } from "react-router-dom"

const BreadCrumb = ({title1, title2, title3, link}) => {
    return (
        <>
            <div className="breadcrumb">
                <h1 className="mr-2"
                // style={{background:"linear-gradient(90deg, #004D6E 0%, #00ACCC 100%)", WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}
                >
                    {title1==="CRR-SME"?(
                        <>
                            CR<sup>2</sup>-SME
                        </>
                    ):(title1)
                    }
                    </h1>
                <ul>
                    {title2 && <li><Link to={link} style={{background:"linear-gradient(90deg, #004D6E 0%, #00ACCC 100%)", WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>{title2}</Link></li>}
                    {title3 && <li style={{background:"linear-gradient(90deg, #004D6E 0%, #00ACCC 100%)", WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>{title3}</li>}
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
        </>
    );
}

export default BreadCrumb;