import { useLocation, useNavigate, Navigate } from "react-router";
import { getUserDetails } from "../../components/utils/functions";


const PrivateRoute = (props) => {

    const { children } = props;
    const user = getUserDetails();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    if (!user) {
        navigate(`/account/login?redirect=${pathname}`)
    }


    return user ? children : <Navigate to="/account/login"/>
}

    export default PrivateRoute;