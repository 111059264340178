import Select from "react-select";
import { inLineSelectStyles} from "../../../components/utils/reactSelect";
const FootNoteForm = ({footNote, setFootNoteValues, t}) => {
    return (
        <div className="row">
            <div className="col-xl-6 mb-5">
                <div className="card text-left o-hidden">
                    <div className="card-header d-flex align-items-center border-0 my-header">
                        <h3 className="w-100 float-left card-title m-0 text-white"> {t("FIN_STATEMENT.FOOT_NOTE")}</h3>
                    </div>
                    <div className="table-responsive table-strip">
                        <table className="table table-hover table-bordered">
                        <thead style={{backgroundColor:"var(--tp-common-gray)"}}>
                            <tr>
                                <th scope="col">{t("FIN_STATEMENT.LABEL")}</th>
                                <th scope="col" style={{textAlign:"right", paddingRight:30}}>Net</th>
                                <th scope="col" style={{textAlign:"right", paddingRight:30}}>Net2</th>
                                <th scope="col" style={{textAlign:"right", paddingRight:30}}>Net3</th>
                            </tr>
                        </thead>
                        {footNote[0].rubriques.map((value, index)=>(
                            
                            <tbody>
                                {index<3 &&(
                                    <>
                                <tr key={value.reference} style={{backgroundColor:"var(--tp-common-gray)", color:"black", fontWeight:"bold"}}>
                                    <td  colSpan="4" style={{textAlign:"center"}}>{t(`FOOTNOTE.${value.reference}`)}</td>
                                </tr>
                                {value.posts.map((post)=>(
                                    <tr key={post.reference}>
                                        <td>{t(`FOOTNOTE.${post.reference}`)}</td>
                                        <td style={{padding:"0", margin:"0", height:"fit-content"}}><input className="form-control mt-0 mb-0" id="firstName1" type="number" placeholder="0.0" style={{borderStyle:"none", textAlign:"right"}} onChange={(e)=>{setFootNoteValues(post.reference, e.target.value, "net")}}/></td>
                                        <td style={{padding:"0", margin:"0", height:"100"}}><input className="form-control" id="firstName1" type="number" placeholder="0.0" style={{borderStyle:"none", textAlign:"right"}} onChange={(e)=>{setFootNoteValues(post.reference, e.target.value, "net1")}}/></td>
                                        <td style={{padding:"0", margin:"0", height:"fit-content"}}><input className="form-control" id="firstName1" type="number" placeholder="0.0" style={{borderStyle:"none", textAlign:"right"}} onChange={(e)=>{setFootNoteValues(post.reference, e.target.value, "net2")}}/></td>
                                    </tr>
                                ))}</>)}
                            </tbody>
                        ))}
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 mb-5">
                <div className="card text-left o-hidden">
                    <div className="card-header d-flex align-items-center border-0 my-header">
                        <h3 className="w-100 float-left card-title m-0 text-white">{t("FIN_STATEMENT.FOOT_NOTE")}</h3>
                    </div>
                        <div className="table-responsive table-strip">
                                <table className="table table-hover table-bordered">
                                <thead style={{backgroundColor:"var(--tp-common-gray)"}}>
                                    <tr>
                                        <th scope="col">{t("FIN_STATEMENT.LABEL")}</th>
                                        <th scope="col" style={{textAlign:"right", paddingRight:30}}>Net</th>
                                        <th scope="col" style={{textAlign:"right", paddingRight:30}}>Net2</th>
                                        <th scope="col" style={{textAlign:"right", paddingRight:30}}>Net3</th>
                                    </tr>
                                </thead>
                                {footNote[0].rubriques.map((value, index)=>(
                                    
                                    <tbody>
                                        {index>=3 &&(
                                            <>
                                        <tr key={value.reference} style={{backgroundColor:"var(--tp-common-gray)", color:"black", fontWeight:"bold"}}>
                                            <td colSpan="4" style={{textAlign:"center"}}>{t(`FOOTNOTE.${value.reference}`)}</td>
                                        </tr>
                                        {value.posts.map((post)=>(
                                            <tr key={post.reference}>
                                                <td>{t(`FOOTNOTE.${post.reference}`)}</td>
                                                <td style={{padding:"0", margin:"0", height:"fit-content"}} className="mb-0"><input className="form-control" id="firstName1" type="number" placeholder="0.0" style={{borderStyle:"none", textAlign:"right"}} onChange={(e)=>{setFootNoteValues(post.reference, e.target.value, "net")}}/></td>
                                                <td style={{padding:"0", margin:"0", height:"fit-content"}}><input className="form-control mt-0 mb-0" id="firstName1" type="number" placeholder="0.0" style={{borderStyle:"none", textAlign:"right"}} onChange={(e)=>{setFootNoteValues(post.reference, e.target.value, "net1")}}/></td>
                                                <td style={{padding:"0", margin:"0", height:"fit-content"}}><input className="form-control mt-0 mb-0" id="firstName1" type="number" placeholder="0.0" style={{borderStyle:"none", textAlign:"right"}} onChange={(e)=>{setFootNoteValues(post.reference, e.target.value, "net2")}}/></td>
                                            </tr>
                                        ))}</>)}
                                    </tbody>
                                ))}
                                </table>
                        </div>
                    </div>
            </div>
        </div>
    )
}
export default FootNoteForm;