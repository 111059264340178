export const en = {
    textExperience: "Experiences",
    textHappyClient: "Happy Clients",
    textContactQ: `<span> You can contact </span><br/>  <span> us for any question </span> `,
    textContactQDesc: `If you have any questions or feedback, please feel free to contact us. We are always 
    happy to hear from you and help you with your needs. You can call us at +237 696 888 241, or email us at 
    <a href="mailto:contact@bkresearchandanalytics.com">contact@bkresearchandanalytics.com</a> .
     We look forward to hearing from you soon.`,
    textWelcomeBack: "Welcome",
    textHome: "Home",
    textAboutUs: "About Us",
    textService: "Service",
    textTeam: "Team",
    textBlog: "Blog",
    textContact: "Contact",
    textLngFrench: "French",
    textLngEnglish: "English",
    textBilling: "Billing",
    textSearch: "Search",
    textAccSettings: "Account Settings",
    textBillHistory: "Billing History",
    logOut: "Sign Out",
    textPwForgot: "Password Forgot",
    textRecover: "Recover Here",
    textSignIn: "Sign In",
    textPassword: "Password",
    textEmail: "Email Address",
    textEmailRep: "Email Required",
    textSendLink: "Receive Link",
    textPWRecovered: "Password successfully recovered",
    textEmailConfirmErr: "Error Confirming Your address",
    textEmailSentMsg: "Email sent: verify your address",
    textEmailNotFound: "Email Not Found",
    textNoAccount: "Don't have an Account",
    textHaveAccount: "Have an Account",
    textSingHere: "Sign Up Here",
    textMin: "It Takes less than a Minute",
    textLogIn: "Login Here",
    textLogInSuccess: "OTP sent to your email account",
    textTypeNewPW: "Type new Password",
    textReTypePW: "Retype password",
    textAccVerSuccess: "Your account has been Successfully verified, start Your journey",
    textSelectAccType: "Select Your Account Type",
    textAccType: "Account Type",
    textAccDet: "Account Details",
    textStarParams: "The parmaters with a star",
    textMandatory: "Are mandatory",
    textSelectComp: "Select Account Type",
    textFinFirm: "Financial Firm",
    textOtherFirm: "Other Type of Firm",
    textIndividual: "Individual",
    textReset: "Reset",
    textFinInd: "Financial Indicators",
    textForcastingDataYear: 'Financial Forcasting for the next',
    textYear: "Years",
    textYear_: "Year",
    textNext: "Next",
    textGetStarted: "Get Started",
    textPeriod: "Period",
    textEndYear: "End of Year",
    textFNextYear: "Forcasting for the next",
    textCancel: "Cancel",
    textProcedd: "Analyse data",
    textOthers: "Other Information",
    textWeak: "Weak",
    textHalf: "Medium",
    textHight: "Hight",
    textYes: "Yes",
    textCountry: "Country",
    textCountrySelect: "Select Country",
    textAmount: "Montant",
    textNumYears: "Number of Years",
    textNumCountry: "Number of Countries",
    textNo: "No",
    textRatingSuccess: "Rating Successfull",
    textRatingReadRes:"Take care to read the results carefully and if you have any enquiry , feel free to contact us",
    textCRRDetails: "Rating details",
    txtDownloadRep: "Download Report",
    textNew: "New",
    textViewAll: "Veiw All",
    textView: "View",
    textFirstName: "First Name",
    textLastName: "Last Name",
    textFinInstType: "Type of Financial Institution",
    textCompName: "Company Name",
    textProfession: "Profession",
    textProEmail: "Professionnal Email address",
    textCity: "City",
    textBank: "Bank",
    textMicrofin: "Micro finance",
    textOthersEnt: "Other",
    textConsFirms: "Consulting Firm",
    textPhonNum: "Phone Number",
    textConfirmPW: "Confirm Password",
    textPWDesc: "Password Should be a combination of special, capital, lowecase and numeric characters",
    textAccept: "Accept Our",
    textTerm: "Terms",
    textAnd: "and",
    textPrivacyPolicy: "Privacy Policy",
    textFirstName_: "First name is Required",
    textFinInstType_: "Choose the Type of Your Company",
    textCompName_: "What is Your Company Name",
    textProfession_: "Provide Your profession",
    textProEmail_: "Provide A valide Professional email address",
    textCity_: "Select Your City",
    textPhonNum_: "Provide a Valid Phone Number",
    textPassword_:"Invalid Password",
    textConfirmPW_: "Password Not Equal",
    textAgreeTerms_: "Need To reed and agree Our terms",
    textOTPMsg: `Please enter the OTP sent to your <br/> email address or phone number`,
    textOTP: "One Time Password",
    textOTP_: "Invalid Otp Code",
    textNoOTP: "OTP Not received",
    textOTPVFailed: "Unable to verify OTP",
    textResend: "Resend",
    textResendSuccess: "OTP Resent",
    textResendFailed: "Unable to Resend OTP",
    textLoginFailed: "Email or password incorrect",
    textContinue: "Continue",
    textInvOldPwError: "You password is incorrect",
    textOldPwError: "Your new password is equal to the old one: Please enter a new",
    textNoOldPwError: "Provide your old password",
    textNotEqualPw: "Password not equal",
    textPwUpdated:"Password Successfully Updated",
    MONTHS: {
        JAN:"January",
        FEB: "February",
        MAR: "March",
        APR: "April",
        MAY: "May",
        JUN: "June",
        JUL: "July",
        AUG: "August",
        SEP: "September",
        OCT: "October",
        NOV: "November",
        DEC: "December"
    },
    OTHERCRRINFO: {
        "CRRBREAKDOWN":"Transaction Breakdown",
        "MONTHCRR": "Monthly Rating",
        "MNEWCRR": "New rating",
        "LATECRR": "Latest Ratings",
        "LASTCRR": "Last Rating",
        "FINEXPCOVRATIO":"Financial expense coverage ratios and fixed expense coverage ratios",
        "OPWCRATIO": "Operating working capital ratio",
        "DERATIOB":"Debt-to-equity ratio based on book value of debt",
        "PROFRATIO":"Profitability Ratio",
        "CURRASS":"Current assets",
        "CURRLIAB": "Current liabilities",
        "CURRRATIO": "Current ratio",
        "CASHEQ":"Cash and cash equivalents",
        "STLIAB": "Short term liab liabilities",
        "LIQRIATIO":"Liquidity ratio Continued",
        "REF":"Reference",
        "CSCORE": "Credit Score",
        "DEFAULTPROB": "Default Probability",
        "BETA":"Beta",
        "RISKPREM": "Risk premium",
        "EQUITYCOST": "Cost of equity",
        "DEBTCOST": "Cost of debt",
        "CAPCOST": "Cost of capital",
        "STINRISK":"Short-term insolvency risk",
        "ACTIVTYBREAKDOWN": "Revenue by business sector",
        "COUNTRYBREAKDOWN": "Revenue by Country",
        "OLEXPENSE":"Operating lease Expenses",
        "RDEXPENSE":"Research and Developpement Expenses",
        "ENTRYMETHOD": "Entry Method",
        "COMPANYDETAILS": "Company's Details",
        "LEGALFORM": "Legal Status",
        "TAX": "Tax",
        "VTA": "Value Added Tax (VTA)",
        "YEARCREATE": "Year of Creation",
        "UNDERCONTROLE": "Controlled By a third party",
        "VISA": "expert's visa",
        "AMORTPERIOD": "Amort Period",
        "TYEAR":"This Year",
        "HEADQ": "Head Quater",
        "DEBTDETAILS": "Debt details",
        "DEBTMATURITY": "Debt Maturity",
        "DEBTESTIMATION": "Cost of Debt Estimation approach",
        "DEBTBT": "Cost of Debt before Tax",
        "CMPTYPE": "Type of Company",
        "VALUE": "Value",
        "ActualRating": "Actual Rating Score",
        "DirectInput": "Direct Input",
        "SyntheticRating": "Synthetic Rating"
    },

    FIN_STATEMENT:{
		"MODE_":"Extended Mode",
		"MODE":"Reduced Mode",
		"VIEW":"Landscape",
		"VIEW_":"Portrait",
		"ASSETS":"Assets",
		"BALANCESHEET":"Balancesheet",
		"LIABILITIES":"Liabilities",
		"CASHFLOW":"Cash Flow",
		"INCOME_STATEMENT":"Income Statement",
		"LABEL":"Label",
		"GROSS":"Gross",
		"AMORT":"Amortization/Depreciation",
		"FOOT_NOTE":"Foot Note",
        "QDATA": "Qualitative Data",
        "QDATADESC": "About the Founder",
        "UPLOAD": "Upload Your DSF files",
        "YEARE": "Excesice"
	},
    ASSETS:{
        "AD": "Intangible Asset",
        "AE": "Research and Development",
        "AF": "Patents, licenses, softwares and other titles",
        "AG": "Goodwill", 
        "AH": "Others intangibles assets", 
        "AI": "Property, Plant and Equipment",
        "AJ": "Lands (1)",
        "AK": "Buildings (1)", 
        "AL": "Fittings, fixtures and installations",
        "AM": "Equipment, furniture and biological assets",
        "AN": "Transport equipment",
        "AP": "ADVANCES ON ASSETS",
        "AQ": "Financial Asset",
        "AR": "Equity securities", 
        "AS": "Others financials assets",
        "AZ": "FIXED ASSET",
        "BA": "Non Ordinary Activity - Current Assets",
        "BB": "Inventories and WIP",
        "BC": "Receivables and assimilated items",
        "BH": "Prepaid expenses",
        "BI": "Accounts receivable",
        "BJ": "Other receivable",
        "BK": "CURRENT ASSET",
        "BQ": "Marketable Securities",
        "BR": "Cheques and bill awaiting collection",
        "BS": "Cash at banks, cash at hand",
        "BT": "CASH-ASSETS",
        "BU": "Unrealized foreign exchange losses",
        "BZ": "TOTAL ASSET" 
    },
    LIABILITIES:{
        "CA": "Capital",
        "CB": "Shareholders, uncalled capital (-)", 
        "CD": "Premiums related to share capital", 
        "CE": "Reevaluation difference",
        "CF": "Unavailable reserves", 
        "CG": "Free reserves",
        "CH": "Accumulated profit (+or-)",
        "CJ": "Net income of the year (+ or -)", 
        "CL": "Investment grant",
        "CM": "Regulated provisions",
        "CP": "Equity (Shareholders)",
        "DA": "Loans and financial liabilities",
        "DB": "Capital lease liabilities",
        "DC": "Provisions for contingencies and charges",
        "DD": "Financial Debts",
        "DF": "PERMANENT RESSOURCES", 
        "DH": "N.O.A Current liabilities", 
        "DI": "Deferred revenue",
        "DJ": "Account Payable",
        "DK": "Tax and social (security) debits",
        "DM": "Other payable",
        "DN": "Provisions for current risk",
        "DP": "CURRENT LIABILITIES", 
        "DQ": "Banks, Discount loan",
        "DR": "Banks, Short term loan",
        "DT": "CASH LIABILITIES", 
        "DV": "Potential foreign exchange gain",
        "DY": "Potential foreign exchange gain",
        "DZ": "TOTAL LABILITIES"
    },
    INCOME_STATEMENT:{
        "TA": "Sales of goods",
        "RA": "Purchases of goods",
        "RB": "Change in inventory (goods) (+ or -)", 
        "XA": "Commercial Margin", 
        "TB": "Sales of manufactured products", 
        "TC": "Services sold",
        "TD": "Ancillary Income",
        "XB": "Turnover",
        "TE": "Stored production (or destocking)", 
        "TF": "Self-constructed asset (own use)", 
        "TG": "Operating grants", 
        "TH": "Other income",
        "TI": "Operating expenses transferred", 
        "RC": "Purchases of materials and supplies",
        "RD": "Change in inventory (materials and supplies)",
        "RE": "Other purchases",
        "RF": "Change in inventory (other supplies)", 
        "RG": "Transport", 
        "RH": "External services", 
        "RI": "Taxes and duties",
        "RJ": "Other expenses", 
        "XC": "Value Added", 
        "RK": "Payroll expenses", 
        "XD": "Gross Operating Earnings",
        "TJ": "Reversal of amortization and provision",
        "RL": "Depreciations and provisions",
        "XE": "Operating Profit or Loss",
        "TK": "Financial income",
        "TL": "Financial provisions written-off",
        "TM": "Financial expenses transferred",
        "RM": "Financial expenses",
        "RN": "Financial Depreciations and provisions",
        "XF": "Financial Profit or Loss",
        "XG": "Ordinary Activity Earnings", 
        "TN": "Income from asset disposal",
        "TO": "Other income N.O.A.",
        "RO": "Book value of Asset Disposal",
        "RP": "Other Expenses N.O.A",
        "XH": "Non Ordinary Activity Earnings",
        "RQ": "Employees profit sharing scheme",
        "RS": "Income tax",
        "XI": "Net Income"
    },
    CASH_FLOW:{
        "ZA": 	"Net cash as at January 01",
        "FA": "Global self financing capacity (GSFC)",
        "FB":  "N.O.A. current assets",
        "FC": "Change in inventories",
        "FD": "Change in receivable",
        "FE": "Change in current liabilities",
        "ZB": "Cash flow from operating activities", 
        "FF": "Cash outflow acquisition Intan. Asset",
        "FG": "Cash outflow acquisition Tang. Asset",
        "FH": "Cash outflow acquisition Fin. Asset",
        "FI": "Cash inflow disposal Intan. Tang. Asset",
        "FJ": "Cash inflow disposal Fin. assets",
        "ZC": "Cash flows from investment activities",
        "FK": "Increase of capital by new contributions",
        "FL":  "Investment grants received",
        "FM":  "Reductions in capital",
        "FN":  "Dividends paid", 
        "ZD": 	"Cash flows from owner's equity",
        "FO": "Increase of Loans",
        "FP": "Other financial debts",
        "FQ": "Reimbursements of loans and other financial debts",
        "ZE":  "Cash flows from external funding",
        "ZF":  "Cash flows from financing activities",
        "ZG":  "Variation of cash of the period",
        "ZH": 	"Net cash as at December 31"
    },
    FOOTNOTE:{
        "TAB1": "Ecart de Conversion",
        "TAB2": "Capital & Dettes",
        "TAB3": "Regulrisation Charges",
        "TAB4": "Dettes et Charges Financières",
        "TAB5": "Actif & Passif Circulant",
        "TAB6": "Amortissement & Provisions",
        "TAB7": "Acquisition et Cession",
        "TAB_1": "Borrowing cost",
        "TAB_2": "O.O.A written back",
        "TAB_3": "O.O.A Allocation",
        "TAB_4": "Establishment Fee",
        "TAB_5": "Redemption Premiums Bond",
        "TAB_6": "Deferred charges",
        "TAB_7": "Interest accrued on loans",
        "TAB_8": "Probable loss on exchange/fixed asset",
        "TAB_9": "Probable gain on exchange/Fixed asset",
        "TAB_10": "Probable loss on exchange/Inventories",
        "TAB_11": "Probable gain on exchange/Inventories",
        "TAB_12": "Probable loss on exchange/Current Asset",
        "TAB_13": "Probable gain on exchange/Current Asset",
        "TAB_14": "Probable loss on exchange/O.O.A current asset",
        "TAB_15": "Probable gain on exchange/O.O.A current asset",
        "TAB_16": "Probable loss on exchange/Borrowing",
        "TAB_17": "Probable gain on exchange/Borrowing",
        "TAB_18": "Accrued Interest on Borrowings",
        "TAB_19": "Probable loss on exchange/Debts",
        "TAB_20": "Probable gain on exchange/ Debts",
        "TAB_21": "Probable loss on exchange/O.O.A liabilities",
        "TAB_22": "Probable gain on exchange/O.O.A liabilities",
        "TAB_23": "Acquisition Intangible assets",
        "TAB_24": "Achievable +1 year",
        "TAB_25": "Financial fixed assets -1 year",
        "TAB_26": "Borrowing Contracted",
        "TAB_27": "Receivables on fixed assets",
        "TAB_28": "Suppliers on Investment",
        "TAB_29": "Acquisition Property, plant and equipment",
        "TAB_30": "Acquisition Financial fixed assets",
        "TAB_31": "Disposal Intangible assets",
        "TAB_32": "Disposal Property, plant and equipment",
        "TAB_33": "Disposal Financial fixed asset",
        "TAB_34": "Capital Deduction",
        "TAB_35": "dividends",
        "TAB_36": "Repayment of Debts",
        "TAB_37": "Amounts payable within one year",
        "TAB_38": "Suppliers more than one year",
        "TAB_39": "Provision on Stock",
        "TAB_40": "Provision on Debt",
        "TAB_41": "Ammortissement Cumul. Inc",
        "TAB_42": "Ammortissement Cumul. corp",
        "TAB_43": "Ammortissement Cumul. fin",
        "TAB_44": "Other financial Debts",
        "TAB_45": "Provision sur tresorerie Actif",
        "TAB_46": "number of Shares",
        "effectif": "number of Staff"
    },
    QUALITATIVE_DATA:{
        "LABEL": "About the entrepreneur",
        "MOD": "Modalities",
        "Var_1": "What is the degree of aversion to risk (of the manager/promotor)?",
        "Var_2": "Is the promotor trustworthy?",
        "Var_3": "Is the manager/promoter experienced in the field?",
        "Var_4": "Promotor aged (>40)?",
        "Var_5": "What is the enterprise's capacity",
        "Var_6": "Respect of engagements",
        "Var_7": "What is the degree of transparancy in communications?",
        "Var_8": "What is the promotor's reliability as information source?"
    }, 
    
    RATINGS_DATA:{
		"NOTE_LABEL": "Credit Score",
		"DEFAULT_PROBABILITY": "Default Probability",
		"BETA": "Beta",
		"RISK_PREMIUM": "Risk Premium",
		"FOND_PROPRE": "Cost of Equity",
		"COST_CREDIT": "Cost of Credit",
		"COST_CAPITAL": "Cost of Capital",


		"LIQUIDITY_TITLE": "Liquidity Ratio",
		"LIQUIDITY_ASSETS": "Current Assets",
		"LIQUIDITY_LIAB": "Current Liabilities",
		"LIQUIDITY_CMS": "Cash & Marketable Secu.",
		"LIQUIDITY_CR": "Current Ratio",
		"LIQUIDITY_QR": "Quick Ratio",
		"WORKING_CAPITAL_MGT_TITLE": "Working Capital Ratio",
		"WORKING_CAPITAL_MGT_SALES": "Sales",
		"WORKING_CAPITAL_MGT_AR": "Account Receivables",
		"WORKING_CAPITAL_MGT_ART": "Account Receivables Turnover",
		"WORKING_CAPITAL_MGT_DR": "Days Receivables",
		"WORKING_CAPITAL_MGT_COG": "Cost of Goods",
		"WORKING_CAPITAL_MGT_INV": "Inventory",
		"WORKING_CAPITAL_MGT_IT": "Inventory Turnover",
		"WORKING_CAPITAL_MGT_DIH": "Days Inventory Held",
		"WORKING_CAPITAL_MGT_PUR": "Purchases",
		"WORKING_CAPITAL_MGT_AP": "Accounts Payable",
		"WORKING_CAPITAL_MGT_APT": "Accounts Payable Turnover",
		"WORKING_CAPITAL_MGT_DAP": "Days Accounts Payable",
		"WORKING_CAPITAL_MGT_RFP": "Required Financing Period",

		"WORKING_CAPITAL_VAR_SJ": "Stable Ressources",
		"WORKING_CAPITAL_VAR_SR": "Stable Ressource",
		"WORKING_CAPITAL_VAR_WC": "Working Capital",
		"WORKING_CAPITAL_VAR_VWC": "Var WC",
		"WORKING_CAPITAL_VAR_CAN": "Current Asset Normal",
		"WORKING_CAPITAL_VAR_CLN": "Current Liabilities Normal",
		"WORKING_CAPITAL_VAR_NCWCN": "Non Cash  Working Capital Normal (Working Capital Requirement Normal)",
		"WORKING_CAPITAL_VAR_VWCN": "Var WCRN",
		"WORKING_CAPITAL_VAR_CAE": "Current Asset Exceptional",
		"WORKING_CAPITAL_VAR_CLE": "Current Liabilities Exceptionnel",
		"WORKING_CAPITAL_VAR_NCWCE": "Non Cash Working Capital Exceptionnel (Working Capital Requirement H.A.O)",
		"WORKING_CAPITAL_VAR_VWCH.A.O": "Var WCR.H.A.O",
		"WORKING_CAPITAL_VAR_NCWCR": "Non-cash Working Capital (Net Working Capital Requirement)",
		"WORKING_CAPITAL_VAR_VWCR": "Var WCR",
		"WORKING_CAPITAL_VAR_CM": "Cash and Marketable",
		"WORKING_CAPITAL_VAR_STDB": "Short Term Debt Bearing",
		"WORKING_CAPITAL_VAR_CWC": "Cash Working Capital",
		"WORKING_CAPITAL_VAR_WCR": "Working Capital Requirement",
		"WORKING_CAPITAL_VAR_WCR.H.A.O": "Working Capital Requirement H.A.O",
		"WORKING_CAPITAL_CAFG": "CAFG",

		"CHARGE_COVERAGE_RATIO_TITLE": "Charges Coverage Ratio",
		"CHARGE_COVERAGE_RATIO_EBIT": "E.B.I.T",
		"CHARGE_COVERAGE_RATIO_IE": "Interest expenses",
		"CHARGE_COVERAGE_RATIO_ICR": "Interest coverage ratio",
		"CHARGE_COVERAGE_RATIO_OLE": "Operating lease expense",
		"CHARGE_COVERAGE_RATIO_FCCR": "Fixed charge coverage ratio",
		"CHARGE_COVERAGE_RATIO_EBEXP": "EBexp  [E.B.I.D.T.A]",
		"CHARGE_COVERAGE_RATIO_CFC": "Cash fixed charges",
		"CHARGE_COVERAGE_RATIO_CFHCR": "Cash fixed charge coverage",
		"CHARGE_COVERAGE_RATIO_CFO": "Cash flow from operations",
		"CHARGE_COVERAGE_RATIO_CE": "Capital expenditures",
		"CHARGE_COVERAGE_RATIO_CF": "CF/Cap Ex",

		"DEBT_RATIO_TITLE": "Debt Ratio",
		"DEBT_RATIO_LTD": "Long Term Debt",
		"DEBT_RATIO_STD": "Short Term Debt",
		"DEBT_RATIO_BVE": "BV of Equity",
		"DEBT_RATIO_LTDE": "LT Debt/Equity",
		"DEBT_RATIO_LTD_LTDE": "LT Debt/(LT Debt + Equity)",
		"DEBT_RATIO_DE": "Debt/Equity",
		"DEBT_RATIO_DDE": "Debt/(Debt + Equity)",
		"DEBT_RATIO_ER": "Equity Ratio",

		"RETURN_RATIO_TITLE": "Return Ratio",
		"RETURN_RATIO_SALES": "Sales",
		"RETURN_RATIO_OI": "Operating Income",
		"RETURN_RATIO_ATOI": "After-tax Operating Income",
		"RETURN_RATIO_NE": "Net Income",
		"RETURN_RATIO_OM": "Operating Margin",
		"RETURN_RATIO_ETOM": "After-tax Operating Margin",
		"RETURN_RATIO_NM": "Net Margin",
		"RETURN_RATIO_EBITM": "E.B.I.T Margin",
		"RETURN_RATIO_BVEB": "BV of Equity-Beginning",
		"RETURN_RATIO_BVEE": "BV of Equity-Ending",
		"RETURN_RATIO_BVEA": "BV of Equity-Average",
		"RETURN_RATIO_ROEBA": "ROE (based on average)",
		"RETURN_RATIO_ROEBB": "ROE (based on beginning)",
		"RETURN_RATIO_BVCB": "BV of Capital-Beginning",
		"RETURN_RATIO_BVCE": "BV of Capital-Ending",
		"RETURN_RATIO_BVCA": "BV of Capial-Average",
		"RETURN_RATIO_ROCBB": "ROC (based on beginning)",
		"RETURN_RATIO_ROCBA": "ROC (based on average)",
		"RETURN_RATIO_ROCBE": "ROC (based on ending)",
		"RETURN_RATIO_BVCIB": "BV of Capital Invested Beginning",
		"RETURN_RATIO_BVCIE": "BV of Capital Invested Ending",
		"RETURN_RATIO_BVCIA": "BV of Capital Invested Average",
		"RETURN_RATIO_ROICBA": "ROIC (based on average)",
		"RETURN_RATIO_ROICBB": "ROIC (based on beginning)",
		"RETURN_RATIO_BVAB": "BV of Assets Beginning",
		"RETURN_RATIO_BVAE": "BV of Assets Ending",
		"RETURN_RATIO_BVAA": "BV of Assets Average",
		"RETURN_RATIO_ROABB": "ROA (based on beginning)",
		"RETURN_RATIO_ROABA": "ROA (based on average)",
		"RETURN_RATIO_ATROC": "After-Tax ROC",
		"RETURN_RATIO_DER": "Debt/Equity Ratio",
		"RETURN_RATIO_BIR": "Book Interest Rate (1-tax rate)",
		"RETURN_RATIO_ROE": "ROE",
		"RETURN_RATIO_ATO": "Assets Turn Over",
		"ADJUSTED": "Adjusted",
		"UNADJUSTED": "Unadjusted",
		"GRADE1": "Highest",
		"GRADE2": "High",
		"GRADE3": "Above Average",
		"GRADE4": "Average",
		"GRADE5": "Below Average",
		"GRADE6": "Inadequate",
		"GRADE7": "Default",
		"NOTE": "Note",
		"IDANTIFIANT": "Identifier",
		"DATE": "Date",
		"ACTIVITY": "Activity",
		"INDICATOR": "Indicator",
		"DOWNLOAD": "Download",
		"DETAILS": "Details"
	},
    textAssumption: "Hypothéses",
    ASSUMPTIONS: {
        kAccPayab: "Account Payable",
        kAccRecev: "Account Receivable",
        kCashMarket: "Cash market secutities",
        kCostOfSales: "cost of Sales",
        kDepreciation: "Depreciation on Current Assets",
        kDeprOthNonCur: "Depreciation on non-current Assets",
        kDividend: "Divident",
        kInterestInc: "Interest Income",
        kInventories: "Inventories",
        kOthCurAss: "Other Current Assets",
        kOthCurLiab: "Other Current Liabilities",
        kOtherNetInc: "Other Net Income",
        kOthNonCur: "Other Non Current Assets",
        kOthNonCurLiab: "Other non Current Liabilities",
        kPpe: "Property, Equity and plant",
        kSales: "Sales",
        kSellingGeneAdm:"Selling and Gen. Admin",
        kShortDeprStrict: "Short term Depreciation",
        kStd: "Short term Debt",
        kTax: "Tax",
        kTaxSocialLiab: "Tax and Social Liabilites",
        longTermInt: "Long term interest",
        shortTermRate: "Short term rate",
        kShortDepr: "Short term Depreciation"
    },
    FINFORCASTING: {
        Sales: "Sales",
        COS: "Cost of Sales",
        GOInc: "Gross Operating Income",
        SGAExp: "Selling, General & Adm. Expenses",
        Deprec: "Depreciation",
        OthNIncExp: "Other net (Income)/Expenses",
        EBIT: "EBIT",
        IntInc: "Interest Income",
        IntExp: "Interest Expense",
        PreTaxInc: "Pretax Income",
        IncTax: "Income Tax",
        NetInc: "Net Income",
        Dividend: "Dividends",
        AddRet: "Addition Retained",
        CMSec: "Cash and Marketable Securities",
        AccRec: "Account Receivalbe",
        Inv: "Inventories",
        OthCurrAss: "Other Current Assets",
        OthCurrAccumDepr: "Accumulated Depreciation",
        ToCurrAss: "Total Current Assets",
        PPEGr: "Property, Plant and Equipment, Gross",
        PPEAcummDepr: "Accumulated Depreciation",
        PPENet: "Property, Plant and Equipment Net",
        OthNCurrAss: "Other Non-Current Assets",
        OthNCurrAccDepr: "Accumulated Depreciation",
        ToNCurrAss: " Total Non-Current Assets",
        ToAss: "  Total Assets",
        AccPay: "Account Payable",
        TSLiab: "Tax and Social Liabilities",
        STD: "Short-term Debt",
        OthCurrLiab: "Other Current Liabilities",
        ToCurrLiab: "   Total Current Liabilities",
        LTDeb: "Long Term Debt",
        OthNCurrLiab: "Other Non-Current Liabilities",
        ToLiab: "Total Liabilities",
        Pcap: "Paid Capital",
        RetEarn: "Retained Earnings",
        ToShaEq: "   Total Shareholders's Equity",
        ToLiabEq: " Total Liabilities and Shareholders' Equity",
        FDN: "Discretionary Funding Need (FDN)",
        EPS: "EPS",
        DivPSh: "Dividend per Share",
        PERatio: "P/E Ratio",
        PBRatio: "P/B Ratio",
        DivPR: "Dividend Payout Ratio",
        ROS: "Return On Sales",
        ROE: "Return On Equity",
        ROA: "Return On Asset",
        SGR: "Sales growth rate",
        EBITGR: "EBIT growth rate",
        NetIncGR: "Net income growth rate",
        CurrR: "Current ratio",
        QuR: "Quick Ratio",
        Income: "Income",
        ProfRatio: "Profitability ratio",
        GrowthRatio: "Growth ratio",
        LiqRatio: "Liquidity ratio",
        LeverageRatio: "Leverage Ratio",
        coverageRatio: "Coverage Ratio",
        textInitialFI: "Initial Indicators",
        textAssumptionConsidered: "Assumptions Considered",
        textForcastingOnFI: "Forcasting on Financial Indicators",
        DTC: "Total debt to Total capitalization",
        TLDTC: "Long term debt to Total capitalisation",
        DEQ: "Total debt to Equity",
        TIE: "Time Interest Earned (TIE)",
        CCR: "Cash coverage ratio",
        StatementBefore: "Etats Financiers avant la période considérée",
        CSStatement: "Common size Statement"
    },
    COUNTRY:{
        "AF": "Afghanistan",
        "AX": "Aland Islands",
        "AL": "Albania",
        "DZ": "Algeria",
        "AS": "American Samoa",
        "AD": "Andorra",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antarctica",
        "AG": "Antigua and Barbuda",
        "AR": "Argentina",
        "AM": "Armenia",
        "AW": "Aruba",
        "AU": "Australia",
        "AT": "Austria",
        "AZ": "Azerbaijan",
        "BS": "Bahamas",
        "BH": "Bahrain",
        "BD": "Bangladesh",
        "BB": "Barbados",
        "BY": "Belarus",
        "BE": "Belgium",
        "BZ": "Belize",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BT": "Bhutan",
        "BO": "Bolivia",
        "BQ": "Bonaire, Saint Eustatius and Saba",
        "BA": "Bosnia and Herzegovina",
        "BW": "Botswana",
        "BV": "Bouvet Island",
        "BR": "Brazil",
        "IO": "British Indian Ocean Territory",
        "VG": "British Virgin Islands",
        "BN": "Brunei",
        "BG": "Bulgaria",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "CV": "Cabo Verde",
        "KH": "Cambodia",
        "CM": "Cameroon",
        "CA": "Canada",
        "KY": "Cayman Islands",
        "CF": "Central African Republic",
        "TD": "Chad",
        "CL": "Chile",
        "CN": "China",
        "CX": "Christmas Island",
        "CC": "Cocos Islands",
        "CO": "Colombia",
        "KM": "Comoros",
        "CK": "Cook Islands",
        "CR": "Costa Rica",
        "HR": "Croatia",
        "CU": "Cuba",
        "CW": "Curacao",
        "CY": "Cyprus",
        "CZ": "Czechia",
        "CD": "Democratic Republic of the Congo",
        "DK": "Denmark",
        "DJ": "Djibouti",
        "DM": "Dominica",
        "DO": "Dominican Republic",
        "EC": "Ecuador",
        "EG": "Egypt",
        "SV": "El Salvador",
        "GQ": "Equatorial Guinea",
        "ER": "Eritrea",
        "EE": "Estonia",
        "SZ": "Eswatini",
        "ET": "Ethiopia",
        "FK": "Falkland Islands",
        "FO": "Faroe Islands",
        "FJ": "Fiji",
        "FI": "Finland",
        "FR": "France",
        "GF": "French Guiana",
        "PF": "French Polynesia",
        "TF": "French Southern Territories",
        "GA": "Gabon",
        "GM": "Gambia",
        "GE": "Georgia",
        "DE": "Germany",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GR": "Greece",
        "GL": "Greenland",
        "GD": "Grenada",
        "GP": "Guadeloupe",
        "GU": "Guam",
        "GT": "Guatemala",
        "GG": "Guernsey",
        "GN": "Guinea",
        "GW": "Guinea-Bissau",
        "GY": "Guyana",
        "HT": "Haiti",
        "HM": "Heard Island and McDonald Islands",
        "HN": "Honduras",
        "HK": "Hong Kong",
        "HU": "Hungary",
        "IS": "Iceland",
        "IN": "India",
        "ID": "Indonesia",
        "IR": "Iran",
        "IQ": "Iraq",
        "IE": "Ireland",
        "IM": "Isle of Man",
        "IL": "Israel",
        "IT": "Italy",
        "CI": "Ivory Coast",
        "JM": "Jamaica",
        "JP": "Japan",
        "JE": "Jersey",
        "JO": "Jordan",
        "KZ": "Kazakhstan",
        "KE": "Kenya",
        "KI": "Kiribati",
        "XK": "Kosovo",
        "KW": "Kuwait",
        "KG": "Kyrgyzstan",
        "LA": "Laos",
        "LV": "Latvia",
        "LB": "Lebanon",
        "LS": "Lesotho",
        "LR": "Liberia",
        "LY": "Libya",
        "LI": "Liechtenstein",
        "LT": "Lithuania",
        "LU": "Luxembourg",
        "MO": "Macao",
        "MG": "Madagascar",
        "MW": "Malawi",
        "MY": "Malaysia",
        "MV": "Maldives",
        "ML": "Mali",
        "MT": "Malta",
        "MH": "Marshall Islands",
        "MQ": "Martinique",
        "MR": "Mauritania",
        "MU": "Mauritius",
        "YT": "Mayotte",
        "MX": "Mexico",
        "FM": "Micronesia",
        "MD": "Moldova",
        "MC": "Monaco",
        "MN": "Mongolia",
        "ME": "Montenegro",
        "MS": "Montserrat",
        "MA": "Morocco",
        "MZ": "Mozambique",
        "MM": "Myanmar",
        "NA": "Namibia",
        "NR": "Nauru",
        "NP": "Nepal",
        "AN": "Netherlands Antilles",
        "NC": "New Caledonia",
        "NZ": "New Zealand",
        "NI": "Nicaragua",
        "NE": "Niger",
        "NG": "Nigeria",
        "NU": "Niue",
        "NF": "Norfolk Island",
        "MP": "Northern Mariana Islands",
        "KP": "North Korea",
        "MK": "North Macedonia",
        "NO": "Norway",
        "OM": "Oman",
        "PK": "Pakistan",
        "PW": "Palau",
        "PS": "Palestinian Territory",
        "PA": "Panama",
        "PG": "Papua New Guinea",
        "PY": "Paraguay",
        "PE": "Peru",
        "PH": "Philippines",
        "PN": "Pitcairn",
        "PL": "Poland",
        "PT": "Portugal",
        "PR": "Puerto Rico",
        "QA": "Qatar",
        "CG": "Republic of the Congo",
        "RE": "Reunion",
        "RO": "Romania",
        "RU": "Russia",
        "RW": "Rwanda",
        "BL": "Saint Barthelemy",
        "SH": "Saint Helena",
        "KN": "Saint Kitts and Nevis",
        "LC": "Saint Lucia",
        "MF": "Saint Martin",
        "PM": "Saint Pierre and Miquelon",
        "VC": "Saint Vincent and the Grenadines",
        "WS": "Samoa",
        "SM": "San Marino",
        "ST": "Sao Tome and Principe",
        "SA": "Saudi Arabia",
        "SN": "Senegal",
        "RS": "Serbia",
        "CS": "Serbia and Montenegro",
        "SC": "Seychelles",
        "SL": "Sierra Leone",
        "SG": "Singapore",
        "SX": "Sint Maarten",
        "SK": "Slovakia",
        "SI": "Slovenia",
        "SB": "Solomon Islands",
        "SO": "Somalia",
        "ZA": "South Africa",
        "GS": "South Georgia and the South Sandwich Islands",
        "KR": "South Korea",
        "SS": "South Sudan",
        "ES": "Spain",
        "LK": "Sri Lanka",
        "SD": "Sudan",
        "SR": "Suriname",
        "SJ": "Svalbard and Jan Mayen",
        "SE": "Sweden",
        "CH": "Switzerland",
        "SY": "Syria",
        "TW": "Taiwan",
        "TJ": "Tajikistan",
        "TZ": "Tanzania",
        "TH": "Thailand",
        "NL": "The Netherlands",
        "TL": "Timor Leste",
        "TG": "Togo",
        "TK": "Tokelau",
        "TO": "Tonga",
        "TT": "Trinidad and Tobago",
        "TN": "Tunisia",
        "TR": "Turkey",
        "TM": "Turkmenistan",
        "TC": "Turks and Caicos Islands",
        "TV": "Tuvalu",
        "UG": "Uganda",
        "UA": "Ukraine",
        "AE": "United Arab Emirates",
        "GB": "United Kingdom",
        "US": "United States",
        "UM": "United States Minor Outlying Islands",
        "UY": "Uruguay",
        "VI": "U.S. Virgin Islands",
        "UZ": "Uzbekistan",
        "VU": "Vanuatu",
        "VA": "Vatican",
        "VE": "Venezuela",
        "VN": "Vietnam",
        "WF": "Wallis and Futuna",
        "EH": "Western Sahara",
        "YE": "Yemen",
        "ZM": "Zambia",
        "ZW": "Zimbabwe"
    }
}