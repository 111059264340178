import { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styles } from "../../../../components/utils/reactSelect"
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const AccountDetails = ({accountSubType, setSubAccountType, accountType, cyties,phoneValid,setPhoneNumber,loading,
    validCity,selectedCity,setSelectedCity,validFirstName, 
    firstName, setFirstName, lastName, setLastName, validProfession,
    setProfession, profession, emailValid, setEmail, agreed, noSubAccountType,
    setAgree, agree, passwordValid, setPassword, equalPassword, setPassword2,companyName, setCompanyName,t}) => {
    
    const [showPassword, setShowPassword] = useState(false);const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (<>
        <div className="row">
            {/* <div className="col-12">
                <h3 style={{background:"linear-gradient(90deg, #004D6E 0%, #00ACCC 100%)", WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>Privide Account Details!</h3>
            </div> */}
        </div>
        <div className="row row-xs">
            <div className="col-md-6 form-group mb-3">
                {!validFirstName && <label className="text-danger">{t("textFirstName_")}</label>}
                {validFirstName && <label htmlFor="firstName">{t("textFirstName")}<span className="text-danger">*</span></label>}
                <input className="form-control" id="firstName" type="text" value={firstName} placeholder="Nkolo" name="firstName" 
                onChange={(e)=>{setFirstName(e.target.value)}}/>
            </div>
            <div className="col-md-6 form-group mb-3">
                <label htmlFor="lastName">{t("textLastName")} </label>
                <input className="form-control" id="lastName" type="text" value={lastName} placeholder="Jean Vincent" 
                name="lastName" onChange={(e)=>{setLastName(e.target.value)}}/>
            </div>
        </div>
        <div className="row row-sx">
        {accountType==="enterprise" &&(
            <div className="col-6 form-group">
                <label htmlFor="accType" className={noSubAccountType?"text-danger":""}>Which Type of Company are You?</label>
                <Select className="basic-single"
                    id="city"
                    styles={styles}
                    isSearchable
                    isDisabled={loading}
                    inputId="city"
                    value={accountSubType}
                    
                    isLoading={loading}
                    onChange={(val)=>{setSubAccountType(val)}}
                    options={[{label:t("textConsFirms"), value: "consulting_firm"},{label:t("textOthersEnt"), value: "other_firm"}]}
                />
            </div>)}
        {accountType==="financial_inst" &&(
            <div className="col-md-6 form-group">
                <label className={noSubAccountType?"text-danger":""}>{t("textFinInstType")}<span className="text-danger">*</span></label>
                <Select className="basic-single"
                    id="city"
                    styles={styles}
                    isSearchable
                    isDisabled={loading}
                    inputId="city"
                    value={accountSubType}
                    
                    isLoading={loading}
                    onChange={(val)=>{setSubAccountType(val)}}
                    options={[{label:t("textBank"), value: "bank"},{label:t("textMicrofin"), value: "micro_finance"}, {label: t("textOthersEnt"), value:"other_fin"}]}
                />
            </div>)}
            {(accountType==="financial_inst"||accountType==="enterprise")&&(<div className="col-md-6 form-group">
                <label>{t("textCompName")}</label>
                <input className="form-control" id="company" type="text" placeholder="B.K Research and Analytics" name="company" 
                onChange={(e)=>{setCompanyName(e.target.value)}} value={companyName}/>
            </div>)}
        </div>
        <div className="row row-xs">
            <div className="col-md-6 form-group mb-3">
                {!validProfession && <label className="text-danger">{t("textProfession_")}</label>}
                {validProfession && <label htmlFor="profession">{t("textProfession")} <span className="text-danger">*</span></label>}
                <input className="form-control" id="profession" type="text" placeholder="Data Analyst" name="profession" 
                onChange={(e)=>{setProfession(e.target.value)}} value={profession} autoComplete="off"/>
            </div>
            <div className="col-md-6 form-group mb-3">
                {!emailValid && <label className="text-danger">{t("textProEmail_")}</label>}
                {emailValid && <label htmlFor="email">{t("textProEmail")}<span className="text-danger">*</span></label>}
                <input className="form-control" id="email" type="text" placeholder="user@bkresearhandanalytics.com" name="email" 
                onChange={(e)=>{setEmail(e.target.value)}}/>
            </div>
        </div>
        <div className="row row-xs">
            <div className="col-md-6 form-group">
                {!validCity && <label className="text-danger">{t("textCity_")}</label>}
                {validCity && <label htmlFor="city">{t("textCity")} <span className="text-danger">*</span></label>}
                <Select className="basic-single"
                    id="city"
                    styles={styles}
                    isSearchable
                    isDisabled={loading}
                    inputId="city"
                    value={selectedCity}
                    
                    isLoading={loading}
                    onChange={(val)=>{setSelectedCity(val)}}
                    options={cyties?.map((i)=>{
                    return {label: i.name, value: i.id, url: i.url}
                    }) ?? []}
                />
            </div>
            <div className="col-md-6 form-group mb-3">
                {!phoneValid && <label className="text-danger">{t("textPhonNum_")}</label>}
                {phoneValid &&<label htmlFor="phoneNumber">{t("textPhonNum")} <span className="text-danger">*</span></label>}
                <input className="form-control" id="phone-number" type="text" placeholder="690 567 892" name="phone-number" onChange={(e)=>{setPhoneNumber(e.target.value)}}/>
            </div>
        </div>
        <div className="row row-xs">
            <div className="col-md-6 form-group mb-3">
                {!passwordValid && <label className="text-danger">{t("textPassword_")}</label>}
                {passwordValid && <label htmlFor="password">{t("textPassword")} <span className="text-danger">*</span></label>}
                <div className="input-group">
                    <input className="form-control" id="password"
                        type={showPassword ? 'text' : 'password'}
                        required=""
                        placeholder="************" 
                        name="password"
                        onChange={(e)=>{setPassword(e.target.value)}}
                        style={{minHeight:"55px"}} />
                    <div className="input-group-append" onClick={togglePasswordVisibility} style={{cursor:"pointer",minHeight:"55px"}}>
                        <span  className="eye-icon input-group-text" id="basic-addon2">
                        <FontAwesomeIcon icon={showPassword ? faEye: faEyeSlash } /></span>
                    </div>
                </div>
                <label className="">{t("textPWDesc")}</label>
            </div>
            <div className="col-md-6 form-group mb-3">
                {!equalPassword && <label className="text-danger">{t("textConfirmPW_")}</label>}
                {equalPassword && <label htmlFor="password2">{t("textConfirmPW")} <span className="text-danger">*</span></label>}
                <div className="input-group">
                    <input className="form-control" id="password2"
                        type={showPassword ? 'text' : 'password'}
                        required=""
                        placeholder="************" 
                        name="password2"
                        onChange={(e)=>{setPassword2(e.target.value)}}
                        style={{minHeight:"55px"}}/>
                    <div className="input-group-append" onClick={togglePasswordVisibility} style={{cursor:"pointer", minHeight:"55px"}}>
                        <span  className="eye-icon input-group-text" id="basic-addon2">
                        <FontAwesomeIcon icon={showPassword ? faEye: faEyeSlash } /></span>
                    </div>
                </div>
            </div>
        </div>
        <div className="row row-xs">
            <div className="col-md-6">
            <span>{t("textAccept")} <Link to="#"style={{fontWeight:"bold", textDecoration:"underline"}}>{t("textTerm")}</Link> {t("textAnd")} <Link to="#"style={{fontWeight:"bold", textDecoration:"underline"}}> {t("textPrivacyPolicy")}</Link>?</span>
            </div>
            <div className="col-md-6 mt-3 mt-md-0">
            <input type="checkbox" htmlFor="acceptTerm" name="acceptTerm" onChange={(e)=>{setAgree(!agree)}}/>
            {!agreed && <label className="text-danger" style={{float:"right"}}>{t("textAgreeTerms_")}</label>}
            </div>
        </div>
    </>);
}
export default AccountDetails;