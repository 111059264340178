import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/auth'
import localReducer from "./slices/language";
import subsReducer from "./slices/subscribtions";

const store = configureStore({
  reducer: {
    auth: authReducer,
    locale: localReducer,
    subscribtions: subsReducer
  },
})


export {store};
