import {Link, Outlet, useLocation} from "react-router-dom"
import { useSelector } from "react-redux";


const ParamsLayout = () => {

    const {auth} = useSelector((state) => state.auth)
    const { pathname } = useLocation();

    return(
        <div className="main-content">
        <div className="breadcrumb">
            <h1 className="mr-2">System Parameters</h1>
            <ul>
                <li><Link to="">List</Link></li>
            </ul>
        </div>
        <div className="separator-breadcrumb border-top"></div>
        
        {/* <div className="main-content-wrap d-flex flex-column">*/}
            <div className="main-content"> 
                <div className="inbox-main-sidebar-container" data-sidebar-container="main">
                    <div className="inbox-main-content" data-sidebar-content="main">
                    <div className="inbox-secondary-sidebar-container" data-sidebar-container="secondary">
                        <div className="secondary">
                            <div className="inbox-secondary-sidebar-content position-relative" style={{minHeight: "500px"}}>
                                <Outlet/>
                            </div>
                        </div>
                        {/* <div className="inbox-secondary-sidebar perfect-scrollbar rtl-ps-none" data-sidebar="secondary"><i className="sidebar-close i-Close" data-sidebar-toggle="secondary"></i>
                                <div className="mail-item">
                                    <div className="avatar"><img src="../../dist-assets/images/faces/1.jpg" alt="" /></div>
                                    <div className="col-xs-6 details"><span className="name text-muted">John Doe</span>
                                        <p className="m-0">Confirm your email</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">20 Dec 2018</span></div>
                                </div>
                                <div className="mail-item">
                                    <div className="avatar"><img src="../../dist-assets/images/faces/5.jpg" alt="" /></div>
                                    <div className="col-xs-6 details"><span className="name text-muted">John Doe</span>
                                        <p className="m-0">Confirm your email</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">20 Dec 2018</span></div>
                                </div>
                                <div className="mail-item">
                                    <div className="avatar"><img src="../../dist-assets/images/faces/2.jpg" alt="" /></div>
                                    <div className="col-xs-6 details"><span className="name text-muted">John Doe</span>
                                        <p className="m-0">Confirm your email</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">20 Dec 2018</span></div>
                                </div>
                                <div className="mail-item">
                                    <div className="avatar"><img src="../../dist-assets/images/faces/3.jpg" alt="" /></div>
                                    <div className="col-xs-6 details"><span className="name text-muted">John Doe</span>
                                        <p className="m-0">Confirm your email</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">20 Dec 2018</span></div>
                                </div>
                                <div className="mail-item">
                                    <div className="avatar"><img src="../../dist-assets/images/faces/4.jpg" alt="" /></div>
                                    <div className="col-xs-6 details"><span className="name text-muted">John Doe</span>
                                        <p className="m-0">Confirm your email</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">20 Dec 2018</span></div>
                                </div>
                                <div className="mail-item">
                                    <div className="avatar"><img src="../../dist-assets/images/faces/5.jpg" alt="" /></div>
                                    <div className="col-xs-6 details"><span className="name text-muted">John Doe</span>
                                        <p className="m-0">Confirm your email</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">20 Dec 2018</span></div>
                                </div>
                                <div className="mail-item">
                                    <div className="avatar"><img src="../../dist-assets/images/faces/4.jpg" alt="" /></div>
                                    <div className="col-xs-6 details"><span className="name text-muted">John Doe</span>
                                        <p className="m-0">Confirm your email</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">20 Dec 2018</span></div>
                                </div>
                                <div className="mail-item">
                                    <div className="avatar"><img src="../../dist-assets/images/faces/9.jpg" alt="" /></div>
                                    <div className="col-xs-6 details"><span className="name text-muted">John Doe</span>
                                        <p className="m-0">Confirm your email</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">20 Dec 2018</span></div>
                                </div>
                            </div> */}
                    </div>
                    </div>
                    <div className="inbox-main-sidebar" data-sidebar="main" data-sidebar-position="left">
                            <div className="pt-3 pr-3 pb-3"><i className="sidebar-close i-Close" data-sidebar-toggle="main"></i>
                                <div className="pl-3">
                                    {/* <p className="text-muted mb-2">Paratmeters types</p> */}
                                    <ul className="inbox-main-nav">
                                        <li className={` ${pathname.includes("/activities") ? 'active' : ''}`}><Link to="activities"><i className="icon-regular i-Spam-Mail"></i> Activities</Link></li>
                                        <li className={` ${pathname.includes("/countries") ? 'active' : ''}`}><Link to="countries"><i className="icon-regular i-Spam-Mail"></i> Countries</Link></li>
                                        <li className={` ${pathname.includes("/industries") ? 'active' : ''}`}><Link to="industries"><i className="icon-regular i-Spam-Mail"></i> Industries</Link></li>
                                        <li  className={` ${pathname.includes("/cds-spread") ? 'active' : ''}`}><Link to="cds-spread"><i className="icon-regular i-Mail-Outbox"></i>CDS Spread</Link></li>
                                        <li  className={` ${pathname.includes("/inflation-rate") ? 'active' : ''}`}><Link to="inflation-rate"><i className="icon-regular i-Mail-2"></i>Inflation rate</Link></li>
                                        <li  className={` ${pathname.includes("/prs-equivalent") ? 'active' : ''}`}><Link to="prs-equivalent"><i className="icon-regular i-Mail-Favorite"></i> PRS Equivalent</Link></li>
                                        <li  className={` ${pathname.includes("/prs-score") ? 'active' : ''}`}><Link to="prs-score"><i className="icon-regular i-Folder-Trash"></i> PRS Score</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Risk Premium</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> PRS Worksheet</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Sovereign Rating</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Spread Basis Point</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Country Tax Rate</Link></li>
                                        {/* <li className="separator-breadcrumb border-top"></li> */}
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Account Payable</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Account Receivable</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> After Tax OI</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Capital Expenditure</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> CM Security</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Days Inventory</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Industry average</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Legal Status</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Magnitude ROC</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Market Regression</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Multiple Revenu</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Profit Margin</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> ROC</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Total Beta</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Working Capital</Link></li>
                                        <li><Link to=""><i className="icon-regular i-Spam-Mail"></i> Country Tax Rate</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                </div>
            {/* </div>*/}   
        </div> 
        </div>
    )
}

export default ParamsLayout;