const SubsValidation = ({del, pack, onUnSubscribe, onUpdate}) => {
    const deletion = del;
    const closeModal = () =>{
        window.$("#subsValiationModal").modal("hide");
    }
    const handleSubmit = () => {
        if(del){
            onUnSubscribe(pack.id)
        }else{
            onUpdate()
        }
    }
    return (
        <div className="modal fade o-hidden" id="subsValiationModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle-2" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header my-header">
                        {
                            deletion?(<h5 className="modal-title text-white" id="exampleModalCenterTitle-2">Cancel Subscribtion</h5>):
                            (<h5 className="modal-title text-white" id="exampleModalCenterTitle-2">Update Subscribtion</h5>)
                        }
                        <button className="close text-white" type="button" data-dismiss="modal" aria-label="Close" onClick={()=>closeModal()}><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="modal-body">
                        {deletion?(
                            <p>Are you sure You want to Cancel your subscribtion to the package {pack?.label}?</p>
                        ):(
                            <p className="text-center">You have another subscribtion for the service {pack?.label} <br/>
                            You may be trying to update it are you sure you want to proceed? if yes press the confirm button down</p>
                        )}
                        


                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-danger" type="button" data-dismiss="modal" onClick={()=>closeModal()}>Close</button>
                        <button className="btn btn-primary ml-2" type="button" onClick={()=>handleSubmit()}>{deletion?'Delete':'Update'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubsValidation;