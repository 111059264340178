import { useEffect, useState } from "react";
import { Link, json, useParams } from "react-router-dom"
import useSWR from "swr";
import { URLS } from "../../services/urls";
import LoaderPage from "../../components/loader";
import BreadCrumb from "../../components/breadcrumb";
import { toast } from "react-toastify";
import { curencyFormater, percentageFormater } from "../../components/utils/functions";
import { Translate } from "react-redux-i18n";
import { useTranslation } from "react-i18next";

const SMECRRDetails = () => {
    const { key, ref } = useParams();
    const {t} = useTranslation()
    console.log(key)
    let {data, isLoading} = useSWR(URLS.CRR.details(key));
    const [dLink, setdLink] = useState(null)
    // console.log(data)
    useEffect(()=>{
        
        if(ref){
            toast.success(t("textRatingSuccess"), {position:toast.POSITION.TOP_RIGHT, theme:"colored"});
            // toast.info(t("textRatingReadRes"), {position:toast.POSITION.TOP_CENTER, delay:1000} )
        }
    },[])
    return (
        <>
        {isLoading?(
            <LoaderPage/>
        ):(
            <>
                <BreadCrumb title1="CRR-SME" title2="details" title3={data?.reference} link="/sme-crr"/>
                <div className="row">
                    {ref && (<div className="col-12 mb-1">
                        <div className="alert alert-info" role="alert">
                            {/* <strong className="text-capitalize">Info!</strong>  */}
                            {t("textRatingReadRes")}
                            <button className="close" type="button" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>)}
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-6 col-xl-3 col-md-6">
                                <div className="tp-service-widget-download mb-4" style={{marginRight:0, marginLeft:"auto"}}>
                                    <Link to={`${String(URLS.RATING_URL).replace('/v1','')}/media/rating_reports/${String(data?.report).split('/').pop()}`} target="_blank">
                                        <span style={{fontSize:"12pt"}}>
                                            {t("txtDownloadRep")} <img src="/assets/img/service/service-details/icon-3.svg" alt=""/>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-6"></div>
                            <div className="col-lg-6 col-xl-3 col-md-6 float-left">
                                <div className="tp-service-widget-download mb-4" style={{marginRight:0, marginLeft:"auto"}}>
                                    <Link to={`${String(URLS.RATING_URL).replace('/v1','')}/media/rating_reports/${String(data?.excel_file).split('/').pop()}`} target="_blank">
                                        <span style={{fontSize:"12pt"}}>
                                            {t("txtDownloadRep")} <img src="/assets/img/service/service-details/icon-3.svg" alt=""/>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 mb-5">
                        <div className="card text-left mb-4 o-hidden">
                            <div className="card-header d-flex align-items-center border-0 my-header">
                                  <h3 className="w-50 float-left card-title m-0 text-white">{t("textCRRDetails")}</h3>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>{t("OTHERCRRINFO.REF")}</td>
                                            <td style={{textAlign:"right", textTransform:"uppercase"}}>{data?.reference}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("OTHERCRRINFO.CSCORE")}</td>
                                            <td style={{textAlign:"right"}}>{data?.grade}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("OTHERCRRINFO.DEFAULTPROB")}</td>
                                            <td style={{textAlign:"right"}}>{percentageFormater(data?.probability_qualitative)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("OTHERCRRINFO.BETA")}</td>
                                            <td style={{textAlign:"right"}}>{percentageFormater(data?.cost_of_equity_and_capital.cost_equity.unlevered_beta)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("OTHERCRRINFO.RISKPREM")}</td>
                                            <td style={{textAlign:"right"}}>{percentageFormater(data?.cost_of_equity_and_capital.cost_equity.risk_premium)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("OTHERCRRINFO.EQUITYCOST")}</td>
                                            <td style={{textAlign:"right"}}>{data?.cost_of_equity_and_capital.cost_equity.cost_of_equity}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("OTHERCRRINFO.DEBTCOST")}</td>
                                            <td style={{textAlign:"right"}}>{data?.cost_of_equity_and_capital.cost_equity_capital.cost_of_debt}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("OTHERCRRINFO.CAPCOST")}</td>
                                            <td style={{textAlign:"right"}}>{data?.cost_of_equity_and_capital.cost_equity_capital.cost_of_capital}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 mb-5">
                        <div className="card text-left mb-4 o-hidden">
                            <div className="card-header d-flex align-items-center border-0 my-header">
                                  <h3 className="w-50 float-left card-title m-0 text-white">{t("OTHERCRRINFO.STINRISK")}</h3>
                            </div>
                            <div className="">
                                <div className="table-responsive">
                                    <table className="table table-hover table-bordered">
                                        <tbody>
                                            <tr style={{backgroundColor:"var(--tp-common-gray)", color:"black", fontWeight:"bold"}}>
                                                <td colSpan={2} style={{textAlign:"right"}}>
                                                    {Number(data?.year) -2}
                                                </td>
                                                <td style={{textAlign:"right"}}>
                                                    {Number(data?.year) -1}
                                                </td>
                                                <td style={{textAlign:"right"}}> {Number(data?.year) -0}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("OTHERCRRINFO.CURRASS")}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.current_assets?.net2)}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.current_assets?.net1)}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.current_assets?.net)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("OTHERCRRINFO.CURRLIAB")}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.current_liabilities?.net2)}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.current_liabilities?.net1)}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.current_liabilities?.net2)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("OTHERCRRINFO.CURRRATIO")}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.current_ratio?.net2)}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.current_ratio?.net1)}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.current_ratio?.net)}</td>
                                            </tr>
                                            <tr className="empty-row"><td  colSpan={7}>.</td></tr>
                                            <tr>
                                                <td>{t("OTHERCRRINFO.CASHEQ")}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.cash_marketable_securities?.net2)}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.cash_marketable_securities?.net1)}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.cash_marketable_securities?.net)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("OTHERCRRINFO.CURRLIAB")}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.current_liabilities?.net2)}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.current_liabilities?.net1)}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.current_liabilities?.net)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("OTHERCRRINFO.LIQRIATIO")}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.quick_ratio?.net2)}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.quick_ratio?.net1)}</td>
                                                <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.quick_ratio?.net)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 mb-5">
                        <div className="card text-left mb-4 o-hidden">
                            <div className="card-header d-flex align-items-center border-0 my-header">
                                  <h3 className="w-100 float-left card-title m-0 text-white">{t("OTHERCRRINFO.FINEXPCOVRATIO")}</h3>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover table-bordered">
                                    <tbody>
                                        <tr style={{backgroundColor:"var(--tp-common-gray)", color:"black", fontWeight:"bold"}}>
                                            <td colSpan={2} style={{textAlign:"right"}}>
                                                {Number(data?.year) -2}
                                            </td>
                                            <td style={{textAlign:"right"}}>
                                                {Number(data?.year) -1}
                                            </td>
                                            <td style={{textAlign:"right"}}> 
                                                {Number(data?.year) -0}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.CHARGE_COVERAGE_RATIO_EBIT")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.ebit?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.ebit?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.ebit?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.CHARGE_COVERAGE_RATIO_IE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.interest_coverage_ratio?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.interest_coverage_ratio?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.interest_coverage_ratio?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.CHARGE_COVERAGE_RATIO_ICR")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.interest_coverage_ratio?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.interest_coverage_ratio?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.interest_coverage_ratio?.net)}</td>
                                        </tr>
                                        <tr className="empty-row"><td  colSpan={7}>.</td></tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.CHARGE_COVERAGE_RATIO_EBIT")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.ebit?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.ebit?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.ebit?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.CHARGE_COVERAGE_RATIO_OLE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.ol_expense?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.ol_expense?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.ol_expense?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.CHARGE_COVERAGE_RATIO_IE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.interest_coverage_ratio?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.interest_coverage_ratio?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.interest_coverage_ratio?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.CHARGE_COVERAGE_RATIO_FCCR")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.fixed_charge_coverage_ratio?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.fixed_charge_coverage_ratio?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.fixed_charge_coverage_ratio?.net)}</td>
                                        </tr>
                                        <tr className="empty-row"><td  colSpan={7}>.</td></tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.CHARGE_COVERAGE_RATIO_EBEXP")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.ebitda?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.ebitda?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.ebitda?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.CHARGE_COVERAGE_RATIO_CFC")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.cash_fixed_charges?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.cash_fixed_charges?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.cash_fixed_charges?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.CHARGE_COVERAGE_RATIO_CFHCR")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.cash_fixed_charge_coverage_ratio?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.cash_fixed_charge_coverage_ratio?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.cash_fixed_charge_coverage_ratio?.net)}</td>
                                        </tr>
                                        <tr className="empty-row"><td  colSpan={7}>.</td></tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.CHARGE_COVERAGE_RATIO_CFO")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.cash_flow_from_operations?.net?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.cash_flow_from_operations?.net?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.cash_flow_from_operations?.net?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.CHARGE_COVERAGE_RATIO_CE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.capital_expenditures?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.capital_expenditures?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.capital_expenditures?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.CHARGE_COVERAGE_RATIO_CF")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.cf_capex?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.cf_capex?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.interest_coverage_ratio?.cf_capex?.net)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6 mb-5">
                        <div className="card text-left mb-4 o-hidden">
                            <div className="card-header d-flex align-items-center border-0 my-header">
                                  <h3 className="w-100 float-left card-title m-0 text-white">{t("OTHERCRRINFO.OPWCRATIO")}</h3>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover table-bordered">
                                    <tbody>
                                        <tr style={{backgroundColor:"var(--tp-common-gray)", color:"black", fontWeight:"bold"}}>
                                            <td colSpan={2} style={{textAlign:"right"}}>
                                                {Number(data?.year) -2}
                                            </td>
                                            <td style={{textAlign:"right"}}>
                                                {Number(data?.year) -1}
                                            </td>
                                            <td style={{textAlign:"right"}}> 
                                                {Number(data?.year) -0}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.WORKING_CAPITAL_MGT_SALES")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.sales?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.sales?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.sales?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.WORKING_CAPITAL_MGT_AR")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.account_receivable?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.account_receivable?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.account_receivable?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.WORKING_CAPITAL_MGT_ART")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.account_receivable_turnover?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.account_receivable_turnover?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.account_receivable_turnover?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.WORKING_CAPITAL_MGT_DR")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.days_receivable?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.days_receivable?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.days_receivable?.net)}</td>
                                        </tr>
                                        <tr className="empty-row"><td  colSpan={7}>.</td></tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.WORKING_CAPITAL_MGT_COG")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.cost_of_good_sold?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.cost_of_good_sold?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.cost_of_good_sold?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.WORKING_CAPITAL_MGT_INV")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.inventory?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.inventory?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.inventory?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.WORKING_CAPITAL_MGT_IT")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.inventory_turnover?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.inventory_turnover?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.inventory_turnover?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.WORKING_CAPITAL_MGT_DIH")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.days_inventory_held?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.days_inventory_held?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.days_inventory_held?.net)}</td>
                                        </tr>
                                        <tr className="empty-row"><td  colSpan={7}>.</td></tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.WORKING_CAPITAL_MGT_PUR")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.purchase?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.purchase?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.purchase?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.WORKING_CAPITAL_MGT_AP")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.account_payable?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.account_payable?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.account_payable?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.WORKING_CAPITAL_MGT_APT")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.account_payable_turn_over?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.account_payable_turn_over?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.account_payable_turn_over?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.WORKING_CAPITAL_MGT_DAP")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.days_account_payable?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.days_account_payable?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.days_account_payable?.net)}</td>
                                        </tr>
                                        <tr className="empty-row"><td  colSpan={7}>.</td></tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.WORKING_CAPITAL_MGT_RFP")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.required_financing_period?.net2)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.required_financing_period?.net1)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.working_capital?.management.required_financing_period?.net)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-12 mb-5">
                        <div className="card text-left mb-4 o-hidden">
                            <div className="card-header d-flex align-items-center border-0 my-header">
                                  <h3 className="w-100 float-left card-title m-0 text-white">{t("OTHERCRRINFO.DERATIOB")}</h3>
                            </div>
                            <div className="table-responsive table-stripe">
                                <table className="table table-hover table-bordered">
                                    <tbody>
                                        <tr style={{backgroundColor:"var(--tp-common-gray)", color:"black", fontWeight:"bold"}}>
                                            <td></td>
                                            <td colSpan={2} className="text-center">
                                                {Number(data?.year) -2}
                                            </td>
                                            <td colSpan={2} className="text-center">
                                                {Number(data?.year) -1}
                                            </td>
                                            <td colSpan={2} className="text-center">
                                                {Number(data?.year) -0}
                                            </td>
                                        </tr>
                                        <tr style={{backgroundColor:"var(--tp-common-gray)", color:"black", fontWeight:"bold"}}>
                                            <td></td>
                                            <td className="text-center">
                                                 {t("RATINGS_DATA.UNADJUSTED")}
                                            </td>
                                            <td className="text-center">
                                                {t("RATINGS_DATA.ADJUSTED")}
                                            </td>
                                            <td className="text-center">
                                                 {t("RATINGS_DATA.UNADJUSTED")}
                                            </td>
                                            <td className="text-center">
                                                {t("RATINGS_DATA.ADJUSTED")}
                                            </td>
                                            <td className="text-center">
                                                 {t("RATINGS_DATA.UNADJUSTED")}
                                            </td>
                                            <td className="text-center">
                                                {t("RATINGS_DATA.ADJUSTED")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.DEBT_RATIO_LTD")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.DEBT_RATIO_STD")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.short_term_debt?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.short_term_debt?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.short_term_debt?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.short_term_debt?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.short_term_debt?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.short_term_debt?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.DEBT_RATIO_BVE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.bv_of_equity?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.bv_of_equity?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.bv_of_equity?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.bv_of_equity?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.bv_of_equity?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.bv_of_equity?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.DEBT_RATIO_LTDE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt_on_equity?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt_on_equity?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt_on_equity?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt_on_equity?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt_on_equity?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt_on_equity?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.DEBT_RATIO_LTD_LTDE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt_on_lt_debt_equity?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt_on_lt_debt_equity?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt_on_lt_debt_equity?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt_on_lt_debt_equity?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt_on_lt_debt_equity?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.long_term_debt_on_lt_debt_equity?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.DEBT_RATIO_DE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_equity?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_equity?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_equity?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_equity?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_equity?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_equity?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.DEBT_RATIO_DDE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_debt_equity?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_debt_equity?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_debt_equity?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_debt_equity?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_debt_equity?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_debt_equity?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.DEBT_RATIO_TITLE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_ratio?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_ratio?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_ratio?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_ratio?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_ratio?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_ratio?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.DEBT_RATIO_ER")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.equity_ratio?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.equity_ratio?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.equity_ratio?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.equity_ratio?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.equity_ratio?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.equity_ratio?.net?.adjusted)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 mb-5">
                        <div className="card text-left mb-4 o-hidden">
                            <div className="card-header d-flex align-items-center border-0 my-header">
                                  <h3 className="w-100 float-left card-title m-0 text-white">{t("OTHERCRRINFO.PROFRATIO")}</h3>
                            </div>
                            <div className="table-responsive table-stripe">
                                <table className="table table-hover table-bordered">
                                    <tbody>
                                        <tr style={{backgroundColor:"var(--tp-common-gray)", color:"black", fontWeight:"bold"}}>
                                            <td></td>
                                            <td colSpan={2} className="text-center">
                                                {Number(data?.year) -2}
                                            </td>
                                            <td colSpan={2} className="text-center">
                                                {Number(data?.year) -1}
                                            </td>
                                            <td colSpan={2} className="text-center">
                                                {Number(data?.year) -0}
                                            </td>
                                        </tr>
                                        <tr style={{backgroundColor:"var(--tp-common-gray)", color:"black", fontWeight:"bold"}}>
                                            <td></td>
                                            <td className="text-center">
                                                 {t("RATINGS_DATA.UNADJUSTED")}
                                            </td>
                                            <td className="text-center">
                                                {t("RATINGS_DATA.ADJUSTED")}
                                            </td>
                                            <td className="text-center">
                                                 {t("RATINGS_DATA.UNADJUSTED")}
                                            </td>
                                            <td className="text-center">
                                                {t("RATINGS_DATA.ADJUSTED")}
                                            </td>
                                            <td className="text-center">
                                                 {t("RATINGS_DATA.UNADJUSTED")}
                                            </td>
                                            <td className="text-center">
                                                {t("RATINGS_DATA.ADJUSTED")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_OI")}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.operational_analysis.profit_margin.operating_income?.net2)}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.operational_analysis.profit_margin.operating_income?.net1)}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.operational_analysis.profit_margin.operating_income?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_ATOI")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.after_tax_operating_income?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.after_tax_operating_income?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.after_tax_operating_income?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.after_tax_operating_income?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.after_tax_operating_income?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.after_tax_operating_income?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_NE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability?.net_income?.net2?.unadjusted)} </td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability?.net_income?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability?.net_income?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability?.net_income?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability?.net_income?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability?.net_income?.net?.adjusted)}</td>
                                        </tr>
                                        <tr className="empty-row"><td  colSpan={7}>.</td></tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_BVEB")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_beginning?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_beginning?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_beginning?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_beginning?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_beginning?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_beginning?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_BVEE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_ending?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_ending?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_ending?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_ending?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_ending?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_ending?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_BVEA")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_average?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_average?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_average?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_average?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_average?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_equity_average?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_ROEBA")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roe_beginning?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roe_beginning?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roe_beginning?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roe_beginning?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roe_beginning?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roe_beginning?.net?.adjusted)}</td>
                                        </tr>
                                        <tr className="empty-row"><td colSpan={7}>.</td></tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_ATROC")}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.profitability.after_tax_roc?.net2)}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.profitability.after_tax_roc?.net1)}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.profitability.after_tax_roc?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_DER")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_equity?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_equity?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_equity?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_equity?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_equity?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.debt_ratio.debt_on_equity?.net?.unadjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_BIR")}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.profitability.book_interest_rate?.net2)}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.profitability.book_interest_rate?.net1)}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.profitability.book_interest_rate?.net)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_ROE")}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.profitability.return_on_equity_ending?.net2)}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.profitability.return_on_equity_ending?.net1)}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.profitability.return_on_equity_ending?.net)}</td>
                                        </tr>
                                        <tr className="empty-row"><td colSpan={7}>.</td></tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_BVCB")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_beginning?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_beginning?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_beginning?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_beginning?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_beginning?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_beginning?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_BVCE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_ending?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_ending?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_ending?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_ending?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_ending?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_ending?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_BVCA")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_average?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_average?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_average?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_average?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_average?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_average?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_ROCBA")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roc_ending?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roc_ending?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roc_ending?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roc_ending?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roc_ending?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roc_ending?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_ROCBB")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roc_beginning?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roc_beginning?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roc_beginning?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roc_beginning?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roc_beginning?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roc_beginning?.net?.adjusted)}</td>
                                        </tr>
                                        <tr className="empty-row"><td colSpan={7}>.</td></tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_BVCIB")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_beginning?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_beginning?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_beginning?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_beginning?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_beginning?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_beginning?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_BVCIE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_ending?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_ending?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_ending?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_ending?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_ending?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_ending?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_BVCIA")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_average?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_average?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_average?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_average?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_average?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_capital_invested_average?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_ROICBA")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roic_ending?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roic_ending?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roic_ending?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roic_ending?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roic_ending?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roic_ending?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_ROICBB")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roic_beginning?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roic_beginning?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roic_beginning?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roic_beginning?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roic_beginning?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roic_beginning?.net?.adjusted)}</td>
                                        </tr>
                                        <tr className="empty-row"><td colSpan={7}>.</td></tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_BVAB")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_assets_beginning?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_assets_beginning?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_assets_beginning?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_assets_beginning?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_assets_beginning?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_assets_beginning?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_BVAE")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_assets_ending?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_assets_ending?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_assets_ending?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_assets_ending?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_assets_ending?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.bv_assets_ending?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_ROABA")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roa_ending?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roa_ending?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roa_ending?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roa_ending?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roa_ending?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roa_ending?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_ROABB")}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roa_beginning?.net2?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roa_beginning?.net2?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roa_beginning?.net1?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roa_beginning?.net1?.adjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roa_beginning?.net?.unadjusted)}</td>
                                            <td style={{textAlign:"right"}}>{curencyFormater(data?.profitability.roa_beginning?.net?.adjusted)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("RATINGS_DATA.RETURN_RATIO_ATO")}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.profitability.asset_turn_over?.net2)}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.profitability.asset_turn_over?.net1)}</td>
                                            <td style={{textAlign:"right"}} colSpan={2}>{curencyFormater(data?.profitability.asset_turn_over?.net)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}
        </>
    );
}
export default SMECRRDetails;