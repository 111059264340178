import {PieChart} from "echarts/charts"; 
import {GridComponent, TitleComponent, TooltipComponent} from "echarts/components"; 
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';

const MPieChart = (props) =>{
    echarts.use([TitleComponent, PieChart, TooltipComponent, GridComponent])
    const {data} = props;
    const option = {
        series: [
            {
                color: ["#1D3461", "#00ACCC", "#1D1460", "#8877bd", "#9181bd", "#6957af"],
                tooltip: {
                    show: true,
                    backgroundColor: "rgba(0, 0, 0, 1.8)",
                },
                type: "pie",
                data: data,
                itemStyle: {
                  emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
            }

        ]
    }
    return <ReactEChartsCore echarts={echarts} option={option}/>
}

export default MPieChart;