import { useEffect, useState } from "react";
import LanguageSwitcher from "../../../components/language";
let timeout: NodeJS.Timeout;
const COUNTDOWN_AMOUNT_TOTAL = 5*60;
const OTPForm = ({t, loading, setOTP, isValidOTP, submit, resend}) => {
    const [seconds, setSeconds] = useState(COUNTDOWN_AMOUNT_TOTAL);
    const displaySeconds = seconds%60;
    const displayMinutes = Math.floor(seconds/60);
    useEffect(()=>{
        if(seconds>0){
            timeout=setTimeout(()=>{
                setSeconds((state)=>state-1);
            },1000);
        }else{
            clearTimeout(timeout);
        }
    },[seconds])
    return (
        <div className="swiper-wrapper mb-30 mt-20">
            <div className="tp-faq-tab-content tp-accordion wow fadeInRight">
                <div className="mb-20" style={{minWidth:"450px"}}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <div className="mb-3 inline">
                                    <p dangerouslySetInnerHTML={{ __html: t("textOTPMsg") }}></p>
                                </div>
                                <div className="form-group">{isValidOTP?
                                    (<label htmlFor="otp">{t("textOTP")}</label>):
                                    (<label htmlFor="otp" className="text-danger">{t("textOTP_")}</label>)}
                                    <input type="number" className="form-control" id="otp" placeholder="012345" onChange={(e)=>setOTP(e.target.value)}/>
                                </div>
                                <button className="btn btn-primary btn-block mt-2" disabled={loading} onClick={()=>submit()}>
                                                                            {t("textContinue")}</button>
                            </div>
                            
                            <div className="mt-3 inline"style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", width:"inherit"}}>
                                <span>{t("textNoOTP")}?</span>
                                {displayMinutes<=0?(<button style={{fontWeight:"bold", textDecoration:"underline"}} className="text-primary" onClick={()=>resend()}>{t("textResend")}</button>):
                                (<div style={{fontWeight:"bold"}} className="text-primary">{displayMinutes}:{displaySeconds}</div>)}
                            </div>
                            <div className="mt-3" style={{display: "flex", flexWrap: "wrap", justifyContent: "center", width:"inherit"}}>
                                <LanguageSwitcher/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OTPForm