import { URLS } from "../../services/urls";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUserSubscribtions, setUserSubscribtions } from "../utils/functions";
import { PAYMENTAPI } from "../../services/axios";
import { setSubcribtions } from "../../store/slices/subscribtions";


const SubsComponent = (props) =>{
    const subscribtions = getUserSubscribtions();
    const dispatch = useDispatch();
    useEffect(() => {
        if(subscribtions){
            PAYMENTAPI.get(URLS.PAYMENT.SUBSCRIBTIONS.list()).then((resp)=>{
                dispatch(setSubcribtions({...subscribtions, subscribtions: resp.data.datas} ));
                setUserSubscribtions(resp.datas.data);
            }).catch((error) => {
                dispatch(setSubcribtions({...subscribtions}))
            })
        }
    });
    return <div></div>
}
export default SubsComponent