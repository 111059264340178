import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const DashboardFooter = () =>{
    return (
        <footer className="app-footer" style={{backgroundColor:"var(--tp-common-gray)"}}>
                <div className="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
                    <Link className="btn btn-primary text-white btn-rounded" to="https://bkresearchandanalytics.com" target="_blank">B.K. Research & Analytics</Link>
                    <span className="flex-grow-1"></span>
                    <div className="d-flex align-items-center" style={{color:"#fff"}}>
                        <img className="logo" src="/assets/logo_final.png" alt="" />
                        <div>
                            <h5 className="m-0 text-14"><strong>&copy; {new Date().getFullYear()} B.K. Research & Analytics</strong>. All rights reserved</h5>
                        </div>
                    </div>
                </div>
        </footer>
    )

}
export default DashboardFooter;