import { useEffect, useState } from "react";
import { curencyFormater, getUserSubscribtions } from "../../components/utils/functions";
import SubsValidation from "./subscribtionValidationModal";

const PackageComponent = ({pack, subscribe, openModal, update, unSubscribe, setPrevPack}) =>{
    const [subscribed, setSubscribed] = useState(false);
    const [similar, setSimilar] = useState(false);
    // const [prevPack, setPrevPack] = useState(null);
    const [cancel, setCancel] = useState(false);
    useEffect(()=>{
        const subs = getUserSubscribtions();
        const initial = pack.ref.split("_")[0];
        subs.forEach((element)=>{
            if(element===pack.ref){
                setSubscribed(true);
            }
            if(element.includes(initial)){
                setSimilar(true)
                setPrevPack(element)
                // console.log("Similar")
            }
        })
    },[]);
    const onSuccess = () => {
        // mutate()
      }

    const handleSubscribe = (pack) => {
        if(similar){
            openModal(false, pack)
        }
        else{
            subscribe(pack.id);
        }
    }
    const handleDelete= () => {
        unSubscribe(pack.id);
    }
    const handleUpdate= (to) => {
        update(pack.id, to);
    }
    console.log(pack);
    return (
        <>
            <div className="col-xl-3 col-lg-4 col-md-6 mb-50" key={pack.id}>
                <div className="card o-hidden mb-4" style={{minHeight:"375px" }}>
                    <div className="card-header d-flex align-items-center border-0 my-header">
                        <h3 className="w-100 float-left card-title m-0 text-white">{pack.label}</h3>
                        {subscribed &&(
                        <div className="dropdown dropleft text-right w-50 float-right">
                            <span className="badge badge-success m-2">Subscribed</span>
                        </div>)}
                    </div>
                    <div className="card-body">
                        <div className="align-items-center mb-2">
                            <p className="card-text text-center">{pack.description}</p>
                        </div>
                        <div className="separator-breadcrumb border-top"></div>
                        <div className="ul-widget__body" style={{fontSize:"10pt"}}>
                            <div className="ul-widget1">
                                <div className="ul-widget4__item ul-widget4__users">
                                    <div className=""><p className="card-text">Onboarding</p></div><span className="ul-widget4__number t-font-boldest text-success card-text">{curencyFormater(100000)}</span>
                                </div>
                                <div className="ul-widget4__item ul-widget4__users">
                                    <div className=""><p className="card-text">Price</p></div><span className="ul-widget4__number t-font-boldest text-success">{curencyFormater(pack.price_min)}</span>
                                </div>
                                <div className="ul-widget4__item ul-widget4__users">
                                    <div className=""><p className="card-text">tax</p></div><span className="ul-widget4__number t-font-boldest text-success">{parseFloat(pack.tva_tx).toFixed(2)}%</span>
                                </div>
                                <div className="ul-widget4__item ul-widget4__users">
                                    <div className=""><p className="card-text">Total price per transaction</p></div><span className="ul-widget4__number t-font-boldest text-success">{curencyFormater(pack.price_min_ttc)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        {subscribed?(
                        <button className="btn btn-danger" href="#" style={{}} onClick={()=>{openModal(true, pack)}}>Cancel subscribtion</button>):
                        (<button className="btn btn-primary" onClick={()=>{handleSubscribe(pack)}}>Subscribe</button>)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PackageComponent;