import { createSlice } from "@reduxjs/toolkit";
const subs = window.localStorage.getItem("subscribtions");

const initialState = {
    subscriptions: subs?? []
}

export const subsSlice = createSlice({
    name: "subs",
    initialState,
    reducers: {
        setSubcribtions: (state, action)=>{
            state.subscriptions = action.payload
        }
    }
})

export const { setSubcribtions } = subsSlice.actions;
export default subsSlice.reducer;