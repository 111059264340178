import { BANK_SVG, ENTERPRISE_SVG, PACCOUNT_SVG } from "../../../../components/utils/consts";
import { styles } from "../../../../components/utils/reactSelect";
import Select from "react-select";

const ChooseCompanyType = ({countries, country, setCountry, validCountry, loading, t, accountType, setAccountType, noAccountType}) => {
    return (
        <div className="row row-xs">
            {/* <div className="col-12">
            <h3 style={{background:"linear-gradient(90deg, #004D6E 0%, #00ACCC 100%)", WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}}>Select the account type!</h3>
            </div> */}
            <div className="col-12 form-group">
            {!validCountry && <label className="text-danger">{t("textCountrySelect")}</label>}
            {validCountry && <label htmlFor="country">{t("textCountry")} <span className="text-danger">*</span></label>}
            <Select className="basic-single"
                id="country"
                styles={styles}
                isSearchable
                isDisabled={loading}
                inputId="country"
                value={country}
                
                isLoading={loading}
                onChange={(val)=>{setCountry(val)}}
                options={countries?.map((i)=>{
                return {label: t(`COUNTRY.${i.code2}`), value: i.id, url: i.url, phone: i.phone}
                }) ?? []}
            />
            </div>
            <div className="col-12 form-group mt-2">
                {noAccountType && <label htmlFor="country" className="text-danger">{t("textSelectComp")} <span className="text-danger">*</span></label>}
                {!noAccountType && <label htmlFor="country">{t("textSelectComp")} <span className="text-danger">*</span></label>}
                <div className={accountType==="financial_inst"?"my-card card-checked":"my-card"} onClick={()=>{setAccountType("financial_inst")}}>
                    <div className="my-box"><img src={BANK_SVG} className="my-img" alt="fin-firm"/></div>
                    <div className="my-msg">{t("textFinFirm")}</div>
                    <div className="my-radio-div">
                        <input type="radio" name="radio" value="financial_inst" className="my-radio" checked={accountType==="financial_inst"}/>
                    </div>
                </div>
            </div>
            <div className="col-12 form-group">
                <div className={accountType==="enterprise"?"my-card card-checked":"my-card"} onClick={()=>{setAccountType("enterprise")}}>
                    <div className="my-box"><img src={ENTERPRISE_SVG} className="my-img" alt="enterprise"/></div>
                    <div className="my-msg">{t("textOtherFirm")}</div>
                    <div className="my-radio-div">
                        <input type="radio" name="radio" value="enterprise" className="my-radio" checked={accountType==="enterprise"} onChange={()=>{setAccountType("enterprise")}}/>
                    </div>
                </div>
            </div>
            <div className="col-12 form-group">
                <div className={accountType==="individual"?"my-card card-checked":"my-card"} onClick={()=>{setAccountType("individual")}}>
                    <div className="my-box"><img src={PACCOUNT_SVG} className="my-img" alt="acc-svg"/></div>
                    <div className="my-msg">{t("textIndividual")}</div>
                    <div className="my-radio-div">
                        <input type="radio" name="radio" value="individual" className="my-radio" checked={accountType==="individual"} onChange={()=>{setAccountType("individual")}}/>
                    </div>
                </div>
            </div>
        </div>);
}
export default ChooseCompanyType;