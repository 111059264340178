import { Link } from "react-router-dom";

const NoTransaction = ({transaction, addFunction}) => {
    return (
        <section className="tp-contact-area p-relative pt-120 fix text-center" 
        style={{height:"50vh"}}>
         <div className="container" >   
             <div className="tp-contact-bg" style={{background:'url("/assets/img/contact/bg.png"")'}}></div>
            <div style={{marginTop:"5%"}}>
                {transaction==="CRR"&&(
                    <>
                        <p className="text-36 subheading mb-3">No rating</p>
                        <Link className="btn btn-primary" to={"/sme-crr/new"}>Start here</Link>
                    </>
                )}
                {transaction==="FF"&&(
                    <>
                        <p className="text-36 subheading mb-3">No Financial Forcasting</p>
                        <Link className="btn btn-primary" to={"/f-forcasting/new"}>Start here</Link>
                    </>
                )}
                {transaction==="BILL"&&(
                    <>
                        <p className="text-36 subheading mb-3">No Bill Available</p>
                        {/* <Link className="btn btn-primary" to={"f-forcasting/new"}>Start here</Link> */}
                    </>
                )}
                {transaction==="CARD"&&(
                    <>
                        <p className="text-36 subheading mb-3">No payment method registered</p>
                        {/* <Link className="btn btn-primary" to={"f-forcasting/new"}>Start here</Link> */}
                    </>
                )}
                {transaction==="MEMBER"&&(
                    <>
                        <p className="text-36 subheading mb-3">No Worker in Your Enterprise</p>
                        <Link className="btn btn-primary" onClick={()=>{addFunction()}}>Add</Link>
                    </>
                )}
            </div>
            </div>
        </section>
    )
}
export default NoTransaction;