import { Link } from "react-router-dom";

const NotFoundPage = () => {
    return (
        // <div className=" text-center">
        <section className="tp-contact-area p-relative pt-120 fix text-center" 
        style={{height:"100vh"}}
        // style={{background:'url("/assets/img/faq/bg-shape.png")', minHeight:"100%", backgroundPosition:"center", backgroundSize:"cover", backgroundRepeat:"no-repeat"}}
        >
         <div className="container" >   
             <div className="tp-contact-bg" style={{background:'url("/assets/img/contact/bg.png"")'}}></div>
            <div style={{marginTop:"5%"}}>
                <h1 className="text-60" style={{background:"linear-gradient(90deg, #004D6E 0%, #00ACCC 100%)", WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent"}} >404</h1>
                <p className="text-36 subheading mb-3">Error!</p>
                <p className="mb-5 text-muted text-18">Sorry! The page you were looking for doesn&apos;t exist.
                </p><Link className="btn btn-lg btn-primary btn-rounded" to="/">Go back to home</Link>
            </div>
            </div>
        </section>
        // </div>
    );
}

export default NotFoundPage;